import React, { useReducer, useState } from "react";
import MTextField from "@material-ui/core/TextField";
//import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/Assignment";
import InputAdornment from "@material-ui/core/InputAdornment";
import protobuf, { Form, Node, Question } from "src/proto/FormServerMessages";
import QuestionView from "./question";
import { INodeModel } from "src/services/models/interface/node_model";
import { Badge, Alert } from "react-bootstrap";
import {
  Card,
  Divider,
  Tooltip,
  Typography,
  Paper,
  Box,
  Icon,
  Button,
  IconButton,
} from "@material-ui/core";
import "./section.css";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "./enums";
import { useIntl } from "react-intl";
import {
  GenericActionMenuCreator,
  MenuItemType,
} from "../common/generic_action_menu";

import EditIcon from "@material-ui/icons/Edit";
import FormName from "./formname/formname";
import TextEditDialog from "../common/text_edit";
import NewQuestionModal from "./new_question_modal";
import { FormModel } from "src/services/models/form_model";
import { messages } from "src/translations/intl_messages";
import { IFormModel } from "src/services/models/interface/form_model";
import { GroupedCondition } from "src/services/models/decision_model";
import { NodeModel } from "src/services/models/node_model";

type FormNameProps = {
  name: string;
};

type SectionProps =
  | {
      section: INodeModel;
      formModel: IFormModel;
      displayMode: FormDisplayMode.EditMode;
      // the function to be called when there is update
      // caused in the container
      onUpdate: () => void;
      isShortNameOK: (nodeId: number | undefined, name: string) => boolean;
    }
  | {
      section: INodeModel;
      filledForm: FilledFormModel;
      displayMode: Exclude<FormDisplayMode, FormDisplayMode.EditMode>;
      // the function to be called when there is update
      // caused in the container
      onUpdate: () => void;
    };

// use of class component result in error in production build
//        Error: Minified React error #152; visit
// perhaps because this is used inside function component
export default function SectionView(props: SectionProps) {
  let section = props.section;

  const intl = useIntl();

  const [openSectionEdit, setOpenSectionEdit] = React.useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isItFormRoot = section.getParent() === undefined;

  const menuItems: MenuItemType[] = [
    {
      name: intl.formatMessage(messages.sectionEditName),
      action: (section: INodeModel) => {
        setOpenSectionEdit(true);
      },
    },
    {
      name: intl.formatMessage(messages.delete),
      action: (section: INodeModel) => {
        // TODO: ask for confirmation
        section.delete();
        props.onUpdate();
      },
    },
    {
      name: "Move section",
      action: (section: INodeModel) => {
        // TODO: display the modal to let user specify where to move the section
      },
    },
    {
      name: "Add question",
      action: (section: INodeModel) => {
        openAddQuestionDialog(true);
      },
    },
    {
      name: "Add subsection",
      action: (section: INodeModel) => {
        openAddSectionDialog(true);
      },
    },
  ];
  const SectionEditMenu = GenericActionMenuCreator(menuItems);
  /*
  {props.displayMode === FormDisplayMode.EditMode ? (
    <SectionEditMenu data={section}>
      {section.getPositionWithDots()} {section.node.shortName}
    </SectionEditMenu>
  ) : (
    section.node.shortName
  )}
  */
  // language specific texts
  const sectionNameLabel = intl.formatMessage({
    id: "form.label.sectionName",
    defaultMessage: "Section Name",
  });
  const editSection = (
    <IconButton aria-label="edit">
      <SectionEditMenu data={section}>
        <EditIcon />
      </SectionEditMenu>
    </IconButton>
  );
  const [addQuestionDialog, openAddQuestionDialog] = useState(false);
  const [addSectionDialog, openAddSectionDialog] = useState(false);

  const questionViewGetter = (index: number, node: INodeModel) =>
    props.displayMode === FormDisplayMode.EditMode ? (
      <QuestionView
        key={index}
        question={node}
        formModel={props.formModel}
        displayMode={props.displayMode}
        onUpdate={forceUpdate}
        isShortNameOK={(name: string) =>
          props.isShortNameOK(node.getId(), name)
        }
      />
    ) : (
      <QuestionView
        key={index}
        question={node}
        filledForm={props.filledForm}
        displayMode={props.displayMode}
        onUpdate={props.onUpdate}
      />
    );

  const sectionViewGetter = (index: number, node: INodeModel) =>
    props.displayMode === FormDisplayMode.EditMode ? (
      <SectionView
        key={index}
        section={node}
        formModel={props.formModel}
        displayMode={props.displayMode}
        onUpdate={forceUpdate}
        isShortNameOK={props.isShortNameOK}
      />
    ) : (
      <SectionView
        key={index}
        section={node}
        filledForm={props.filledForm}
        displayMode={props.displayMode}
        onUpdate={props.onUpdate}
      />
    );

  return (
    <div>
      {isItFormRoot ? (
        section.getChildren().map((node: INodeModel, index: number) => {
          return node.isQuestion()
            ? questionViewGetter(index, node)
            : sectionViewGetter(index, node);
        })
      ) : (
        <Paper className="Section">
          <Box m={2}>
            {props.displayMode === FormDisplayMode.EditMode ? (
              <>
                <Typography
                  align="center"
                  variant="h5"
                  className="sectionTitle"
                >
                  {section.getPositionWithDots()} {section.getShortName()}
                  {editSection}
                </Typography>
                <TextEditDialog
                  open={openSectionEdit}
                  hintText={"Edit Section"}
                  labelExistingText={[
                    {
                      labelText: sectionNameLabel,
                      existingText: section.getShortName(),
                    },
                    {
                      labelText: "Text Below Section Header",
                      existingText: section.getTextToDisplay(),
                    },
                  ]}
                  onClose={(newTitle, confirmed) => {
                    setOpenSectionEdit(false);
                    if (confirmed && newTitle !== undefined) {
                      // TODO: below is wrong way to update the Form; though it is working. Correct it
                      // to follow reactjs guideline to not modify the props values.
                      if (newTitle[0]) section.setShortName(newTitle[0]);
                      section.setTextToDisplay(newTitle[1] ? newTitle[1] : "");
                      console.log("new section value is " + newTitle[0]);
                      forceUpdate();
                    }
                  }}
                />
                <NewQuestionModal
                  key={Math.random() * 10000}
                  open={addQuestionDialog}
                  formModel={props.formModel as IFormModel}
                  isShortNameOK={(name: string) =>
                    props.isShortNameOK(undefined, name)
                  }
                  condition={undefined}
                  onClose={(
                    qLabel: string | undefined,
                    qText: string | undefined,
                    isOptional: boolean | undefined,
                    question: Question | undefined,
                    confirmed: boolean,
                    condition: GroupedCondition | undefined
                  ) => {
                    openAddQuestionDialog(false);
                    createQuestion(
                      confirmed,
                      qLabel,
                      qText,
                      question,
                      props.section,
                      isOptional,
                      condition
                    );
                  }}
                />
                <TextEditDialog
                  open={addSectionDialog}
                  hintText={"New Section"}
                  labelExistingText={[
                    {
                      labelText: "Section Name",
                      existingText: undefined,
                    },
                    {
                      /* section header paragraph */
                      labelText: "Text Below Section Header",
                      existingText: undefined,
                    },
                  ]}
                  onClose={(newText, confirmed) => {
                    openAddSectionDialog(false);
                    if (
                      confirmed &&
                      newText !== undefined &&
                      newText[0] !== undefined
                    ) {
                      FormModel.newSection(
                        props.section,
                        newText[0],
                        newText[1]
                      );
                      console.log("new subsection " + newText[0]);
                      props.onUpdate();
                    }
                  }}
                />
              </>
            ) : (
              <Typography align="center" variant="h5" className="sectionTitle">
                {section.getPositionWithDots()} {section.getShortName()}
              </Typography>
            )}
            <Typography className="information" paragraph>
              {section.getTextToDisplay()}
            </Typography>
            {section.getChildren().map((node: INodeModel, index: number) => {
              return node.isHidden()
                ? null
                : node.isQuestion()
                ? questionViewGetter(index, node)
                : sectionViewGetter(index, node);
            })}
          </Box>
        </Paper>
      )}
    </div>
  );
}
function createQuestion(
  confirmed: boolean,
  qLabel: string | undefined,
  qText: string | undefined,
  question: Question | undefined,
  parentSection: INodeModel,
  isOptional: boolean | undefined,
  condition: GroupedCondition | undefined
) {
  if (confirmed && qText !== undefined && question !== undefined) {
    const questionNode: NodeModel = FormModel.newQuestion(
      parentSection,
      qLabel ? qLabel : qText,
      isOptional !== undefined && isOptional,
      question,
      qText
    );
    if (condition) {
      condition.addAffectedNode(
        questionNode.getId(),
        protobuf.DecisionType.HIDE
      );
    }
  }
}
