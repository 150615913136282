import React from "react";
import { useIntl } from "react-intl";
import protobufTypes, { AnswerValue } from "src/proto/FormServerMessages";
import { NodeModel } from "src/services/models/node_model";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import {
  Grid,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { FormModel } from "src/services/models/form_model";
import RefValueGetterView from "./ref_value_getter";
import { QuestionChooser } from "./question_chooser";
import { FormDisplayMode } from "../enums";
import {
  ConditionTypeToLabelMap,
  getNumberStringAsKeysOfEnum
} from "src/components/enum_to_labels";
import { IFormModel } from "src/services/models/interface/form_model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  })
);

export type NodeConditionToView = {
  // node id
  nodeId?: number;
  // condition
  matchOperator?: protobufTypes.ConditionType;
  // TODO: support array of reference values
  // ref value
  refValue?: AnswerValue;
};

type NodeConditionViewProps = {
  displayMode: FormDisplayMode;
  // the form
  formModel: IFormModel;
  // any existing node condition
  nodeCondition?: NodeConditionToView;
  // menu of questions
  // questionsAsMenuList: React.ReactElement[];

  // call when there is change in values,
  // the change in values can be both valid and invalid
  onChange: (
    nodeId: number | undefined,
    matchOperator: protobufTypes.ConditionType | undefined,
    refVal: AnswerValue | undefined
  ) => void;
  // user wants to add a new condition
  onAddNewCondition: () => void;
  // user wants to remove this condition
  onRemoveCondition: () => void;
};

export default function NodeConditionView(props: NodeConditionViewProps) {
  const intl = useIntl();
  const selectQuestionLabel = intl.formatMessage({
    id: "app.condition.question",
    defaultMessage: "Question"
  });
  const selectMatchOperatorLabel = intl.formatMessage({
    id: "app.condition.matchOperator",
    defaultMessage: "Condition"
  });

  const matchOperator = props.nodeCondition?.matchOperator ?
    props.nodeCondition?.matchOperator
    : protobufTypes.ConditionType.EQ;

  const cTypeToLabel = ConditionTypeToLabelMap(intl);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={11}>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={5}>
              <QuestionChooser
                formModel={props.formModel}
                nodeId={props.nodeCondition?.nodeId}
                onChange={(id: number) =>
                  props.onChange(
                    id,
                    matchOperator,
                    props.nodeCondition?.refValue
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl>
                <InputLabel id="match-type-select-label">
                  {selectMatchOperatorLabel}
                </InputLabel>
                <Select
                  disabled={props.displayMode !== FormDisplayMode.EditMode}
                  native
                  labelId="match-type-select-label"
                  aria-label={selectMatchOperatorLabel}
                  name={selectMatchOperatorLabel}
                  defaultValue={
                    props.nodeCondition?.matchOperator
                      ? protobufTypes.ConditionType[
                          props.nodeCondition.matchOperator
                        ]
                      : matchOperator
                  }
                  onChange={(e) => {
                    const newMatchOp =
                      protobufTypes.ConditionType[
                        e.target
                          .value as keyof typeof protobufTypes.ConditionType
                      ];
                    // inform container about change by user
                    props.onChange(
                      props.nodeCondition?.nodeId,
                      newMatchOp,
                      props.nodeCondition?.refValue
                    );
                  }}
                >
                  {
                    /* https://stackoverflow.com/questions/39372804/typescript-how-to-loop-through-enum-values-for-display-in-radio-buttons */
                    getNumberStringAsKeysOfEnum(
                      protobufTypes.ConditionType
                    ).map((cType, index) => (
                      <option key={index} value={cType.toString()}>
                        {cTypeToLabel.get(cType)}
                      </option>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              {props.nodeCondition?.nodeId === undefined ? null : (
                <RefValueGetterView
                  displayMode={props.displayMode}
                  questionNode={
                    props.formModel.getNode(
                      props.nodeCondition.nodeId
                    ) as NodeModel
                  }
                  refValue={props.nodeCondition?.refValue}
                  onSelect={(values) =>
                    props.onChange(
                      props.nodeCondition?.nodeId,
                      matchOperator,
                      values[0]
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {props.displayMode !== FormDisplayMode.EditMode ? null : (
          <Grid item xs={1} direction="column">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Add">
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onAddNewCondition();
                    }}
                  >
                    <Icon arial-label="add after">add_circle</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Remove">
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.onRemoveCondition();
                    }}
                  >
                    <Icon arial-label="remove">remove_circle</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
