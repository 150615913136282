import React from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import GenericTable from "../common/generic_table";
import {
  GenericActionMenuCreator,
  MenuItemType
} from "../common/generic_action_menu";
import { HeadCellType } from "../common/enhanced_table_head";
import { useFetchFormList } from "src/hooks/form";
import { messages } from "../../translations/intl_messages";
import { getContextActionMenuItems } from "./getContextActionMenuItems";

/**
 * Goal : To make a table with header which contains sortable columns.
 * optionally, should allow multiple rows selection, for certain action or actions.
 *
 * The bottom, optionally, should have page footer.
 * This table component should be reusable.
 *
 * numCols: number of columns
 * tableHeader: list of elements
 * dataRows: array of list of data elements
 */

export type FormMetaData = {
  guid: string;
  accountId: string;
  version: number;
  synched: boolean;
  hash: string;
  formName: string;
  creationTime: number;
  lastUpdateTime: number;
};

type TableColumns = {
  guid: string;
  version: number;
  formName: string;
  creationTime: number;
  lastUpdateTime: number;
};

export default function FormList() {
  const intl = useIntl();
  const history = useHistory<any>();
  const { formsArray, err } = useFetchFormList();
  const errorMessage = intl.formatMessage(messages.networkError);

  const contextMenuItems: MenuItemType[] = getContextActionMenuItems(
    intl,
    history
  );
  const headCells: HeadCellType<TableColumns>[] = [
    {
      id: "formName",
      rightAlign: false,
      disablePadding: true,
      label: intl.formatMessage(messages.formName),
      sortable: true
    },
    {
      id: "version",
      rightAlign: true,
      disablePadding: false,
      label: intl.formatMessage(messages.version)
    },
    {
      id: "creationTime",
      rightAlign: false,
      disablePadding: false,
      label: intl.formatMessage(messages.creationDate),
      sortable: true,
      stringConverter: (t) => intl.formatDate(new Date(t))
    },
    {
      id: "lastUpdateTime",
      rightAlign: false,
      disablePadding: false,
      label: intl.formatMessage(messages.lastUpdateDate),
      sortable: true,
      stringConverter: (t) => intl.formatDate(new Date(t))
    }
  ];
  const rows: TableColumns[] | undefined = formsArray?.map((elem) => {
    return {
      guid: elem.guid,
      formName: elem.formName,
      version: elem.version,
      creationTime: elem.creationTime,
      lastUpdateTime: elem.lastUpdateTime
    };
  });

  return (
    <div>
      {/*ToDo: add action to take on selected forms Do refactoring of GenericTable
      as given at 10:36 of https://www.youtube.com/watch?v=nQo0EdHNjto That will
      make much easy to use headCells.*/}
      {rows === undefined ? null : (
        <GenericTable<TableColumns>
          defaultSortKeyName={"formName"}
          guid={"guid"}
          rows={rows}
          headCells={headCells}
          addSelectionCheckbox={false}
          actionMenu={GenericActionMenuCreator(contextMenuItems)}
        />
      )}
      {err ? errorMessage : null}
    </div>
  );
}
