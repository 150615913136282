import React, { useState } from "react";
import { useIntl } from "react-intl";
import protobufTypes, {
  Question,
  AnswerOption,
  AnswerValue
} from "src/proto/FormServerMessages";
import truncate from "lodash/truncate";
import { NodeModel } from "src/services/models/node_model";
import {
  Select,
  InputLabel,
  TextField,
  FormControl,
  MenuItem,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "../enums";

type RefValueGetterViewProps = {
  displayMode: FormDisplayMode;
  // question
  questionNode: NodeModel;
  // TODO: support multiple ref values in future
  // existing reference value
  refValue?: AnswerValue;
  // callback to call on change made
  onSelect: (values: AnswerValue[]) => void;
};
/**
 * Gets the reference value to use during comparison with answer to the question
 * @param props
 */
export default function RefValueGetterView(props: RefValueGetterViewProps) {
  const intl = useIntl();
  const selectRefValLabel = intl.formatMessage({
    id: "app.condition.refValue",
    defaultMessage: "Value to compare against"
  });
  const yesText = intl.formatMessage({
    id: "app.question.yesText",
    defaultMessage: "Yes",
    description: "Yes in the user's language"
  });
  const noText = intl.formatMessage({
    id: "app.question.noText",
    defaultMessage: "No",
    description: "No in the user's language"
  });
  const question = props.questionNode.node.question as Question;
  const questionType = question.type;
  const [refVal, setRefVal] = useState(props.refValue);
  // for fill-in blank type, take the general text as input
  return (
    <React.Fragment>
      {questionType === protobufTypes.QuestionType.FILL_IN_BLANK ? (
        <TextField
          disabled={props.displayMode !== FormDisplayMode.EditMode}
          fullWidth
          label={selectRefValLabel}
          value={refVal?.textValue}
          onChange={(e) => {
            let answerValue = new AnswerValue();
            // TODO: store the string to the value type as used by the fill-in blank question
            answerValue.value = "textValue";
            answerValue.textValue = e.target.value;
            props.onSelect([answerValue]);
          }}
        />
      ) : (
        <FormControl fullWidth>
          <InputLabel id="select-ref-label">{selectRefValLabel}</InputLabel>
          <Select
            disabled={props.displayMode !== FormDisplayMode.EditMode}
            fullWidth
            labelId="select-ref-label"
            aria-label={selectRefValLabel}
            value={JSON.stringify(refVal)}
            onChange={(e) => {
              // REVIEW: is e.target.value value field of <MenuItem> or is it string ?
              // setNodeId(parseInt(e.target.value as string));
              const newRefVal = JSON.parse(
                e.target.value as string
              ) as AnswerValue;
              setRefVal(newRefVal);
              props.onSelect([newRefVal]);
            }}
          >
            {questionType === protobufTypes.QuestionType.YES_NO
              ? [
                  FilledFormModel.YesAnswerValue,
                  FilledFormModel.NoAnswerValue
                ].map((ansVal, index) => (
                  <MenuItem key={index} value={JSON.stringify(ansVal)}>
                    {ansVal.trueFalseValue ? yesText : noText}
                  </MenuItem>
                ))
              : (question.options as AnswerOption[]).map((option, index) => (
                  <MenuItem key={index} value={JSON.stringify(option.value)}>
                    {truncate(option.accompaniedText, { length: 15 })}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      )}
    </React.Fragment>
  );
}
