import React, { useRef, useState } from "react";
import { Button, Container } from "@material-ui/core";
import { FormDisplayMode } from "../formelements/enums";
import { FormModel } from "src/services/models/form_model";
import { NodeModel } from "src/services/models/node_model";
import protobufTypes, { Question } from "src/proto/FormServerMessages";
import { IFormModel } from "src/services/models/interface/form_model";
import { useHistory } from "react-router-dom";
import NewConditionModal from "../formelements/conditions/new_condition_modal";
import { FormScreenType } from "../formelements/form";
import { NewQuestionButton, NewQuestionButtonProps } from "./NewQuestionButton";

import ReCaptcha from "src/components/recaptcha";

import {
  NewSectionPopupButton,
  NewSectionPopupButtonPropsType
} from "./NewSectionPopupButton";
import {
  PreviousNextSectionNavigation,
  PreviousNextSectionNavigationPropsType
} from "./PreviousNextSectionNavigation";

type FormFooterPropsType = {
  displayMode: FormDisplayMode;
  formModel: FormModel;
  submit?: boolean;
  submitToolTip?: string; // the tooltip to show for disabled submit button which tells why submit button is disabled.
  onSubmit: (data?: any) => void;
  currentFormScreenType: FormScreenType;
  onSetFormScreenType: (formScreenType: FormScreenType) => void;
  formId?: string; // formId is used by condition button
} & NewSectionPopupButtonPropsType &
  NewQuestionButtonProps &
  PreviousNextSectionNavigationPropsType;
/**
 * The display element as footer of form/filled-form.
 *
 * This display element will placed potentially in the top and in the bottom of display.
 */
export default function FormFooter(props: FormFooterPropsType) {
  const history = useHistory();
  const recaptchaEl = useRef<null|ReCaptcha>(null);
  const onLoadRecaptcha = ()=>{
    if (recaptchaEl.current) {
      // recaptchaEl.current.reset();
    }
  }
  const verifyCallback = (recaptchaToken: string) => {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token");
    props.onSubmit();
  }
  const onSubmit = () => {
    // the flow will be
    //   verify that form is filled enough to be submitted
    //   if not return back
    //   else recaptchaEl.current.execute();
    // TODO: execute above flow
    if (recaptchaEl.current) {
      recaptchaEl.current.execute();
      // recaptchaEl.current.reset();
    }
  }
  const formSubmitButton: JSX.Element = (
    <>

      {props.submit === false ? null :
       (
        <Button
          onClick={(e) => {
            console.log("clicked the submit button");
            props.displayMode === FormDisplayMode.FillMode ? onSubmit() : props.onSubmit();
          }}
        >
          SUBMIT
        </Button>
      )}
            {(props.submit === false || props.displayMode !== FormDisplayMode.FillMode) ? null :         <ReCaptcha
            ref={recaptchaEl}
            size="invisible"
            render="explicit"
            onLoad={onLoadRecaptcha}
            onSuccess={verifyCallback}
        />
        }
    </>
  );

  const showCondition =
    (props.displayMode === FormDisplayMode.EditMode ||
      props.displayMode === FormDisplayMode.ViewMode) &&
    props.formModel &&
    props.formModel.getQuestionNodes().length > 0;

  const [addConditionDialog, openAddConditionDialog] = useState(false);
  const createNewConditionButton: JSX.Element = (
    <>
      <Button
        onClick={(e) => {
          openAddConditionDialog(true);
        }}
      >
        Add Condition
      </Button>
      <NewConditionModal
        displayMode={props.displayMode}
        formModel={props.formModel}
        key={Math.random() * 100000}
        open={addConditionDialog}
        onClose={() => openAddConditionDialog(false)}
      />
    </>
  );

  const conditionButton: JSX.Element = (
    <>
      <Button
        onClick={(e) => {
          props.onSetFormScreenType(FormScreenType.ConditionsScreen);
        }}
      >
        Conditions
      </Button>
    </>
  );

  const goBackToMainFormViewButton: JSX.Element = (
    <>
      {props.currentFormScreenType !== FormScreenType.QuestionsScreen ? (
        <Button
          onClick={(e) => {
            props.onSetFormScreenType(FormScreenType.QuestionsScreen);
          }}
        >
          GO BACK
        </Button>
      ) : null}
    </>
  );

  return (
    <>
      <Container>
        {props.displayMode === FormDisplayMode.EditMode ? (
          <>
            <NewSectionPopupButton
              formModel={props.formModel}
              onAddSection={props.onAddSection}
            />
            <NewQuestionButton
              formModel={props.formModel}
              onAddQuestion={props.onAddQuestion}
            />
            {showCondition ? createNewConditionButton : null}
          </>
        ) : null}
        {showCondition ? conditionButton : null}
      </Container>

      <PreviousNextSectionNavigation {...props}>
        {goBackToMainFormViewButton}
        {props.displayMode === FormDisplayMode.EditMode ||
        props.displayMode === FormDisplayMode.FillMode
          ? formSubmitButton
          : null}
      </PreviousNextSectionNavigation>
    </>
  );
}
