import { defineMessages } from "react-intl";

export const messages = defineMessages({
  networkError: {
    id: "app.error.network",
    defaultMessage: "Failed to get data from internet",
    description: "message to show on network/fetch issue"
  },
  view: {
    id: "app.view",
    defaultMessage: "View",
    description: "view word"
  },
  edit: {
    id: "app.edit",
    defaultMessage: "Edit",
    description: "edit word"
  },
  delete: {
    id: "app.delete",
    defaultMessage: "Delete",
    description: "delete word"
  },
  formName: {
    id: "app.formName",
    defaultMessage: "Form Name",
    description: "the label to use for form name "
  },
  formDescr: {
    id: "form.label.formDescr",
    defaultMessage: "Text below form name"
  },
  formTextLabel: {
    id: "form.label.formTextLabel",
    defaultMessage: "Text"
  },
  version: {
    id: "app.version",
    defaultMessage: "Version",
    description: "label to use for version"
  },
  creationDate: {
    id: "app.creationDate",
    defaultMessage: "Date of creation",
    description: "label to use for date of creation"
  },
  lastUpdateDate: {
    id: "app.lastUpdateDate",
    defaultMessage: "Date of last update",
    description: "label to use for date of last update"
  },
  questionEditAction: {
    id: "app.question.edit",
    defaultMessage: "Edit question text",
    description: "user action to edit the question"
  },
  questionMoveAction: {
    id: "app.question.move",
    defaultMessage: "Move question",
    description: "user action to move the question"
  },
  questionAddBeforeAction: {
    id: "app.question.add.before",
    defaultMessage: "Add question before",
    description: "user action to add new question before"
  },
  questionAddAfterAction: {
    id: "app.question.add.after",
    defaultMessage: "Add question after",
    description: "user action to add new question after this"
  },
  sectionName: {
    id: "form.label.sectionName",
    defaultMessage: "Section Name"
  },
  sectionEditName: {
    id: "section.edit.name",
    defaultMessage: "Edit section name"
  },
  billingLabel: {
    id: "app.billing",
    defaultMessage: "Billing",
    description: "label to show for billing"
  },
  createForm: {
    id: "app.create_form",
    defaultMessage: "Create Form",
    description: "Create Form"
  },
  formList: {
    id: "app.formList",
    defaultMessage: "List of Forms"
  },
  recordViewTitle: {
    id: "app.view_record",
    defaultMessage: "View Record",
    description: "View Record"
  },
  conditionName: {
    id: "app.condition.name",
    defaultMessage: "Condition Name",
    description: "name of condition"
  },
  conditionClause: {
    id: "app.condition.clause",
    defaultMessage: "Condition"
  },
  conditionResult: {
    id: "app.condition.result",
    defaultMessage: "Impact of condition"
  },
  yesText: {
    id: "app.question.yesText",
    defaultMessage: "Yes",
    description: "Yes in the user's language"
  },
  noText: {
    id: "app.question.noText",
    defaultMessage: "No",
    description: "No in the user's language"
  },
  "app.question.text": {
    id: "app.question.text",
    defaultMessage: "Question Text",
    description:
      "The label to show to user before the input field that takes text of question"
  },
  "app.question.shortName": {
    id: "app.question.shortName",
    defaultMessage: "Label to use to refer to the question",
    description: "The label to show as attribute"
  },
  "app.question.type": {
    id: "app.question.type",
    defaultMessage: "Type of Question"
  },
  "app.question.fillIn.blanktypeLabel": {
    id: "app.question.fillIn.blanktypeLabel",
    defaultMessage: "Type of Fill-In"
  },
  "app.question.optionalLabel": {
    id: "app.question.optionalLabel",
    defaultMessage: "Optional Question"
  },
  "app.question.isAnAttribute": {
    id: "app.question.isAnAttribute",
    defaultMessage: "Use as attribute"
  },
  "app.question.isConditional": {
    id: "app.question.isConditional",
    defaultMessage: "Conditional"
  },
  "app.question.shortname_help": {
    id: "app.question.shortname_help",
    defaultMessage: "Name should be unique with length less than 20 characters"
  },
  "app.question.enterOptions": {
    id: "app.question.enterOptions",
    defaultMessage: "Enter choice"
  }
});
