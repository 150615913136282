import React from "react";
import AccountDetail, { AccountProps } from "src/components/account/account";

type AccountPropsType = {};
export function Account(props: AccountPropsType) {
  const dummy: AccountProps = {
    creationTime: new Date("07/07/2020"),
    numOfForms: 5,
    numOfRecordsExisting: 200,
    numOfRecordsExisting30Days: 45,
    numOfRecordsTotal: 500,
    numOfRecordsTotal30Days: 80,
  };
  return (
    <div>
      Account detail, Account Holder Name, Address, ...
      <AccountDetail {...dummy} />
    </div>
  );
}
