import React, { ReactElement } from "react";
import { Button, Typography } from "@material-ui/core";
import { FormDisplayMode } from "../formelements/enums";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

const StyledSpan = styled.span`
  color: palevioletred;
`;

type QuestionHeadPropsType = {
  sequenceNo?: string;
  optional?: boolean;
};
/**
 * The display element as header of form/filled-form.
 *
 * This display element will placed potentially in the top and in the bottom of display.
 */
const QuestionHead: React.FC<QuestionHeadPropsType> = function (props) {
  const optional: boolean = props.optional !== undefined && props.optional;
  let SeqNoTag: ReactElement | null = (
    <Tooltip title={optional ? "optional" : "required"}>
      <StyledSpan>
        {props.sequenceNo}
        {optional ? null : "*"}
      </StyledSpan>
    </Tooltip>
  );

  return <>{SeqNoTag}</>;
};

export default QuestionHead;
