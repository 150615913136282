import React from "react";
import RecordListComponent from "src/components/record/record_list";
import Navigation from "src/navigation/navigation";
import { useIntl, defineMessages } from "react-intl";

type RecordsListPropsType = {};
export function RecordsList(props: RecordsListPropsType) {
  const intl = useIntl();
  const messages = defineMessages({
    recordListTitle: {
      id: "app.recordList",
      defaultMessage: "List of Records",
      description: "List of records owned by account",
    },
  });
  const recordListTitle = intl.formatMessage(messages.recordListTitle);

  return (
    <div className="App">
      <Navigation title={recordListTitle}>
        <div>
          List of records, filtering criteria, sort criteria, pagination,
          actions to take on selected record
          <RecordListComponent />
        </div>
      </Navigation>
    </div>
  );
}
