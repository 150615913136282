import React, { useState } from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import GenericTable from "../common/generic_table";
import {
  GenericActionMenuCreator,
  MenuItemType
} from "../common/generic_action_menu";
import { getContextMenuRecordListRows } from "./getContextMenuRecordListRows";
import { HeadCellType } from "../common/enhanced_table_head";
import { useFetchRecordList } from "../../hooks/useFetchRecordList";
import { getTableRowsFromRecordArray } from "./getRecordListTableRowsFromRecordArray";

/**
 * Goal : To make a table with header which contains sortable columns.
 * optionally, should allow multiple rows selection, for certain action or actions.
 *
 * The bottom, optionally, should have page footer.
 * This table component should be reusable.
 *
 * numCols: number of columns
 * tableHeader: list of elements
 * dataRows: array of list of data elements
 */

/*
	GUID         string `json:"recordGuid"`
	FormGUID     string `json:"formGuid"`
	FormVersion  int    `json:"formVersion"`
	CreationTime int64  `json:"recordDate"`
	// the person who created the record
	AccountID string `json:"accountID"`
	Synched   bool   `json:"synched"`
	// record can be draft or completely submitted
	// the support for draft state will allow the user to
	// complete the form filling from different machines
	// and not having to suffer from frustration of starting from scratch
	// The draft record will be deleted after 7 days of last modification.
	// Draft record will not be visible to the form owner.
	IsDraft bool `json:"isDraft"`
*/

export type TableColumns = {
  formGuid: string;
  formName: string;
  version: number;
  numRecords: number;
  lastCreated: string;
};

export type FormRecordMetaData = {
  formGuid: string;
  formName: string;
  version: number;
  numRecords: number;
  lastCreated: number;
};

/**
 * In form, we need to ask user what field of record (or combination of fields )
 * can be used as record name for display of record.
 * By default : it can be just the date-time of submission.
 * Or should we ask user ?
 */
const headCells: HeadCellType<TableColumns>[] = [
  {
    id: "formName",
    rightAlign: false,
    disablePadding: true,
    label: "Form Name",
    sortable: true
  },
  {
    id: "version",
    rightAlign: true,
    disablePadding: false,
    label: "Form Version"
  },
  // TODO: add number of records for the form
  {
    id: "numRecords",
    rightAlign: true,
    disablePadding: false,
    label: "Number of submissions"
  },
  {
    id: "lastCreated",
    rightAlign: false,
    disablePadding: false,
    label: "Date of latest submission",
    sortable: true
  }
];

export default function RecordList() {
  const intl = useIntl();
  const history = useHistory();

  // we want to allow user to set the time interval for which
  // he/she wants to see the records.
  const [lowerLimitSubmissionTime, setLowerLimitSubmissionTime] = useState(0);
  const [upperLimitSubmissionTime, setUpperLimitSubmissionTime] = useState<
    undefined | number
  >();
  const recordsArray = useFetchRecordList();

  const contextMenuItems: MenuItemType[] = getContextMenuRecordListRows(
    history,
    lowerLimitSubmissionTime,
    upperLimitSubmissionTime
  );

  // convert the metadata received from server about records
  // to properties that UI element takes in.

  const rows: TableColumns[] = getTableRowsFromRecordArray(
    recordsArray,
    lowerLimitSubmissionTime,
    upperLimitSubmissionTime,
    intl
  );

  return (
    <div>
      <p>TODO: return form name in the record metadata returned by server</p>
      {rows === undefined ? null : (
        <GenericTable<TableColumns>
          defaultSortKeyName={"lastCreated"}
          guid={"formGuid"}
          rows={rows}
          headCells={headCells}
          addSelectionCheckbox={false}
          actionMenu={GenericActionMenuCreator(contextMenuItems)}
        />
      )}
    </div>
  );
}
