import protoTypes, {
  FilledForm,
  Form,
  Node,
  Question,
  AnswerGiven,
  AnswerValue,
  AnswerOption,
  IAnswerGiven,
} from "src/proto/FormServerMessages";
import { v4 as uuid } from "uuid";
//import { NodeModel } from "./nodeModel";
import { FormModel } from "./form_model";
import { NodeModel } from "./node_model";
import { RecordAttribute } from "../datatypes/metadata";

/**
 * FilledFormModel wraps the Form
 * provides the methods to keep track of filling of form
 * and supports the decision to take based on current set of
 * answers.
 */
export class FilledFormModel {
  public filledForm: FilledForm | undefined;
  public form: FormModel;
  private answerGivenMap = new Map<number, AnswerGiven>();
  private requiredQuestionNodeIds = new Set<number>();
  private questionNodeIds = new Set<number>();
  private optionalQuestionNodeIds = new Set<number>();

  // attributes for the record, which will be saved in metadata of record
  // the attributes are the answers of certains questions which form-creator
  // marked that they should be used for creating attributes
  // Getting the record attributes
  //    first, from FormModel, get all questions which are marked for attribute purpose.
  //    then, get their corresponding answers from answerGivenMap
  //    and use them to construct attributes
  private attributeCarryingQuestionNodes: NodeModel[] = [];
  // private attributes: RecordAttribute[];
  public getAttributes(): RecordAttribute[] {
    const attributes: RecordAttribute[] = [];
    // loop over all attribute carrying questions
    this.attributeCarryingQuestionNodes.forEach((qNode: NodeModel) => {
      // get the answer given for the question
      const answerAttrValue = this.answerGivenMap.get(qNode.id);
      if (answerAttrValue !== undefined) {
        // if answer is given
        let answerAttr: RecordAttribute = {};
        answerAttr[qNode.node.shortName] = JSON.stringify(
          // array for multi-choice answers
          answerAttrValue.answerValues
        );
        attributes.push(answerAttr);
      }
    });
    return attributes;
  }

  public static YesAnswerValue: AnswerValue = FilledFormModel.InitYesAnswerValue();
  public static NoAnswerValue: AnswerValue = FilledFormModel.InitNoAnswerValue();

  private static InitYesAnswerValue() {
    FilledFormModel.YesAnswerValue = new AnswerValue();
    FilledFormModel.YesAnswerValue.trueFalseValue = true;
    return FilledFormModel.YesAnswerValue;
  }
  private static InitNoAnswerValue() {
    FilledFormModel.NoAnswerValue = new AnswerValue();
    FilledFormModel.NoAnswerValue.trueFalseValue = false;
    return FilledFormModel.NoAnswerValue;
  }
  private constructor(form: Form) {
    this.form = FormModel.from(form);
  }
  /*
    Caller is expected to have only FilledForm, and from form GUID in FilledForm
    caller is expected to fetch Form from server, and create Form object;
    and then pass both FilledForm and Form objects to below function.
    */
  public static fromServerRecord(filledForm: FilledForm, form: Form) {
    let model = new FilledFormModel(form);
    model.filledForm = filledForm;
    filledForm.answers.forEach((answer: IAnswerGiven) => {
      model.answerGivenMap.set(
        answer.questionNodeId as number,
        answer as AnswerGiven
      );
    });
    // REVIEW decision support
    model.form.getConditionsHandler().setAnswers(filledForm);
    model.setQuestionNodeMetadata();
    return model;
  }
  public static fromForm(form: Form) {
    let model = new FilledFormModel(form);
    model.filledForm = new FilledForm();
    model.filledForm.guid = uuid();
    model.filledForm.guidForm = form.guid;
    model.setQuestionNodeMetadata();
    return model;
  }

  // BUG: below is not taking care of
  // transitivity of optional from section
  // to its children
  private setQuestionNodeMetadata() {
    // iterate over list of question
    this.form?.getQuestionNodes().forEach((qNode: NodeModel) => {
      this.questionNodeIds.add(qNode.id);
      if (qNode.isOptional()) {
        this.optionalQuestionNodeIds.add(qNode.id);
      } else {
        this.requiredQuestionNodeIds.add(qNode.id);
      }
      // is this question marked to be used to get attribute for the record
      if (qNode.isCarryingAttribute()) {
        this.attributeCarryingQuestionNodes.push(qNode);
      }
    });
  }

  /**
   * Returns the protobuf message FilledForm
   * based on answers given by the form filler
   *
   * @return FilledForm
   */

  public getFilledForm(): FilledForm | undefined {
    if (!this.areRequiredQuestionsAnswered()) {
      return undefined;
    }
    this.answerGivenMap.forEach((answer: AnswerGiven) => {
      this.filledForm?.answers.push(answer);
    });

    return this.filledForm;
  }

  public giveTextAnswer(nodeModel: NodeModel, fillInAnswer: string): boolean {
    const node = nodeModel.node;

    if (
      (node.question as Question).type !== protoTypes.QuestionType.FILL_IN_BLANK
    ) {
      console.error(
        `giveTextAnswer called for non fill-in blank question ${node.shortName}`
      );
    }
    let answerGiven: AnswerGiven = new AnswerGiven();
    answerGiven.questionNodeId = node.id;
    let answerValue = new AnswerValue();
    answerValue.textValue = fillInAnswer;
    answerGiven.answerValues = [answerValue];
    this.answerGivenMap.set(node.id, answerGiven);

    // inform conditions machine about answer given
    return this.form.getConditionsHandler().addAnswer(node.id, [answerValue]);
  }

  public giveNumericAnswer(
    nodeModel: NodeModel,
    fillInAnswer: number
  ): boolean {
    const node = nodeModel.node;

    if (
      (node.question as Question).type !== protoTypes.QuestionType.FILL_IN_BLANK
    ) {
      console.error(
        `giveNumericAnswer called for non fill-in blank question ${node.shortName}`
      );
    }
    // TODO: if creating new variables every time of change in answer is problem (memory consuming)
    // then refactor the code, so that UI for question gets the AnswerGiven as parameter
    let answerGiven: AnswerGiven = new AnswerGiven();
    answerGiven.questionNodeId = node.id;
    let answerValue = new AnswerValue();
    answerValue.decimalValue = fillInAnswer;
    answerGiven.answerValues = [answerValue];
    this.answerGivenMap.set(node.id, answerGiven);
    // inform conditions machine about answer given
    return this.form.getConditionsHandler().addAnswer(node.id, [answerValue]);
  }
  // https://stackoverflow.com/questions/22266826/how-can-i-do-a-shallow-comparison-of-the-properties-of-two-objects-with-javascri
  // I am supposing below can compare equality of AnswerValue objects
  // TODO: verify that below really can compare two AnswerValue objects
  public static areEqualShallow(a: any, b: any): boolean {
    for (var key in a) {
      if (!(key in b) || a[key] !== b[key]) {
        return false;
      }
    }
    for (var key in b) {
      if (!(key in a) || a[key] !== b[key]) {
        return false;
      }
    }
    return true;
  }
  public giveOptionAnswer(nodeModel: NodeModel, optionIndex: number): boolean {
    const node = nodeModel.node;
    if (
      (node.question as Question).type === protoTypes.QuestionType.FILL_IN_BLANK
    ) {
      console.error(
        `giveOptionAnswer called for fill-in blank question ${node.shortName}`
      );
      throw new Error(
        `giveOptionAnswer called for fill-in blank question ${node.shortName}`
      );
    }

    const choiceValue: AnswerValue = ((node.question as Question).options[
      optionIndex
    ] as AnswerOption).value as AnswerValue;

    let existingAnswerGiven: AnswerGiven | undefined = this.answerGivenMap.get(
      node.id
    );

    if (existingAnswerGiven) {
      existingAnswerGiven.answerValues = [choiceValue];
    } else {
      const answerGiven: AnswerGiven = new AnswerGiven();
      answerGiven.questionNodeId = node.id;
      answerGiven.answerValues = [choiceValue];
      this.answerGivenMap.set(node.id, answerGiven);
    }
    // inform conditions machine about answer given
    return this.form.getConditionsHandler().addAnswer(node.id, [choiceValue]);
  }
  public resetOptionAnswer(nodeModel: NodeModel, optionIndex: number): void {
    const node = nodeModel.node;
    if (
      (node.question as Question).type === protoTypes.QuestionType.FILL_IN_BLANK
    ) {
      console.error(
        `resetOptionAnswer called for fill-in blank question ${node.shortName}`
      );
      throw new Error(
        `resetOptionAnswer called for fill-in blank question ${node.shortName}`
      );
    }

    const choiceValue: AnswerValue = ((node.question as Question).options[
      optionIndex
    ] as AnswerOption).value as AnswerValue;

    let existingAnswerGiven: AnswerGiven | undefined = this.answerGivenMap.get(
      node.id
    );

    if (existingAnswerGiven) {
      const index = existingAnswerGiven.answerValues.indexOf(choiceValue);
      if (index > -1) {
        existingAnswerGiven.answerValues.splice(index, 1);
        // inform conditions machine about answer given
        this.form
          .getConditionsHandler()
          .addAnswer(
            node.id,
            existingAnswerGiven.answerValues as AnswerValue[]
          );
      }
      if (existingAnswerGiven.answerValues.length < 1) {
        // remove, no answer is available
        this.answerGivenMap.delete(node.id);
        // inform conditions machine about answer given
        this.form.getConditionsHandler().resetAnswer(node.id);
      }
    }
  }
  public selectChoice(nodeModel: NodeModel, optionIndex: number): void {
    const node = nodeModel.node;
    if (
      (node.question as Question).type === protoTypes.QuestionType.FILL_IN_BLANK
    ) {
      console.error(
        `giveOptionAnswer called for fill-in blank question ${node.shortName}`
      );
      throw new Error(
        `giveOptionAnswer called for fill-in blank question ${node.shortName}`
      );
    }
    const choiceValue: AnswerValue = ((node.question as Question).options[
      optionIndex
    ] as AnswerOption).value as AnswerValue;

    let existingAnswerGiven: AnswerGiven | undefined = this.answerGivenMap.get(
      node.id
    );

    if (existingAnswerGiven) {
      for (let i = 0; i < existingAnswerGiven.answerValues.length; i++) {
        // check if it is already part of answer, and return if so
        if (
          FilledFormModel.areEqualShallow(
            existingAnswerGiven.answerValues[i],
            choiceValue
          )
        ) {
          return;
        }
      }
      existingAnswerGiven.answerValues.push(choiceValue);
      // inform conditions machine about answer given
      this.form
        .getConditionsHandler()
        .addAnswer(node.id, existingAnswerGiven.answerValues as AnswerValue[]);
    } else {
      const answerGiven: AnswerGiven = new AnswerGiven();
      answerGiven.questionNodeId = node.id;
      answerGiven.answerValues = [choiceValue];
      this.answerGivenMap.set(node.id, answerGiven);
      // inform conditions machine about answer given
      this.form.getConditionsHandler().addAnswer(node.id, [choiceValue]);
    }
  }

  public giveYesNoAnswer(nodeModel: NodeModel, answerValue: boolean): void {
    const node = nodeModel.node;
    if ((node.question as Question).type !== protoTypes.QuestionType.YES_NO) {
      console.error(
        `giveOptionAnswer called for non yes-no question ${node.shortName}`
      );
      throw new Error(
        `giveOptionAnswer called for non yes-no question ${node.shortName}`
      );
    }

    let answerGiven: AnswerGiven = new AnswerGiven();
    answerGiven.questionNodeId = node.id;
    answerGiven.answerValues = [
      answerValue
        ? FilledFormModel.YesAnswerValue
        : FilledFormModel.NoAnswerValue,
    ];
    this.answerGivenMap.set(node.id, answerGiven);
    // inform conditions machine about answer given
    this.form
      .getConditionsHandler()
      .addAnswer(node.id, answerGiven.answerValues as AnswerValue[]);
  }
  public resetYesNoAnswer(
    nodeModel: NodeModel,
    answerValueReset: boolean
  ): void {
    const node = nodeModel.node;
    if ((node.question as Question).type !== protoTypes.QuestionType.YES_NO) {
      console.error(
        `resetYesNoAnswer called for non yes-no question ${node.shortName}`
      );
      throw new Error(
        `resetYesNoAnswer called for non yes-no question ${node.shortName}`
      );
    }

    const resetValue: AnswerValue = answerValueReset
      ? FilledFormModel.YesAnswerValue
      : FilledFormModel.NoAnswerValue;

    let existingAnswerGiven: AnswerGiven | undefined = this.answerGivenMap.get(
      node.id
    );

    if (existingAnswerGiven) {
      if (existingAnswerGiven.answerValues[0] === resetValue) {
        // remove
        this.answerGivenMap.delete(node.id);
        // inform conditions machine about answer given
        this.form.getConditionsHandler().resetAnswer(node.id);
      }
    }
  }
  /**
   * Returns the list of questions whose answers is required
   * but haven't been answered yet
   *
   * @return the node ids
   */
  public getNotAnsweredRequiredQuestions(): number[] {
    let notAnswered: number[] = [];
    this.requiredQuestionNodeIds.forEach((reqQId) => {
      if (!this.answerGivenMap.has(reqQId)) {
        notAnswered.push(reqQId);
      }
    });

    return notAnswered;
  }
  /**
   * Returns true if all questions whose answers are required
   * are answered
   *
   * @return true/false
   */
  public areRequiredQuestionsAnswered(): boolean {
    for (const reqQuestionId of Array.from(this.requiredQuestionNodeIds)) {
      if (!this.answerGivenMap.has(reqQuestionId)) {
        console.log("areRequiredQuestionsAnswered: false");
        return false;
      }
    }

    return true;
  }
  /**
   * Erases the answer filled for the question
   * It happens when filler is not sure about question
   * and wants to come back to the question to fill later
   * by looking into not-yet-answered questions
   *
   * @param question
   */
  public eraseGivenAnswer(question: NodeModel): void {
    this.answerGivenMap.delete(question.node.id);
    // inform conditions machine about answer given
    this.form.getConditionsHandler().resetAnswer(question.node.id);
  }

  /**
   * Returns the answer filled by the filler for the question
   *
   * @param question
   * @return the answer filled, or null if answer is not yet filled
   */
  public getGivenAnswer(question: NodeModel): AnswerGiven | undefined {
    return this.answerGivenMap.get(question.node.id);
  }

  /**
   * returns the information about so far answered questions
   *
   * @return map of question node id to answer given
   */
  public getGivenAnswers(): Map<number, AnswerGiven> {
    return this.answerGivenMap;
  }
}
