import React, { useState } from "react";
import {
  Tooltip,
  TextField,
  FormControlLabel,
  Radio,
  Typography,
  Select,
  MenuItem,
  Button,
  Paper,
  Grid,
  Checkbox,
} from "@material-ui/core";
import "./question.css";
import {
  FormDisplayMode,
  DecisionType,
} from "src/components/formelements/enums";
import Icon from "@material-ui/core/Icon";
import "./answer_option.css";
import { XOR } from "src/tstypes/xor";
//     -------------------------------------------------
//     |    (node)  *-- auxiliary data = Question      |
//     |                     |1◆                      |
//     |---------------------|--------------------------
//                           |*
//                 ------------------------------------
//                 |   AnswerOption                   |
//                 ------------------------------------

type AnswerOptionProps = XOR<
  {
    displayMode: FormDisplayMode;
    index: number;
    textToShow?: string;
    showDecision?: boolean;
    askValue?: boolean;
    checked: boolean;
  },
  {
    displayMode: FormDisplayMode;
    index: number;
    // multiSelect and handleSelect supports the multi-select
    multiSelect: boolean;
    handleSelect: (index: number, selected: boolean) => void;
    checked: boolean;
    textToShow?: string;
    showDecision?: boolean;
    askValue?: boolean;
  }
>;

type AnswerOptionGetDecisionProps = {
  nodeToImpact?: number; // node id
  impactType?: string; // supported impact types
  onChange: (nodeToImpact: number, impactType: string) => void;
};
function AnswerOptionGetDecision(props: AnswerOptionGetDecisionProps) {
  const [nodeToImpact, setNodeToImpact] = useState(props.nodeToImpact);
  const changeSelection = function (
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    setNodeToImpact(parseInt(event.target.value as string));
  };
  // remove_cirlce is from https://material.io/resources/icons/?search=minus&style=baseline
  return (
    <div className="OptionDecision">
      <div className="item">
        <Select
          className="selectNode item"
          variant="filled"
          value={nodeToImpact === undefined ? "" : nodeToImpact}
        >
          <MenuItem value={0}> Question 1</MenuItem>
          <MenuItem value={0}> Question 2</MenuItem>
        </Select>
        <Select
          className="selectNode item"
          value={nodeToImpact === undefined ? "" : nodeToImpact}
          variant="filled"
          onChange={changeSelection}
        >
          <MenuItem value={DecisionType.HIDE}>hide</MenuItem>
          <MenuItem value={DecisionType.MAKE_OPTIONAL}>optional</MenuItem>
        </Select>
      </div>
      <div className="align_right add_remove_button item alignVertical">
        {props.nodeToImpact !== undefined ? (
          <Tooltip title="Remove">
            <Icon arial-label="remove">remove_circle</Icon>
          </Tooltip>
        ) : (
          <Tooltip title="Add">
            <Icon arial-label="add">add_circle</Icon>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

// Edit View
//    -------------------------------------------------
//    ▢ <- toggle button to switch display mode (edit/fill) : it will be set at question level
//    Text to show: [----------]                       |
//    Value (hover: On choosing option):[........]     |
//    Decision (hover: to make on choosing option) :   |
//                [Decision Type] [Affected Q/Sec] (+)(-)
//    Display type: [Options ▽]                        |
//   ---------------------------------------------------|
//
// Fill View (will depend on display types : radio button, chip button, )
//    () Text to show
export default function AnswerOptionView(props: AnswerOptionProps) {
  const onDecisionChange = () => void {};

  const fillView = props.multiSelect ? (
    <FormControlLabel
      control={
        <Checkbox
          disabled={
            props.displayMode === FormDisplayMode.RecordMode && !props.checked
          }
          checked={
            props.checked // add logic to check  value.answerValues
          }
          onChange={(_event, checked) =>
            props.handleSelect(props.index, checked)
          }
          name={props.textToShow}
          color="secondary"
        />
      }
      label={props.textToShow}
    />
  ) : (
    <FormControlLabel
      value={`${props.index}`}
      control={
        <Radio
          disabled={
            props.displayMode === FormDisplayMode.RecordMode && !props.checked
          }
        />
      }
      label={props.textToShow}
    />
  );

  const editView = (
    <div className="AnswerOptionView">
      <div className="alignVertical">
        {props.multiSelect ? (
          <Icon>check_box_outline_blank</Icon>
        ) : (
          <Icon>radio_button_unchecked</Icon>
        )}
      </div>
      <Paper className="OptionEditView">
        <div>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10}>
              <TextField
                className="optionText"
                label="Text to show for option"
                value={props.textToShow}
                variant="filled"
                multiline={true}
              />
              {props.askValue !== true ? null : (
                <Tooltip title="on choosing option">
                  <TextField
                    className="optionValue"
                    label="Value to assign to option (optional)"
                    variant="filled"
                  />
                </Tooltip>
              )}
              {props.showDecision !== true ? null : (
                <div className="decisionBox">
                  <div className="decisionText alignVertical">
                    <Tooltip title="on choosing option">
                      <Typography> Impact</Typography>
                    </Tooltip>
                  </div>
                  <AnswerOptionGetDecision
                    nodeToImpact={undefined}
                    impactType={undefined}
                    onChange={onDecisionChange}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              <div className="align_right">
                <Tooltip title="Add">
                  <Button onClick={() => {}}>
                    <Icon arial-label="add">add_circle</Icon>
                  </Button>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
  return /*props.displayMode === FormDisplayMode.EditMode ? editView :*/ fillView;
}
