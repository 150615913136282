import React from "react";
import "./welcome.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { FormattedMessage } from "react-intl";
import "firebase/auth";
import { Typography } from "@material-ui/core";

// export default function App() {

//   return (
//     <IntlProvider locale={usersLocale} key={usersLocale}>
//       <div className="App">
//       <CssBaseline />
//         <Navigation />
//       </div>
//     </IntlProvider>
//   );
// }

export default function GrowBiz() {
  return (
    <div>
      <Typography variant="h2" color="primary">
        Grow Business
      </Typography>
      <Typography>
        You can use this service in many ways, to simplify and grow your
        business.
      </Typography>
      {/* source of image: https://commons.wikimedia.org/wiki/File:5_-_man_in_suit_holding_euro_banknotes_-_royalty_free,_without_copyright,_public_domain_photo_image.JPG */}
      <img src="/app/banknotes.png" />

      <ul>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.grow_biz_feature1"
              defaultMessage="Allow customers to give you the needed information from convenience of their phone"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.grow_biz_feature2"
              defaultMessage="Design the data entry form for yourself so that you can easily fill the data and share it with customers over internet"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.grow_biz_feature3"
              defaultMessage="Get the customer orders, feedback, contact informations through forms that you edit"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.grow_biz_feature4"
              defaultMessage="Set time when link to fill the form become active"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.grow_biz_feature5"
              defaultMessage="Share the link through your own webpage, social media post, or message (sms/whatsapp/..)"
            />
          </Typography>
        </li>
      </ul>
      <Typography variant="h6">
        TODO: insert the sample small record here
      </Typography>
    </div>
  );
}
