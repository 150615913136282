import messages_hi from "../translations/hi.json";
import messages_en from "../translations/en.json";

const defaultLanguage = "en";
// using the Record type so that we can know if we missed to add entry for any language code;
type LanguageCode =
  | "en"
  | "fr"
  | "de"
  | "es" // major European languages
  | "hi"
  | "ur"
  | "bn"
  | "gu" // major Indian languages
  | "ar"
  | "ar-eg"
  | "fa" // major west asian languages
  | "zh"
  | "ja"
  | "ko" // major east asian languages
  | "ru"
  | "uk"; // russian and siblings

//interface { [key: string]: string; };
// export const supportedLanguageCodeToLocalName: Record<LanguageCode, string> = {
export const supportedLanguageCodeToLocalName: { [key: string]: string } = {
  en: "English",
  fr: "français",
  de: "Deutsch",
  es: "Spanish",
  hi: "हिंदी",
  ur: "اردو",
  bn: "বাংলা",
  gu: "ગુજરાતી",
  ar: "العربية",
  "ar-eg": "مصرى",
  fa: "فارسی",
  zh: "中文 ",
  ja: "日本語",
  ko: "한국어",
  ru: "русский",
  uk: "Українська",
};

// See // see https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-react-app-with-react-intl
export const messages: { [key: string]: any } = {
  en: messages_en,
  "en-US": messages_en,
  hi: messages_hi,
};

export function getMappedLanguage(language: string) {
  if (!(language in supportedLanguageCodeToLocalName)) {
    return defaultLanguage;
  }
  return language;
}

export class ChosenLanguage {
  constructor(public langCode: string) {
    langCode = getMappedLanguage(langCode);
  }
}
