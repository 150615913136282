export enum FormDisplayMode {
  ViewMode, // show the form, answers are not filled
  // CreateMode,
  EditMode, // edit mode is same as create mode; if props.form is undefined.
  FillMode, // shows the form to be filled, answers are recorded in FilledForm.
  RecordMode, // show the form with answers filled as coming from the FilledForm
}

export enum DecisionType {
  // every node is required by default
  // MAKE_REQUIRED = 0;
  MAKE_OPTIONAL = 0,
  HIDE = 1,
}
