import React from "react";
import Navigation from "src/navigation/navigation";
import FormikTest from "src/components/form/formik";

type AboutPropsType = {};
export default function About(props: AboutPropsType) {
  return (
    <Navigation title="About">
      <h2>About</h2>
      <FormikTest />
    </Navigation>
  );
}
