import React from "react";
import { Form as FormMessages, Node } from "src/proto/FormServerMessages";
import FormView from "src/components/formelements/form";
import { FormModel } from "src/services/models/form_model";
import "./form.css";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "src/components/formelements/enums";
import { useFetchFilledForm } from "src/hooks/form";

type ErrorType = {
  message: string;
};

function ViewRecord(props: { recordGuid: string }) {
  console.log("going to start");
  const { error, isLoaded, recordMetadata, filledForm } = useFetchFilledForm(
    props.recordGuid
    // AppConfiguration.formGuidTestOnProjBintech
  );
  //const {error, isLoaded, items, form } = useFetch("c6081c95-7ffd-49ae-aaad-bc052817e7fa");
  try {
    if (filledForm !== undefined && filledForm !== null) {
      console.log(
        "rendering form " + filledForm.form.rootNodeModel?.node.shortName
      );

      return (
        <div>
          <div className="form">
            <FormView
              filledForm={filledForm}
              displayMode={FormDisplayMode.RecordMode}
            ></FormView>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div>
          Error: {props.recordGuid} - {error.message}
        </div>
      );
    } else if (!isLoaded || filledForm === undefined || filledForm === null) {
      return <div>Loading...</div>;
    } else {
      return <div>Unknown state...</div>;
    }
  } catch {
    return <div>The record {props.recordGuid} has problem.</div>;
  }
}

export default ViewRecord;
