import React from "react";
import "./welcome.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { FormattedMessage } from "react-intl";
import "firebase/auth";
import { Typography } from "@material-ui/core";

// export default function App() {

//   return (
//     <IntlProvider locale={usersLocale} key={usersLocale}>
//       <div className="App">
//       <CssBaseline />
//         <Navigation />
//       </div>
//     </IntlProvider>
//   );
// }

export default function ShareForms() {
  return (
    <div>
      <Typography variant="h2" color="primary">
        Share Forms
      </Typography>
      <p>You own your forms.</p>
      <Typography>
        You share the form with people who will fill the form
      </Typography>

      <ul>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.share_forms_feature1"
              defaultMessage="Control who can fill the form"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.share_forms_feature2"
              defaultMessage="Add requirement for form filler to provide verified email/phone no"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.share_forms_feature3"
              defaultMessage="Expire the link to fill the form"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.share_forms_feature4"
              defaultMessage="Set time when link to fill the form become active"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              id="ad.share_forms_feature5"
              defaultMessage="Share the link through your own webpage, social media post, or message (sms/whatsapp/..)"
            />
          </Typography>
        </li>
      </ul>
      <Typography variant="h6">
        TODO: insert the sample small record here
      </Typography>
    </div>
  );
}
