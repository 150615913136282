import React, { useEffect, useState } from "react";
import { FormModel } from "src/services/models/form_model";
import { FormMetadata, RecordMetadata } from "src/services/datatypes/metadata";
import FormService from "src/services/form_service";
import { Form } from "src/proto/FormServerMessages";
import { FilledFormModel } from "src/services/models/filled_form_model";
import RecordService from "src/services/record_service";
import { FormMetaData } from "src/components/form_list/form_list";
import Axios from "axios";
import AppConfiguration from "src/configurations/constants";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { onUnauthorizedHttpResponse } from "./login";

export type ErrorType = {
  message: string;
};

export enum HttpErrorCodes {
  BAD_GATEWAY = 502,
  BAD_REQUEST = 400, // invalid syntax
  FORBIDDEN = 403, // no access right
  INTERNAL_SERVER_ERROR = 500,
  NOT_FOUND = 404,
  NOT_IMPLEMENTED = 501,
  UNAUTHORIZED = 401, // unauthenticated
}
export function useFormUpdate(form: FormModel, formMetadata: FormMetadata) {
  useEffect(() => {
    FormService.postForm(form.getForm() as Form, formMetadata);
  });
}

export const useFetchForm = (formGuid: string) => {
  const [error, setError] = useState<null | ErrorType>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [items, setItems] = useState<Array<Node>>([]);
  const [form, setForm] = useState<undefined | FormModel>(undefined);
  const intl = useIntl();

  console.log("going to start");

  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    FormService.getForm(formGuid)
      .then((form) => {
        console.log("using form " + form.guid);
        //setItems(form.node.children);
        const formModel: FormModel = FormModel.from(form);
        setForm(formModel);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log("error ? " + JSON.stringify(error));
        console.log(new Error().stack);
        setError({ message: "some error" });
      });
    //}
    //fetchData();
  }, [formGuid]);
  return {
    error,
    isLoaded, //items,
    form,
  };
};

/**
 * useFetchFilledForm is react hook to fetch the filled form (record) from server.
 * In our UI of records, we have both data recordGuid and its corresponding formGuid.
 * So, we often we don't need to determine th
 *
 *   public static fromServerRecord(filledForm: FilledForm, form: Form)
 */
export const useFetchFilledForm = (recordGuid: string) => {
  const [error, setError] = useState<null | ErrorType>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filledForm, setFilledForm] = useState<undefined | FilledFormModel>(
    undefined
  );
  const [recordMetadata, setRecordMetadata] = useState<
    undefined | RecordMetadata
  >();
  const history = useHistory();

  console.log("going to start");

  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    RecordService.getRecordMetadata(recordGuid)
      .then((response) => {
        const metadata = response.data;
        setRecordMetadata(metadata);
        FormService.getForm(metadata.formGuid)
          .then((form) => {
            console.log("using form " + form.guid);
            RecordService.getFilledForm(recordGuid)
              .then((record) => {
                console.log("using record " + record.guid);
                const formModel: FilledFormModel = FilledFormModel.fromServerRecord(
                  record,
                  form
                );
                setFilledForm(formModel);
                setIsLoaded(true);
              })
              .catch((error) => {
                console.log(
                  `error in getting record from server ${recordGuid} ` +
                    JSON.stringify(error)
                );
                console.log(new Error().stack);
                setError({ message: "error in getting record" });
              });
          })
          .catch((error) => {
            console.log(
              `error in getting form ${metadata.formGuid} for the record ${recordGuid} ` +
                JSON.stringify(error)
            );
            console.log(new Error().stack);
            setError({ message: "some error" });
          });
      })
      .catch((error) => {
        console.log(
          `error in getting record metadata for ${recordGuid} ` +
            JSON.stringify(error)
        );
        console.log(new Error().stack);
        if (error.response.status) {
          switch (error.response.status) {
            case HttpErrorCodes.UNAUTHORIZED:
              onUnauthorizedHttpResponse();
              break;
          }
        }
        setError({
          message:
            "Error in getting record - record probably no longer exists.",
        });
      });

    //}
    //fetchData();
  }, [recordGuid]);
  return { error, isLoaded, recordMetadata, filledForm };
};

export const useFetchFormList = () => {
  const [err, setError] = useState<null | ErrorType>(null);
  const [formsArray, setFormArray] = useState<undefined | FormMetaData[]>();
  const intl = useIntl();
  const history = useHistory();

  console.log("going to start");

  useEffect(() => {
    // fetch the list of forms
    Axios.get(AppConfiguration.formServerUrl + "/account/forms", {
      withCredentials: true,
    })
      .then((response) => {
        console.log("response for list of forms: " + response.data);
        const listOfForms = response.data as FormMetaData[];
        setFormArray(listOfForms);
      })
      .catch((error) => {
        console.error(error);
        if (error && error.response) {
          switch (error.response.status) {
            case HttpErrorCodes.UNAUTHORIZED:
              onUnauthorizedHttpResponse();
              break;
            default:
              setError({
                message: intl.formatMessage({
                  id: "http.error.unknown",
                  defaultMessage:
                    "Some network error. Please check network and retry.",
                }),
              });
          }
        } else {
          setError({
            message: intl.formatMessage({
              id: "http.error.unknown",
              defaultMessage:
                "Some network error. Please check network and retry.",
            }),
          });
        }
      });
  }, []);
  return { formsArray, err };
};
