import React, { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import LoginService from "src/services/login_service";

// When form-server sends unauthorized response,
// it means the session-cookie has expired;
// and user needs to login again
export function onUnauthorizedHttpResponse() {
  firebase.auth().signOut();
  LoginService.signInAnonymously();
}
export function useLogin(languageCode: string) {
  let signedIn = firebase.auth().currentUser !== null;
  useEffect(() => {
    // TODO: make below work; it is not working
    // I don't see any languae specific login screen for google login
    // See https://firebase.google.com/docs/auth/web/google-signin#before_you_begin
    firebase.auth().languageCode = languageCode;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        signedIn = true;
        user?.getIdToken().then((idtoken) => {
          // calling below so that server can set the session cookie associated to idToken
          // TODO: what if session cookie times out ? it will result in unauthorized response
          LoginService.Login(idtoken);
        });
      } else if (LoginService.isLoggedIn()) {
        LoginService.Logout();
        signedIn = false;
      }
    });
  }, []);
  return signedIn;
}
