import { useState, useEffect } from "react";
import Axios from "axios";
import AppConfiguration from "src/configurations/constants";
import { RecordMetadata } from "src/services/datatypes/metadata";

export function useFetchRecordList() {
  const [recordsArray, setRecordArray] = useState<
    undefined | RecordMetadata[]
  >();
  useEffect(() => {
    // fetch the list of records
    Axios.get(AppConfiguration.formServerUrl + "/account/records/created", {
      withCredentials: true
    })
      .then((response) => {
        console.log("response for list of records: " + response.data);
        const listOfRecords = response.data as RecordMetadata[];
        setRecordArray(listOfRecords);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return recordsArray;
}
