import React from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import Navigation from "src/navigation/navigation";
import FormView from "src/components/formelements/form";
import { FormDisplayMode } from "src/components/formelements/enums";
import { messages } from "src/translations/intl_messages";

export function CreateForm() {
  const intl = useIntl();
  const formTitle = intl.formatMessage(messages.createForm);
  return (
    <div className="App">
      <Navigation title={formTitle}>
        <FormView displayMode={FormDisplayMode.EditMode}></FormView>
      </Navigation>
    </div>
  );
}
