import React from "react";
import { Form as FormMessages, Node } from "src/proto/FormServerMessages";
import FormView from "src/components/formelements/form";
import { FormModel } from "src/services/models/form_model";
import "./form.css";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "src/components/formelements/enums";
import { useFetchForm } from "src/hooks/form";

type ErrorType = {
  message: string;
};

function ViewForm(props: { formGuid: string }) {
  console.log("going to start");
  const { error, isLoaded, form } = useFetchForm(
    props.formGuid
    // AppConfiguration.formGuidTestOnProjBintech
  );
  //const {error, isLoaded, items, form } = useFetch("c6081c95-7ffd-49ae-aaad-bc052817e7fa");
  try {
    if (form !== undefined && form !== null) {
      console.log("rendering form " + form.rootNodeModel?.node.shortName);

      return (
        <div>
          <div className="form">
            <FormView
              filledForm={FilledFormModel.fromForm(
                form.getForm() as FormMessages
              )}
              displayMode={FormDisplayMode.ViewMode}
            ></FormView>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div>
          Error: {props.formGuid} - {error.message}
        </div>
      );
    } else if (!isLoaded || form === undefined || form === null) {
      return <div>Loading...</div>;
    } else {
      return <div>Unknown state...</div>;
    }
  } catch {
    return <div>The form {props.formGuid} has problem.</div>;
  }
}

export default ViewForm;
