import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link/Link";
import React, { useState } from "react";
import { Condition, Question } from "src/proto/FormServerMessages";
import { GroupedCondition } from "src/services/models/decision_model";
import { FormModel } from "src/services/models/form_model";
import { IFormModel } from "src/services/models/interface/form_model";
import { NodeModel } from "src/services/models/node_model";
import { FormDisplayMode } from "../enums";
import QuestionView from "../question";
import NewConditionModal, { ConditionModalProps } from "./new_condition_modal";

/**
 * Clickable question shows the short name of the question and clicking
 * it opens the dialog view which shows the complete question.
 *
 * This view is helpful to present the conditions in a concise way.
 *    answer to [question] equals/not equals/... [value]
 * where question is the text shown using this view.
 */
type ClickableConditionProps = {
  displayMode: FormDisplayMode;
  groupedCondition: GroupedCondition;
  formModel: IFormModel;
  onClose: () => void;
};
export function ClickableCondition(props: ClickableConditionProps) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography display="block" variant="body2">
          {props.groupedCondition.getName()}
        </Typography>
      </Link>

      <NewConditionModal
        displayMode={props.displayMode}
        formModel={props.formModel}
        groupedCondition={props.groupedCondition}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}
