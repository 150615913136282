import React from "react";
import { useIntl } from "react-intl";
import { GroupedCondition } from "src/services/models/decision_model";
import { IFormModel } from "src/services/models/interface/form_model";
import { ClickableQuestion } from "./clickable_question";
import Typography from "@material-ui/core/Typography/Typography";

/**
 * [Form Name]
 *
 * Condition name     Condition Text                          Condition Impact
 *
 * [condition name]     answer to [question short name]       [section/question] [short name] is made [hidden/optional]
 *                      [is/is not/is one of] [values],                           and
 *                                   and
 *
 *    car insurance     answer to "insurace wanted"           section "life insurance" is made "hidden"
 *                      is "car insurance"
 *
 *
 *  actions : on condtion name -> edit/delete/create new based on this
 *            on question short name -> click opens up the pop up showing the question with button to edit it
 */

// for reason of this kind of declaration of function
// see https://stackoverflow.com/questions/58311442/type-element-is-missing-the-following-properties-from-type-element-type

const ConditionImpactList: Function = (props: {
  groupCondition: GroupedCondition;
  formModel: IFormModel;
}): JSX.Element[] => {
  const intl = useIntl();
  const noImpactText = intl.formatMessage({
    id: "app.conditions.no_impact",
    defaultMessage: "No impact"
  });
  const affectedNodes = props.groupCondition.getAffectedNodes();
  return affectedNodes.length === 0
    ? [<Typography variant="body2">{noImpactText}</Typography>]
    : affectedNodes.map((nodeImpacted) => {
        return (
          <React.Fragment key={nodeImpacted.nodeId}>
            <ClickableQuestion
              formModel={props.formModel}
              nodeId={nodeImpacted.nodeId as number}
            />
            <Typography variant="body2">`${nodeImpacted.impact}`</Typography>
          </React.Fragment>
        );
      });
};

export default ConditionImpactList;
