import React, { useState, useReducer } from "react";
import MTextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/Assignment";
import InputAdornment from "@material-ui/core/InputAdornment";
import protobufTypes, {
  Form,
  Node,
  Question,
  AnswerOption,
} from "src/proto/FormServerMessages";
import { INodeModel } from "src/services/models/interface/node_model";
import Badge from "react-bootstrap/Badge";
import {
  Card,
  Divider,
  Tooltip,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  IconButton,
} from "@material-ui/core";
import "./question.css";
import { FilledFormModel } from "src/services/models/filled_form_model";
import AnswerGetterView from "src/components/formelements/answer";
import {
  DecisionType,
  FormDisplayMode,
} from "src/components/formelements/enums";
import QuestionHead from "./question_head";
import { useIntl } from "react-intl";
import {
  MenuItemType,
  GenericActionMenuCreator,
} from "../common/generic_action_menu";
import EditIcon from "@material-ui/icons/Edit";
import NewQuestionModal from "./new_question_modal";
import { NodeModel } from "src/services/models/node_model";
import { messages } from "src/translations/intl_messages";
import { FormModel } from "src/services/models/form_model";
import { IFormModel } from "src/services/models/interface/form_model";
import { XOR } from "src/tstypes/xor";
import { GroupedCondition } from "src/services/models/decision_model";

type QuestionProps =
  | {
      question: INodeModel;
      // we need FormModel to support providing list of conditions to choose from at time of editing
      formModel: IFormModel;
      displayMode: FormDisplayMode.EditMode;
      // the callback which the user component of this component can provide
      // to call when this component modifies the form
      onUpdate: () => void;
      // callback to container's method which checks if the supplied short name meets the requirements
      isShortNameOK: (name: string) => boolean;
    }
  | {
      question: INodeModel;
      filledForm: FilledFormModel;
      displayMode: Exclude<FormDisplayMode, FormDisplayMode.EditMode>;
      // the callback which the user component of this component can provide
      // to call when this component modifies the form
      onUpdate: () => void;
      // callback to container's method which checks if the supplied short name meets the requirements
      // isShortNameOK: (name: string) => boolean;
    };

export default function QuestionView(props: QuestionProps) {
  let questionNode = props.question as NodeModel;

  const intl = useIntl();

  const [openQuestionEdit, setOpenQuestionEdit] = React.useState(false);
  const [actionType, setActionType] = React.useState<
    "edit" | "" | "after" | "before" | "move"
  >("");

  const menuItems: MenuItemType[] = [
    {
      name: intl.formatMessage(messages.questionEditAction),
      action: (section: INodeModel) => {
        setActionType("edit");
        setOpenQuestionEdit(true);
      },
    },
    {
      name: intl.formatMessage(messages.delete),
      action: (section: INodeModel) => {
        questionNode.delete();
        props.onUpdate();
      },
    },
    {
      name: intl.formatMessage(messages.questionMoveAction),
      action: (section: INodeModel) => {
        setActionType("move");
      },
    },
    {
      name: intl.formatMessage(messages.questionAddBeforeAction),
      action: (section: INodeModel) => {
        setActionType("before");
        setOpenQuestionEdit(true);
      },
    },
    {
      name: intl.formatMessage(messages.questionAddAfterAction),
      action: (section: INodeModel) => {
        setActionType("after");
        setOpenQuestionEdit(true);
      },
    },
  ];
  const QuestionEditMenu = GenericActionMenuCreator(menuItems);
  const editQuestionView = (
    <IconButton aria-label="edit">
      <QuestionEditMenu data={questionNode}>
        <EditIcon />
      </QuestionEditMenu>
    </IconButton>
  );

  return (
    <div id={questionNode.getId().toString()} className="question">
      <QuestionHead
        sequenceNo={questionNode.getPositionWithDots()}
        optional={questionNode.isOptional()}
      />
      <Typography display="inline">
        {questionNode.getTextToDisplay()}
      </Typography>
      {props.displayMode !== FormDisplayMode.EditMode ? null : editQuestionView}
      <div className="boxView">
        {props.displayMode !== FormDisplayMode.EditMode ? (
          <AnswerGetterView
            question={questionNode}
            filledForm={props.filledForm}
            displayMode={props.displayMode}
            onNeedToRefreshForm={props.onUpdate}
          />
        ) : (
          <AnswerGetterView
            question={questionNode}
            filledForm={undefined}
            displayMode={props.displayMode}
            onNeedToRefreshForm={props.onUpdate}
          />
        )}
      </div>

      {props.displayMode !== FormDisplayMode.EditMode ? null : (
        <NewQuestionModal
          open={
            actionType === "edit" ||
            actionType === "before" ||
            actionType === "after"
          }
          formModel={props.formModel as IFormModel}
          isShortNameOK={props.isShortNameOK}
          questionNodeModel={
            props.question as NodeModel
            // TODO: find out why below doesn't work
            // actionType === "edit" ? (props.question as NodeModel) : undefined
          }
          condition={
            props.question
              ? props.question.getConditionImpactingThis()
              : undefined
          }
          onClose={(
            qLabel: string | undefined,
            qText: string | undefined,
            isOptional: boolean | undefined,
            question: Question | undefined,
            confirmed: boolean,
            condition: GroupedCondition | undefined
          ) => {
            setOpenQuestionEdit(false);
            switch (actionType) {
              case "edit":
                editQuestion(
                  confirmed,
                  qLabel,
                  qText,
                  question,
                  questionNode,
                  isOptional,
                  condition
                );
                break;
              case "after":
              case "before":
                createQuestion(
                  confirmed,
                  qLabel,
                  qText,
                  question,
                  questionNode,
                  isOptional,
                  condition,
                  actionType === "before"
                );
                break;
            }
            setActionType("");

            props.onUpdate();
          }}
        />
      )}
    </div>
  );
}

function editQuestion(
  confirmed: boolean,
  qLabel: string | undefined,
  qText: string | undefined,
  question: protobufTypes.Question | undefined,
  questionNode: NodeModel,
  isOptional: boolean | undefined,
  condition: GroupedCondition | undefined
) {
  if (confirmed && qText !== undefined && question !== undefined) {
    questionNode.node.optional = isOptional !== undefined && isOptional;
    questionNode.node.question = question;
    questionNode.node.shortName = qLabel ? qLabel : qText;
    questionNode.setTextToDisplay(qText);
  }
  if (condition) {
    condition.addAffectedNode(questionNode.getId(), DecisionType.HIDE);
  }
}

function createQuestion(
  confirmed: boolean,
  qLabel: string | undefined,
  qText: string | undefined,
  question: Question | undefined,
  questionNode: INodeModel,
  isOptional: boolean | undefined,
  condition: GroupedCondition | undefined,
  before: boolean
) {
  // if user confirmed and gave all data to edit/create questions
  if (confirmed && qText !== undefined && question !== undefined) {
    // get the node that contains this question
    const containerNode = (questionNode as NodeModel)
      .containerNode as INodeModel;
    // create new question
    let questionNew = FormModel.newQuestion(
      containerNode,
      qLabel ? qLabel : qText,
      isOptional !== undefined && isOptional,
      question,
      qText
    );
    if (condition) {
      condition.addAffectedNode(questionNew.getId(), DecisionType.HIDE);
    }
    // if action is to add question before this question
    if (before) {
      containerNode.moveNodeAsChildBefore(
        questionNode as NodeModel,
        questionNew
      );
    } else {
      containerNode.moveNodeAsChildAfter(
        questionNode as NodeModel,
        questionNew
      );
    }
  }
}
