import protobufType, {
  DecisionType,
  GroupCondition,
  NodeCondition as NodeConditionProto,
  // ConditionType,
  AnswerValue,
  FilledForm,
  Form,
  AnswerGiven,
  INodeCondition,
  ConditionType,
  INodeDecision,
  IAnswerGiven,
  IAnswerValue,
} from "src/proto/FormServerMessages";
import { isEqual } from "lodash";
import { FormModel } from "./form_model";
import { v4 as uuid } from "uuid";
import { IFormModel } from "./interface/form_model";

// DecisionModel handles the decision made, i.e. the answer given to questions
// It marks a node optional, hidden or required on the basis of the answer.
export enum NodeDecisionType {
  NONE,
  OPTIONAL,
  REQUIRED,
  HIDDEN,
}
export class DecisionModel {
  // we can make DecisionModel member of FormModel
  constructor(private formModel: FormModel) {}
  // DecsionModel will be
}

export class NodeCondition {
  private nodeCondition_: protobufType.NodeCondition;
  // involved nodeId
  /*
  private nodeId: number;
  private matchOperator: protobufType.ConditionType;
  private referenceValue: AnswerValue;
  */
  constructor(
    nodeId: number,
    value: AnswerValue,
    condition: protobufType.ConditionType
  ) {
    this.nodeCondition_ = new protobufType.NodeCondition();
    this.nodeCondition_.nodeId = nodeId;
    this.nodeCondition_.matchOperator = condition;
    this.nodeCondition_.refValue = value;
    /*
    this.nodeId = nodeId;
    this.matchOperator = condition;
    this.referenceValue = value;
    */
  }
  public static from(nodeCondition: protobufType.NodeCondition): NodeCondition {
    if (nodeCondition.refValue === undefined) {
      console.error(
        `node condition ${nodeCondition.matchOperator} on node ${nodeCondition.nodeId} has null ref val`
      );
    }
    return new NodeCondition(
      nodeCondition.nodeId,
      nodeCondition.refValue as AnswerValue,
      nodeCondition.matchOperator
    );
  }
  public toProtobuf(): protobufType.NodeCondition {
    return this.nodeCondition_;
  }
  /** Does the answer value meets the condition
   * @param values: array of answer values (array to support multiple select answers)
   */

  public isTrue(values: AnswerValue[]) {
    /*
    switch (this.matchOperator) {
      case protobufType.ConditionType.EQ:
        return isEqual(value, this.referenceValue);
      case protobufType.ConditionType.NEQ:
        return !isEqual(value, this.referenceValue);
    }
    */
    if (values === undefined || values.length < 1) return false;

    // TODO: add support for "one of" match operator
    switch (this.nodeCondition_.matchOperator) {
      case protobufType.ConditionType.EQ:
        return (
          values.length === 1 &&
          isEqual(values[0], this.nodeCondition_.refValue)
        );
      case protobufType.ConditionType.NEQ:
        return (
          values.findIndex((value) =>
            isEqual(value, this.nodeCondition_.refValue)
          ) < 0
        );
      //return values.length===1 && !isEqual(values[0], this.nodeCondition_.refValue);
    }
    return false;
  }
  public getNodeId(): number {
    return this.nodeCondition_.nodeId;
    //return this.nodeId;
  }
  public setConditionType(condition: protobufType.ConditionType) {
    this.nodeCondition_.matchOperator = condition;
    //this.matchOperator = condition;
  }
  public getConditionType(): protobufType.ConditionType {
    return this.nodeCondition_.matchOperator;
    //return this.matchOperator;
  }
  public getRefValue(): protobufType.AnswerValue {
    return this.nodeCondition_.refValue as AnswerValue;
  }
}

export type AffectedNode = protobufType.INodeDecision;

/**
 * group of nodes (sections/question nodes)
 * at the moment, these groups are disjoint groups
 * because we are defining them to be collection of nodes which are dependent on single condition on certain answer of a node
 * E.g. 1) all nodes which become hidden for answer X1, X2 to question nodes Y1,Y2 will belong to the same group Z.
 * So, number of groups is same as number of conditions.
 * E.g. 2) this is alteration. All nodes which are dependent on single condition on certain answer of a node for being hidden or optional.
 */
export class GroupedCondition {
  // unique number attached to the group (not used in protoBuf)
  // this is used to refer to the group in UI in compact way e.g. to delete the group
  private groupId_: string;
  // private groupCondition_: protobufType.GroupCondition;

  // associated condition
  // at the moment answer value
  // array of conditions, all should be true
  // private _nodeConditions: NodeCondition[];

  public get nodeConditions(): NodeCondition[] {
    //return this.groupCondition_.nodeConditions;
    return Array.from(this.nodeToConditions_).map(([key, val]) => val);
    //return this._nodeConditions;
  }
  public set nodeConditions(value: NodeCondition[]) {
    this.nodeToConditions_ = new Map();
    value.forEach((val) => this.nodeToConditions_.set(val.getNodeId(), val));
    //this._nodeConditions = value;
  }

  // When filling forms :
  // as and when answer is changed, we want to evaluate nodeConditions to determine
  // it becoming true or false. One way is : map node id -> NodeCondition
  //    so that there is fast search of node condition
  //    and maintain the set of not yet true NodeConditions;
  //    when that set becomes empty, all conditions are true.
  private nodeToConditions_: Map<number, NodeCondition>;

  private nodesWithNotYetTrueConditions_: number[];

  // id of the group: is it needed ? why ?
  // not needed (we needed for nodes, as we move nodes without affecting their content).
  // private id: number;

  // user assgined name for him to refer to the group
  private name: string;

  // affected nodes
  private affectedNodes: AffectedNode[];

  // form model, needed to support impacting of nodes when user is filling the form
  private formModel_: IFormModel;

  constructor(
    formModel: IFormModel,
    name: string,
    affectedNodes: AffectedNode[],
    nodeConditions: NodeCondition[]
  ) {
    this.groupId_ = uuid();
    this.name = name;
    this.formModel_ = formModel;
    this.nodeToConditions_ = new Map();
    //this._nodeConditions = [];
    this.affectedNodes = affectedNodes;
    this.nodeConditions = nodeConditions;
    // in the beginning of form filling, all conditions are false.
    this.nodesWithNotYetTrueConditions_ = nodeConditions.map((val) =>
      val.getNodeId()
    );
  }
  /**
   *
   * @param groupCondition
   */
  public getId(): string {
    return this.groupId_;
  }
  /**
   *
   * @param groupCondition: protobufType.GroupCondition
   */
  public static from(
    formModel: FormModel,
    groupCondition: protobufType.IGroupCondition
  ): GroupedCondition {
    // REVIEW: construct from protobuf's GroupCondition
    const groupConditionObj = new GroupedCondition(
      formModel,
      groupCondition.name as string,
      groupCondition.nodeDecisions as INodeDecision[],
      (groupCondition.nodeConditions as INodeCondition[]).map(
        (nodeCond: INodeCondition) =>
          new NodeCondition(
            nodeCond.nodeId as number,
            nodeCond.refValue as AnswerValue,
            nodeCond.matchOperator as ConditionType
          )
      )
    );
    //groupConditionObj.nodeConditions = groupCondition.nodeConditions;
    //groupCondition.nodeDecisions.map( (val : protobufType.NodeDecision)  => new AffectedNode(val.nodeId))
    return groupConditionObj;
  }

  /**
   *
   * @param groupCondition: protobufType.GroupCondition
   */
  public toProtobuf(): protobufType.GroupCondition {
    // REVIEW: convert into protobuf's GroupCondition
    let groupCondition = new protobufType.GroupCondition();
    groupCondition.name = this.name;
    groupCondition.nodeConditions = this.nodeConditions.map((val) =>
      val.toProtobuf()
    );
    groupCondition.nodeDecisions = this.affectedNodes;
    return groupCondition;
  }
  // ---------------------------
  //  Name of grouped conditions
  // ---------------------------

  // name of the group
  getName(): string {
    return this.name;
  }
  // set the name of the group
  setName(name: string): void {
    this.name = name;
  }

  // ----------------------------
  //   Conditions
  // ----------------------------

  // add condition
  addCondition(nodeCondition: NodeCondition): void {
    // REVIEW: any need to verify that this is question node ?
    this.nodeToConditions_.set(nodeCondition.getNodeId(), nodeCondition);
    /*
    const nodeIndex = this.nodeConditions.findIndex(
      (value) => value.getNodeId() === nodeCondition.getNodeId()
    );
    if (nodeIndex >= 0) {
      // ensure no more than one match operator for a question
      this.nodeConditions[nodeIndex] = nodeCondition;
    } else {
      this.nodeConditions.push(nodeCondition);
    }
    */
  }
  // remove the condition
  removeCondition(nodeId: number): void {
    this.nodeToConditions_.delete(nodeId);
    /*
    const nodeIndex = this.nodeConditions.findIndex(
      (value) => value.getNodeId() === nodeId
    );
    if (nodeIndex >= 0) this.nodeConditions.splice(nodeIndex, 1);
    */
  }

  // -------------------------
  //  Affected nodes(questions/sections)
  // -------------------------

  // add node to the group, and specify how it will be affected when
  // group becomes active (i.e. all condidtions of group become true)
  addAffectedNode(nodeId: number, impact: protobufType.DecisionType) {
    const nodeIndex = this.affectedNodes.findIndex(
      (value) => value.nodeId === nodeId
    );
    if (nodeIndex >= 0) {
      this.affectedNodes[nodeIndex].impact = impact;
    } else {
      let affectedNode: AffectedNode = new protobufType.NodeDecision();
      affectedNode.nodeId = nodeId;
      // REVIEW: DecisionType and protobuf decision type
      affectedNode.impact = impact;
      this.affectedNodes.push(affectedNode);
    }
  }
  // remvoe a node from the group
  removeAffectedNode(nodeId: number): void {
    const nodeIndex = this.affectedNodes.findIndex(
      (value) => value.nodeId === nodeId
    );
    if (nodeIndex >= 0) this.affectedNodes.splice(nodeIndex, 1);
  }
  // get the list of affected nodes
  // NOTE UI needs access to this list
  getAffectedNodes(): AffectedNode[] {
    return this.affectedNodes;
  }
  // check if all conditions are true, with answers given so far.
  // 1) this is needed in UI when user is filling the form
  // 2) also needed when showing the filled form
  // 3) also needed when checking all required questiosn have been answered.
  areConditionsTrue(): boolean {
    // evaluate the conditions
    return this.nodesWithNotYetTrueConditions_.length === 0;
  }
  /**
   * Add answer
   * @param values: array of answer values (array to support multiple select answers)
   */
  public addAnswer(nodeId: number, values: AnswerValue[]): boolean {
    let isThereChange: boolean = false;
    const nodeCondition = this.nodeToConditions_.get(nodeId);
    if (nodeCondition !== undefined) {
      const index = this.nodesWithNotYetTrueConditions_.findIndex(
        (val) => val === nodeId
      );
      const allTrueBeforeAnswer = this.areConditionsTrue();
      if (nodeCondition.isTrue(values)) {
        // condition has become true
        this.nodesWithNotYetTrueConditions_.splice(index, 1);
      } else if (index < 0) {
        // condition has become false
        this.nodesWithNotYetTrueConditions_.push(nodeId);
      }
      const allTrueAfterAnswer = this.areConditionsTrue();
      // if there change in truth value for the combination of conditions
      if (allTrueBeforeAnswer !== allTrueAfterAnswer) {
        isThereChange = true;
        if (allTrueAfterAnswer) {
          // REVIEW: impact the nodes
          this.affectedNodes.forEach((nodeDecision) =>
            this.formModel_.impactNode(
              nodeDecision.nodeId as number,
              nodeDecision.impact === protobufType.DecisionType.HIDE
                ? NodeDecisionType.HIDDEN
                : NodeDecisionType.OPTIONAL
            )
          );
        } else {
          // REVIEW: reset the impact to the nodes
          this.affectedNodes.forEach((nodeDecision) =>
            this.formModel_.resetImpactNode(nodeDecision.nodeId as number)
          );
        }
      }
    }
    return isThereChange;
  }
  /**
   * Remove/reset answer
   */
  public resetAnswer(nodeId: number): void {
    const nodeCondition = this.nodeToConditions_.get(nodeId);
    // TODO: make change as above addAnswer
    // check for if (allTrueBeforeAnswer !== allTrueAfterAnswer)
    if (nodeCondition !== undefined) {
      const index = this.nodesWithNotYetTrueConditions_.findIndex(
        (val) => val === nodeId
      );
      // if not existing in nodes with not yet true conditions
      if (index === -1) {
        // condition has become false
        this.nodesWithNotYetTrueConditions_.push(nodeId);
      }
    }
  }
  /**
   * Act on node deleted. This is needed when user is editing the form.
   * remove all node conditions object and node decision object which were using the node.
   */
  public actOnNodeDeletion(nodeId: number): void {
    this.nodeToConditions_.delete(nodeId);
    const affectedIndex = this.affectedNodes.findIndex(
      (affectedNode) => affectedNode.nodeId === nodeId
    );
    if (affectedIndex >= 0) {
      this.affectedNodes.splice(affectedIndex, 1);
    }
  }
}
/**
 * This class is counterpart of form.proto's repeated GroupConditions.
 *
 */
export class ConditionsInForm {
  private formModel_: FormModel;
  // the set of group conditions
  private group_conditions: GroupedCondition[] = [];

  public constructor(formModel: FormModel) {
    this.formModel_ = formModel;
  }
  /**
   * Convert's protobuf's group conditions array to obeject of this class type
   * @param groupConditions
   */
  public static from(formModel: FormModel): ConditionsInForm {
    // REVIEW: implement parsing of group conditions, and initializing this object
    let conditionsInForm = new ConditionsInForm(formModel);
    conditionsInForm.group_conditions = (formModel.getForm() as Form).groupConditions.map(
      (val) => GroupedCondition.from(formModel, val)
    );
    return conditionsInForm;
  }

  public setAnswers(filledRecord: FilledForm): void {
    // const conditionInForm = ConditionsInForm.from(form.groupConditions);
    // TODO: add support for multiple select answers
    filledRecord.answers.forEach((answerGiven: IAnswerGiven) =>
      this.addAnswer(
        answerGiven.questionNodeId as number,
        answerGiven.answerValues as AnswerValue[]
      )
    );
  }
  /**
   * Converts this object into protobuf's group conditions array so that
   * protobuf Form object can be constructed with the conditions.
   */
  public toProtobuf(): protobufType.GroupCondition[] {
    // REVIEW: implement converting this object to protobuf's group conditions array
    return this.group_conditions.map((val) => val.toProtobuf());
  }
  /**
   *
   *
   */
  public getConditions() {
    return this.group_conditions;
  }

  /**
   * Add a group condition
   */
  public addGroupCondition(groupCondition: GroupedCondition): void {
    // REVIEW: do we need to check if groupCondition is already added
    this.group_conditions.push(groupCondition);
    // update form
    (this.formModel_.getForm() as Form).groupConditions = this.toProtobuf();
  }
  /**
   * Remove a group condition
   */
  public removeGroupCondition(groupId: string): void {
    this.group_conditions.splice(
      this.group_conditions.findIndex((val) => val.getId() === groupId),
      1
    );
    // update form
    (this.formModel_.getForm() as Form).groupConditions = this.toProtobuf();
  }
  /**
   * Add answer
   *
   * @param values: array of answer values (array to support multiple select answers)
   */
  public addAnswer(nodeId: number, values: AnswerValue[]): boolean {
    let isAnyNodeImpacted: boolean = false;
    this.group_conditions.forEach((groupCondition) => {
      groupCondition.addAnswer(nodeId, values);
      isAnyNodeImpacted = isAnyNodeImpacted
        ? isAnyNodeImpacted
        : groupCondition.getAffectedNodes().length > 0;
    });
    return isAnyNodeImpacted;
  }
  /**
   * Reset answer
   */
  public resetAnswer(nodeId: number): boolean {
    let isAnyNodeImpacted: boolean = false;
    this.group_conditions.forEach((groupCondition) => {
      groupCondition.resetAnswer(nodeId);
      isAnyNodeImpacted = isAnyNodeImpacted
        ? isAnyNodeImpacted
        : groupCondition.getAffectedNodes().length > 0;
    });
    return isAnyNodeImpacted;
  }
  /**
   * Act on node deleted.
   * Expectation is that if a section is deleted, this method will be called for each of its child node
   *  and addition to for that section node.
   * remove all node conditions object and node decision object which were using the node.
   */
  public actOnNodeDeletion(nodeId: number): void {
    this.group_conditions.forEach((groupCondition) =>
      groupCondition.actOnNodeDeletion(nodeId)
    );
    // update form
    (this.formModel_.getForm() as Form).groupConditions = this.toProtobuf();
  }
}
