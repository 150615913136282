import React, { useEffect } from "react";
//import Button from "react-bootstrap/Button";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Menu,
  MenuItem,
  Button,
  Icon,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";

//import Toolbar from '@material-ui/core/Toolbar';
//import IconButton  from '@material-ui/core/IconButton';
//import MenuIcon  from '@material-ui/core/MenuIcon';
//import Typography from '@material-ui/core/Typography';
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";

// drawer
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
// A tiny (229B) utility for constructing className strings conditionally.
import clsx from "clsx";

import DrawerContent from "./drawer_content";
import "../App.css";
import useBrowserTitle from "./use_browser_title";
import ProfileSubMenu from "./profile_submenu";
import MobileScreenMenuForm from "./mobile_screen_menu";
import { Link } from "react-router-dom";
import ShareButton, { ShareButtonPropsType } from "src/components/share_button";
import LoginService from "src/services/login_service";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LanguageSwitcher from "src/components/language/language-switcher";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //flexGrow: 1,
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    title: {
      flexGrow: 1,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    list: {
      width: drawerWidth,
    },
    fullList: {
      width: "auto",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    avatarSize: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);

type NavigationInputProps = {
  // title set on app menu bar
  title: string | JSX.Element;
  // the content
  children: any;
  // menu items with routes
  menuItemsMobile?: any;
  menuItemsDesktop?: any;
  valuesToShare?: ShareButtonPropsType;
};
/**
 * Navigation will get all the data that should be in the navigation bar
 * One data : title : kind of view : (See) Form, Edit Form, Fill Form, (See) Filled Form, (See)Record, Account, Billing, ...
 *
 * Buttons : to go to relatee views : context based : if current view is (See) Form, then button will be Edit Form, and so on
 *
 * App Bar : showing all possible navigation options
 */

function Navigation(props: NavigationInputProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [drawerOpenState, setDrawerOpenState] = React.useState(false);

  // number of anchor elements is going to be same as number of simultaneous pop-over possibles
  const [anchorElementProfile, setAnchorElementProfile] = React.useState<
    (EventTarget & HTMLLIElement & HTMLButtonElement) | null
  >(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<
    (EventTarget & HTMLLIElement & HTMLButtonElement) | null
  >(null);
  const [mobileMoreAnchorEl1, setMobileMoreAnchorEl1] = React.useState<
    (EventTarget & HTMLLIElement & HTMLButtonElement) | null
  >(null);

  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const mobileMenuId1 = "primary-search-account-menu-mobile1";

  useBrowserTitle("Bintech: " + props.title);

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLLIElement & HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElementProfile(event.currentTarget);
  };

  const handleMobileMenuOpen = (
    event: React.MouseEvent<HTMLLIElement & HTMLButtonElement, MouseEvent>
  ) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuOpen1 = (
    event: React.MouseEvent<HTMLLIElement & HTMLButtonElement, MouseEvent>
  ) => {
    setMobileMoreAnchorEl1(event.currentTarget);
  };
  // multiple open menus can be open if one
  // is stacked on another.
  // usually, all these menus are supposed to be closed together
  // if click happens outside of active child menu.
  // below closeAllMenus is to support such feature.
  const closeAllMenus = () => {
    setAnchorElementProfile(null);
    setMobileMoreAnchorEl(null);
  };

  // below two are to support the case where click outside of active child menu
  // should close only the active child menu
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuClose1 = () => {
    setMobileMoreAnchorEl1(null);
  };
  const handleProfileMenuClose = () => {
    setAnchorElementProfile(null);
    //handleMobileMenuClose();
  };

  const setDrawerState = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpenState(open);
  };

  const drawerList = (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: false,
      })}
      role="presentation"
      onClick={setDrawerState(false)}
      onKeyDown={setDrawerState(false)}
    >
      <DrawerContent />
    </div>
  );

  const renderMobileMenu = (
    <MobileScreenMenuForm
      AnchorEl={mobileMoreAnchorEl}
      menuId={mobileMenuId}
      handleMenuClose={handleMobileMenuClose}
      handleProfileMenuOpen={handleProfileMenuOpen}
    />
  );

  // add login popup/modal below

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={setDrawerState(true)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <Link to="/login">
            {LoginService.isLoggedIn() ? (
              <Avatar
                className={classes.avatarSize}
                alt={LoginService.getUserName() as string | undefined}
                src={LoginService.getUserImage() as string | undefined}
              />
            ) : (
              <IconButton>
                <Icon style={{ color: "white" }}>account_box</Icon>
              </IconButton>
            )}
          </Link>
          {props.valuesToShare ? (
            <ShareButton {...props.valuesToShare} />
          ) : null}
          <LanguageSwitcher />

          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId1}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen1}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <MobileScreenMenuForm
        AnchorEl={mobileMoreAnchorEl}
        menuId={mobileMenuId}
        handleMenuClose={handleMobileMenuClose}
        handleProfileMenuOpen={handleProfileMenuOpen}
      />
      <MobileScreenMenuForm
        AnchorEl={mobileMoreAnchorEl1}
        menuId={mobileMenuId1}
        handleMenuClose={handleMobileMenuClose1}
        handleProfileMenuOpen={handleProfileMenuOpen}
      />
      <ProfileSubMenu
        handleMenuClose={handleProfileMenuClose}
        menuId={menuId}
        anchorEl={anchorElementProfile}
      />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            open={drawerOpenState}
            onClose={setDrawerState(false)}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerList}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawerList}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className="AppBody">{props.children}</div>
      </main>
    </div>
  );
}
export default Navigation;
