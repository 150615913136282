import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import { FormLabel } from "@material-ui/core";

export type LabelExistingTextPair = {
  labelText?: string;
  existingText?: string;
};
interface TextEditPropsType {
  open: boolean;
  hintText: string;
  labelExistingText: LabelExistingTextPair[];
  onClose: (
    text: (string | undefined)[] | undefined,
    confirmed: boolean
  ) => void;
}
/**
 * Use of TextEditDialog component
 *      <TextEditDialog hintText={"Section Title"} existingText={"Personal Information"} onChangeText={}>  .... </FormTextEdit>
 * The wrapped children will be clickable; it will popup dialog to change the text.
 *      ------------------------------------------
 *      hintText :  label [   text area      ]
 *                  label [   text area      ]
 *                  label [   text area      ]
 *                 [Cancel]        [OK]
 *      ------------------------------------------
 * @param props
 */
export default function TextEditDialog(props: TextEditPropsType) {
  const { onClose } = props;
  const [text, setText] = useState(
    props.labelExistingText?.map((e) => e.existingText)
  );

  return (
    <Dialog
      fullWidth
      onClose={(e) => onClose(text, false)}
      aria-labelledby={props.hintText}
      open={props.open}
    >
      <DialogTitle id="simple-dialog-title">{props.hintText}</DialogTitle>
      <DialogContent>
        {props.labelExistingText.map((e, index) => (
          <React.Fragment key={index}>
            <FormLabel>{e.labelText}</FormLabel>
            <Input
              autoFocus={index === 0 /** put focus on the first input box */}
              fullWidth
              value={text ? text[index] : null}
              inputProps={{ "aria-label": props.hintText }}
              onChange={(e) => {
                let newText = [...text];
                newText[index] = e.target.value;
                setText(newText);
              }}
            />
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            onClose(undefined, false);
            setText(props.labelExistingText?.map((e) => e.existingText));
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={(e) => {
            onClose(text, true);
            setText(text);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
