import React from "react";
import { useIntl } from "react-intl";
import { HeadCellType } from "src/components/common/enhanced_table_head";
import GenericTable from "src/components/common/generic_table";
import { FormModel } from "src/services/models/form_model";
import { NodeModel } from "src/services/models/node_model";
import { messages } from "src/translations/intl_messages";
import {
  GroupedCondition,
  NodeCondition
} from "src/services/models/decision_model";
import { IFormModel } from "src/services/models/interface/form_model";
import { FormDisplayMode } from "../enums";
import { ClickableQuestion } from "./clickable_question";
import Typography from "@material-ui/core/Typography/Typography";
import { ClickableCondition } from "./clickable_condition";
import Grid from "@material-ui/core/Grid";
import { ConditionTypeToLabelMap } from "src/components/enum_to_labels";
import protobufTypes from "src/proto/FormServerMessages";
import ConditionImpactList from "./condition_impact_list";
import ConditionClause from "./condition_clause";

/**
 * [Form Name]
 *
 * Condition name     Condition Text                          Condition Impact
 *
 * [condition name]     answer to [question short name]       [section/question] [short name] is made [hidden/optional]
 *                      [is/is not/is one of] [values],                           and
 *                                   and
 *
 *    car insurance     answer to "insurace wanted"           section "life insurance" is made "hidden"
 *                      is "car insurance"
 *
 *
 *  actions : on condtion name -> edit/delete/create new based on this
 *            on question short name -> click opens up the pop up showing the question with button to edit it
 */

export type DisplayedConditionData = {
  id: string;
  name: React.ReactElement;
  clause: React.ReactElement;
  result: React.ReactElement;
};

type ConditionListProps = {
  formModel: FormModel;
  displayMode: FormDisplayMode;
};

export const ConditionTable: React.FC<ConditionListProps> = function ({
  formModel,
  displayMode
}: ConditionListProps) {
  const intl = useIntl();
  const headCells: HeadCellType<DisplayedConditionData>[] = [
    {
      id: "name",
      rightAlign: false,
      disablePadding: true,
      label: intl.formatMessage(messages.conditionName)
      //sortable: true,
    },
    {
      id: "clause",
      rightAlign: true,
      disablePadding: false,
      label: intl.formatMessage(messages.conditionClause)
    },
    {
      id: "result",
      rightAlign: false,
      disablePadding: false,
      label: intl.formatMessage(messages.conditionResult)
    }
  ];
  const rows: DisplayedConditionData[] = formModel
    .getConditionsHandler()
    .getConditions()
    .map((conditionGrouped) => {
      return {
        id: conditionGrouped.getId(),
        name: (
          <ClickableCondition
            displayMode={displayMode}
            formModel={formModel}
            groupedCondition={conditionGrouped}
            onClose={() => {
              return;
            }}
          />
        ), //conditionGrouped.getName(),
        clause: (
          <ConditionClause
            groupCondition={conditionGrouped}
            formModel={formModel}
          />
        ),
        result: (
          <div>
            <ConditionImpactList
              groupCondition={conditionGrouped}
              formModel={formModel}
            />
          </div>
        )
      };
    });
  return (
    <div>
      {/*ToDo: add action to take on selected forms Do refactoring of GenericTable
          as given at 10:36 of https://www.youtube.com/watch?v=nQo0EdHNjto That will
          make much easy to use headCells.*/}
      {rows === undefined ? null : (
        <GenericTable<DisplayedConditionData>
          defaultSortKeyName={"name"}
          guid={"id"}
          rows={rows}
          headCells={headCells}
          addSelectionCheckbox={false}
        />
      )}
    </div>
  );
};
