import React from "react";

type BillingHistoryPropsType = {};
export function BillingHistory(props: BillingHistoryPropsType) {
  return (
    <div>
      Display of summary of billing for previous months, each one can be clicked
      to see the detail.
    </div>
  );
}
