import React, { useReducer, useState } from "react";
import { useIntl } from "react-intl";
import protobufTypes, {
  Question,
  AnswerOption,
  AnswerValue,
  QuestionType
} from "src/proto/FormServerMessages";
import clonedeep from "lodash/cloneDeep";
import truncate from "lodash/truncate";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { QuestionModel } from "src/services/models/question_model";
import { NodeModel } from "src/services/models/node_model";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import {
  Container,
  Grid,
  Checkbox,
  Select,
  InputLabel,
  TextField,
  FormControl,
  IconButton,
  Typography,
  MenuItem,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import {
  GroupedCondition,
  NodeCondition
} from "src/services/models/decision_model";
import { FormModel } from "src/services/models/form_model";
import { FilledFormModel } from "src/services/models/filled_form_model";
import RefValueGetterView from "./ref_value_getter";
import NodeConditionView, { NodeConditionToView } from "./node_condition";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IFormModel } from "src/services/models/interface/form_model";
import { INodeModel } from "src/services/models/interface/node_model";
import { Form } from "formik";
import { FormDisplayMode } from "../enums";
import { getTranslations } from "./getTranslations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  })
);

export type ConditionModalProps = {
  displayMode: FormDisplayMode;
  groupedCondition?: GroupedCondition; // in case of edit
  formModel: IFormModel;
  hintText?: any;
  open: boolean;
  onClose: () => void;
};

export default function NewConditionModal(props: ConditionModalProps) {
  //  --- Condition ----
  //   If answer to questions is as below
  //     question [.....], answer is [equal/not equal/one of] [......]  + -
  //   and,  question [.....], answer is [equal/not equal/one of] [......]  + -
  //
  //    If above conditions are true, then make
  //      [section/questio] [....] [hidden/optional]  +/-
  //      [section/questio] [....] [hidden/optional]  +/-
  //
  //      [Cancel]   [Add]
  const intl = useIntl();
  const {
    shortNameLabel,
    conditionLabel,
    askToShowImpactedNodes,
    resultClause
  } = getTranslations(intl);
  /* we clone nodeCondition so that any changes made inside the below view is
     not reflected in the props.groupedCondition until client confirms by pressing OK button */
  const [groupedCondition, setGroupedCondition] = useState(
    props.groupedCondition ? clonedeep(props.groupedCondition) : undefined
  );
  const [name, setName] = useState(
    props.groupedCondition ? props.groupedCondition.getName() : undefined
  );
  // each element of nodeConditions correspond to one NodeConditionView shown
  const [nodeConditions, setNodeConditions] = useState<
    (NodeConditionToView | undefined)[]
  >(
    props.groupedCondition
      ? props.groupedCondition.nodeConditions.map((nodeCond) => {
          return {
            nodeId: nodeCond.getNodeId(),
            matchOperator: nodeCond.getConditionType(),
            refValue: nodeCond.getRefValue()
          };
        })
      : [undefined]
  );

  const [showImpactedNodes, setShowImpactedNodes] = useState(false);
  const [submittable, setSubmittable] = useState(
    props.groupedCondition !== undefined
  );

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const { onClose } = props;

  const onNodeConditionChange = function (
    index: number,
    nodeCond: NodeConditionToView
  ) {
    nodeConditions[index] = nodeCond;
    setIfSettable();
    forceUpdate();
  };

  const setIfSettable = function () {
    setSubmittable(
      !(
        name === undefined ||
        name.length <= 0 ||
        // groupedCondition === undefined ||
        nodeConditions.length <= 0 ||
        nodeConditions.findIndex(
          (nodeCond) =>
            nodeCond === undefined ||
            nodeCond.nodeId === undefined ||
            nodeCond.matchOperator === undefined ||
            nodeCond.refValue === undefined
        ) >= 0
      )
    );
  };
  const onSave = function () {
    if (props.displayMode !== FormDisplayMode.EditMode) {
      return;
    }
    if (!submittable) {
      // there is some error that this is getting invoked.
      console.error("onSave invoked, even though group conditions are invalid");
      return;
    }
    const nodeConditionsToSave = nodeConditions.map(
      (nodeCond) =>
        new NodeCondition(
          nodeCond?.nodeId as number,
          nodeCond?.refValue as AnswerValue,
          nodeCond?.matchOperator as protobufTypes.ConditionType
        )
    );
    // create the new group condition object if not existing
    if (props.groupedCondition) {
      // replace props.groupedCondition with the new groupedCondition
      props.groupedCondition.setName(name as string);
      // below replaces the old map by new map (performance: memory consumption implication)
      props.groupedCondition.nodeConditions = nodeConditionsToSave;
    } else {
      let groupedCondition = new GroupedCondition(
        props.formModel,
        name as string,
        [],
        nodeConditionsToSave
      );
      // attach to props.formModel
      props.formModel
        .getConditionsHandler()
        .addGroupCondition(groupedCondition);
    }
  };

  /**
   * Note: using onBlur() below as otherwise Input takes too long to react to
   * fast keyboard typing.
   * See https://stackoverflow.com/questions/32911519/react-slow-with-multiple-controlled-text-inputs/36769581
   */

  return (
    <Dialog
      fullWidth
      onClose={(e) =>
        // REVIEW: anything more needed ?
        props.onClose()
      }
      aria-labelledby={props.hintText}
      open={props.open}
    >
      <DialogTitle id="condition-dialog">{props.hintText}</DialogTitle>
      <DialogContent>
        {/* https://stackoverflow.com/questions/47012169/a-component-is-changing-an-uncontrolled-input-of-type-text-to-be-controlled-erro */}
        <TextField
          disabled={props.displayMode !== FormDisplayMode.EditMode}
          label={shortNameLabel}
          autoFocus
          fullWidth
          multiline
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setIfSettable();
          }}
        />
        <br />
        <Typography>{conditionLabel}</Typography>
        {nodeConditions.map((nodeCondition, index) => (
          <NodeConditionView
            key={index}
            displayMode={props.displayMode}
            formModel={props.formModel}
            nodeCondition={nodeCondition}
            onAddNewCondition={() => {
              let newNodeConditions = [...nodeConditions];
              newNodeConditions.splice(index + 1, 0, undefined);
              setNodeConditions(newNodeConditions);
            }}
            onRemoveCondition={() => {
              if (nodeConditions.length > 1) {
                // REVIEW: do we need to do below clone ? is it bad for performance ?
                let newNodeConditions = [...nodeConditions];
                newNodeConditions.splice(index, 1);
                setNodeConditions(newNodeConditions);
              }
            }}
            onChange={(nodeId, matchOperator, refValue) => {
              // check what kind of change it is, valid or invalid
              // if valid change, update the nodeConditions
              // if invalid change, make submittable false
              onNodeConditionChange(index, {
                nodeId: nodeId,
                matchOperator: matchOperator,
                refValue: refValue
              });
            }}
          />
        ))}
        {props.groupedCondition === undefined ? null : (
          <>
            <FormControlLabel
              value={false}
              control={
                <Checkbox
                  color="primary"
                  checked={showImpactedNodes}
                  onChange={(_, checked) => setShowImpactedNodes(checked)}
                  name="shouldShowImpactedNodes"
                />
              }
              label={askToShowImpactedNodes}
              labelPlacement="end"
            />
            {showImpactedNodes === false ? null : (
              <>
                <br />
                <Typography>{resultClause}</Typography>
                <br />
                {groupedCondition
                  ?.getAffectedNodes()
                  .map((nodeDecision, index) => (
                    <React.Fragment key={index}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography>
                            {props.formModel
                              .getNode(nodeDecision.nodeId as number)
                              ?.getShortName()}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>
                            {nodeDecision.impact ===
                            protobufTypes.DecisionType.HIDE
                              ? "Hide"
                              : "Make Optional"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {props.displayMode !== FormDisplayMode.EditMode ? null : (
          <Button
            onClick={(e) => {
              // TODO: add cancel click handler
              props.onClose();
              return null;
              // onClose(undefined, undefined, undefined, undefined, false);
              //setQuestion(undefined);
            }}
          >
            Cancel
          </Button>
        )}
        {/* TODO: Enable OK button only if inputs are OK */}
        <Button
          disabled={
            !submittable && props.displayMode === FormDisplayMode.EditMode
          }
          color="primary"
          onClick={(e) => {
            // TODO: add OK button handler
            // TODO: add button to delete the condition
            onSave();
            props.onClose();
          }}
        >
          {props.displayMode === FormDisplayMode.EditMode ? "OK" : "CLOSE"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
