import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Icon from "@material-ui/core/Icon/Icon";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import React, { useState } from "react";

export type ShareButtonPropsType = {
  title: string; // title to show
  url: string; // url to share
  text?: string; // text to be shared
};
/**
 * The button that opens up share dialog on supported browsers (all on mobile phones),
 * or opens up a pop up to copy the content to be shared.
 */
export default function ShareButton(props: ShareButtonPropsType) {
  const [isDialogOpen, openDialog] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const onClick = (_event: any) => {
    // below is to deal with the typescript error that
    // share property doesn't exist on navigator object
    let navigatorWithShare = navigator as Navigator &
      Record<"share", (x: any) => Promise<void>>;
    if (navigatorWithShare.share) {
      // invoke native sharing mechanism of the device
      // detail: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
      navigatorWithShare
        .share({
          title: props.title,
          url: props.url,
          text: props.text,
        })
        .then(() => {
          console.log(`shared ${props.url} ${props.text}`);
        })
        .catch(console.error);
    } else {
      // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
      /* typescript has problem with below code, particularly with query parameter.
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then((result) => {
          if (result.state === "granted" || result.state === "prompt") {
            // write to the clipboard now 
            navigator.clipboard.writeText(props.url);
          }
        });
        */

      /* write to the clipboard now */
      navigator.clipboard.writeText(props.url).then(
        function () {
          /* clipboard successfully set */
          setCopySuccess(true);
        },
        () => setCopySuccess(false)
      );
      // open the dialog
      openDialog(true);
    }
  };

  return (
    <>
      <IconButton onClick={onClick}>
        <Icon>share</Icon>
      </IconButton>
      <Dialog
        onClose={(e) => openDialog(false)}
        aria-labelledby={props.title}
        open={isDialogOpen}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Typography>{props.text}</Typography>
          <Typography>{props.url}</Typography>
          {copySuccess === true ? (
            <Typography variant="body2">
              Copied the url to clipboard !
            </Typography>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
