import React, { useReducer } from "react";
import MTextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/Assignment";
import InputAdornment from "@material-ui/core/InputAdornment";
import protobufTypes, {
  Form,
  Node,
  Question,
  AnswerOption,
  AnswerValue,
} from "src/proto/FormServerMessages";
import { NodeModel } from "src/services/models/node_model";
import Badge from "react-bootstrap/Badge";
import {
  Card,
  Divider,
  Tooltip,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Input,
  Select,
  InputLabel,
  MenuItem,
  createStyles,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import "./answer.css";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "src/components/formelements/enums";
import NumberFormat from "react-number-format";
import AnswerOptionView from "./answer_option";
import AnswerBoxNumber from "./answer_box_number";
import AnswerBoxText from "./answer_box_text";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";
import { messages } from "src/translations/intl_messages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

//     -------------------------------------------------
//     |    (node)  *-- auxiliary data = Question      |
//     |                     |1◆                      |
//     |---------------------|--------------------------
//                           |*
//                 ------------------------------------
//                 |   AnswerOption                   |
//                 ------------------------------------

type AnswerViewProps = {
  question: NodeModel;
  filledForm: FilledFormModel | undefined;
  displayMode: FormDisplayMode;
  onNeedToRefreshForm: () => void;
};

export default function AnswerGetterView(props: AnswerViewProps) {
  let question = props.question.node.question as protobufTypes.Question;
  let type = question.type;
  const intl = useIntl();
  const classes = useStyles();

  const yesText = intl.formatMessage(messages.yesText);
  const noText = intl.formatMessage(messages.noText);

  const [value, setValue] = React.useState(
    props.filledForm?.getGivenAnswer(props.question)
  );
  const [, forceUiRefresh] = useReducer((x) => x + 1, 0);

  //((props.filledForm as FilledFormModel).getGivenAnswer(props.question) as protobufTypes.AnswerGiven).answerValue

  const handleFillInBlankChange = (filledText: string) => {
    if (props.displayMode === FormDisplayMode.RecordMode) {
      // record is being displayed, don't allow to change value
      return;
    }
    const conditionalNodeImpacted:
      | boolean
      | undefined = props.filledForm?.giveTextAnswer(
      props.question,
      filledText
    );
    setValue(props.filledForm?.getGivenAnswer(props.question));
    if (conditionalNodeImpacted) {
      // TODO: make the full form get refreshed.
      props.onNeedToRefreshForm();
    }
  };
  const handleOptionChange = (event: any) => {
    if (props.displayMode === FormDisplayMode.RecordMode) {
      // record is being displayed, don't allow to change value
      return;
    }
    const conditionalNodeImpacted:
      | boolean
      | undefined = props.filledForm?.giveOptionAnswer(
      props.question,
      Number(event.target.value)
    );
    const newValue = props.filledForm?.getGivenAnswer(props.question);
    setValue(newValue);
    // const optionIndex = question.options
    //   .map((option) => option.value)
    //   .findIndex(value?.answerValue);
    // console.log(optionIndex);
    if (conditionalNodeImpacted) {
      // TODO: make the full form get refreshed.
      props.onNeedToRefreshForm();
    }
  };

  const handleSelect = (index: number, checked: boolean) => {
    if (props.displayMode === FormDisplayMode.RecordMode) {
      // record is being displayed, don't allow to change value
      return;
    }
    console.log(`selected: ${index}, ${checked}`);
    if (checked) {
      props.filledForm?.selectChoice(props.question, index);
    } else {
      props.filledForm?.resetOptionAnswer(props.question, index);
    }
    const newValue = props.filledForm?.getGivenAnswer(props.question);
    // the newValue is same (same reference) as old value
    // therefore below setValue will not trigger UI refresh
    setValue(newValue);
    // trigger UI refresh
    forceUiRefresh();
  };

  const handleYesNoAnswer = (trueFalseValue: boolean, checked: boolean) => {
    if (props.displayMode === FormDisplayMode.RecordMode) {
      // record is being displayed, don't allow to change value
      return;
    }
    console.log(checked);
    //(props.filledForm as FilledFormModel).give
    if (checked) {
      props.filledForm?.giveYesNoAnswer(props.question, trueFalseValue);
      const newValue = props.filledForm?.getGivenAnswer(props.question);
      setValue(newValue);
    } else {
      // TODO: remove the value from props.filledForm
      props.filledForm?.resetYesNoAnswer(props.question, trueFalseValue);
      setValue(undefined);
    }
  };
  let view;
  let orientationHorizontal = false;
  switch (type) {
    case protobufTypes.QuestionType.FILL_IN_BLANK:
      /**
          <AnswerBoxNumber onAnswer={handleFillinBlankChange} maxNumber={80} />
          <AnswerBoxText onAnswer={handleFillinBlankChange} maxLength={5} />
          */
      view = (
        <Input
          className="answerGetter"
          multiline
          onChange={(e) => handleFillInBlankChange(e.target.value)}
          value={value?.answerValues[0].textValue}
        />
      );
      break;
    case protobufTypes.QuestionType.YES_NO:
      view = (
        <FormGroup row className="answerGetter">
          <AnswerOptionView
            index={0}
            multiSelect
            checked={
              value !== undefined &&
              (value.answerValues[0] as AnswerValue).trueFalseValue
            }
            handleSelect={(index, checked) => {
              handleYesNoAnswer(true, checked);
            }}
            textToShow={yesText}
            displayMode={props.displayMode}
          />
          <AnswerOptionView
            index={1}
            multiSelect
            checked={
              value !== undefined && !value.answerValues[0].trueFalseValue
            }
            handleSelect={(index, checked) => {
              handleYesNoAnswer(false, checked);
            }}
            textToShow={noText}
            displayMode={props.displayMode}
          />
        </FormGroup>
      );
      break;
    case protobufTypes.QuestionType.MULTI_CHOICE:
      // It looks like FormControlLabel doesn't work if value is number (the radio button doesn't change its appearance).
      // Perhaps reason is that The DOM API casts value of RadioGroup to a string. See https://material-ui.com/api/radio-group/
      // Therefore, converting it to string below

      if (value) {
        console.log(JSON.stringify(value));
      }
      // TODO: make orientationHorizontal false if length of any option is greater than 20 character
      const shouldUseSelect: boolean =
        question.options.length > 5 && // when number of options are more than 5
        props.displayMode !== FormDisplayMode.EditMode; // and when it is not to do edit of options
      if (shouldUseSelect) {
        view = (
          <FormControl className={classes.formControl}>
            <InputLabel id={`label-${props.question.id}`}>
              Choose one
            </InputLabel>
            <Select
              labelId={`label-${props.question.id}`}
              id={`select-${props.question.id}`}
              aria-label={"choose one"}
              name="options"
              value={
                value === undefined
                  ? value
                  : (value
                      .answerValues[0] as AnswerValue).integerValue.toString()
              }
              onChange={handleOptionChange}
            >
              {(question.options as AnswerOption[]).map((option, index) => (
                <MenuItem key={index} value={index.toString()}>
                  {option.accompaniedText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else {
        view = (
          <RadioGroup
            aria-label={"choose one"}
            name="options"
            value={
              value === undefined
                ? value
                : (value.answerValues[0] as AnswerValue).integerValue.toString()
            }
            onChange={handleOptionChange}
            row={orientationHorizontal}
          >
            {(question.options as AnswerOption[]).map((option, index) => (
              <AnswerOptionView
                key={index}
                index={index}
                textToShow={option.accompaniedText}
                displayMode={props.displayMode}
                checked={
                  value !== undefined &&
                  value.answerValues.includes(option.value as AnswerValue)
                }
              />
            ))}
          </RadioGroup>
        );
      }
      break;
    case protobufTypes.QuestionType.MULTI_SELECT:
      // TODO: modify AnswerOptionView to support multi-select in edit
      view = (
        <FormGroup row className="answerGetter">
          {(question.options as AnswerOption[]).map((option, index) => (
            <AnswerOptionView
              key={index}
              index={index}
              multiSelect
              checked={
                value !== undefined &&
                (value.answerValues as AnswerValue[]).filter(
                  (e: AnswerValue) => isEqual(e, option.value) //option.value.
                ).length > 0 /** add logic to check  value.answerValues */
              }
              handleSelect={handleSelect}
              textToShow={option.accompaniedText}
              displayMode={props.displayMode}
            />
          ))}
        </FormGroup>
      );

      break;
  }
  return view;
}
