import React from "react";
import { Button, Container } from "@material-ui/core";

export type PreviousNextSectionNavigationPropsType = {
  prev?: boolean;
  next?: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export function PreviousNextSectionNavigation(
  props: PreviousNextSectionNavigationPropsType & { children: any }
) {
  const previousSectionButton: JSX.Element = (
    <>
      {props.prev === false ? null : (
        <Button
          onClick={(e) => {
            props.onPrevButtonClick();
          }}
        >
          PREVIOUS SECTION
        </Button>
      )}
    </>
  );
  const nextSectionButton: JSX.Element = (
    <>
      {props.next === false ? null : (
        <Button
          onClick={(e) => {
            props.onNextButtonClick();
          }}
        >
          NEXT SECTION
        </Button>
      )}
    </>
  );
  return (
    <Container>
      {previousSectionButton}
      {props.children}
      {nextSectionButton}
    </Container>
  );
}
