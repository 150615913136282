import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "src/screens/login";
import Navigation from "src/navigation/navigation";
import Home from "src/screens/home";
import About from "src/screens/about";
import { Settings } from "src/screens/settings";
import { Account } from "./screens/account";
import Box from "@material-ui/core/Box";
import { FormList } from "./screens/forms_list";
import { CreateForm } from "./screens/form_create";
import { EditForm } from "./screens/form_edit";
import { ViewForm } from "./screens/form_view";
import { RecordsList } from "./screens/records_list";
import { DataSource } from "./screens/data_source";
import { FillForm } from "./screens/form_fill";
import { FormRecords } from "./screens/form_records";
import { Record } from "./screens/record";
import { BillingCurrent } from "./screens/billing_current";
import { BillingHistory } from "./screens/billing_history";
import PrivateRoute from "./components/auth/PrivateRoute";
import { CreateFormLink } from "./screens/formlink_create";

/*
A <Switch> looks through its children <Route>s and
      renders the first one that matches the current URL.
*/
export function AppScreens() {
  return (
    <Box>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/forms">
          <FormList />
        </Route>
        <PrivateRoute path="/billing/current">
          <BillingCurrent />
        </PrivateRoute>
        <PrivateRoute path="/billing/history">
          <BillingHistory />
        </PrivateRoute>
        <PrivateRoute path="/billing">
          <BillingCurrent />
        </PrivateRoute>
        <Route path="/formlink/create">
          <CreateFormLink />
        </Route>
        <Route path="/form/create">
          <CreateForm />
        </Route>
        <Route path="/form/edit/:formId">
          <EditForm />
        </Route>
        <Route path="/form/view/:formId">
          <ViewForm />
        </Route>
        <Route path="/records">
          <RecordsList />
        </Route>
        {/* all records for a form */}
        <Route path="/form/records/:formId">
          <FormRecords />
        </Route>
        {/* form to fill */}
        <Route path="/fill/:formLinkId">
          <FillForm />
        </Route>
        {/* all links of form to fill */}
        <Route path="/fill-links">
          <RecordsList />
        </Route>
        {/* all links for a particular form to fill*/}
        <Route path="/records/:formId">
          <RecordsList />
        </Route>
        {/* a particular record*/}
        <Route path="/record/view/:recordGuid">
          <Record />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/login">
          <Navigation title="Login">
            <Login />
          </Navigation>
        </Route>
        <Route path="/account">
          <Navigation title="Account">
            <Account />
          </Navigation>
        </Route>
        <PrivateRoute path="/datasource">
          <Navigation title="Data Sources">
            <DataSource />
          </Navigation>
        </PrivateRoute>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Box>
  );
}
