import React from "react";
import Welcome from "src/components/market/welcome";

type HomePropsType = {};
export default function Home(props: HomePropsType) {
  return (
    <div>
      <Welcome />
    </div>
  );
}
