import React from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import GenericTable from "../common/generic_table";
import {
  GenericActionMenuCreator,
  MenuItemType,
} from "../common/generic_action_menu";
import {
  RecordMetadata,
  RecordAttribute,
} from "src/services/datatypes/metadata";
import Typography from "@material-ui/core/Typography";
import { getContextMenuForRecordRows } from "./getContextMenuForRecordRows";
import { getFormRecordTableHeaderCellProps } from "./getFormRecordTableHeaderCellProps";
import { HeadCellType } from "../common/enhanced_table_head";
import { useGetFormRecords } from "../../hooks/useGetFormRecords";

/**
 * Goal : To make a table with header which contains sortable columns.
 * optionally, should allow multiple rows selection, for certain action or actions.
 *
 * The bottom, optionally, should have page footer.
 * This table component should be reusable.
 *
 * numCols: number of columns
 * tableHeader: list of elements
 * dataRows: array of list of data elements
 */

// TODO: do the color coding of rows if user adds some tag or based on the attribute values

/*
	GUID         string `json:"recordGuid"`
	FormGUID     string `json:"formGuid"`
	FormVersion  int    `json:"formVersion"`
	CreationTime int64  `json:"recordDate"`
	// the person who created the record
	AccountID string `json:"accountID"`
	Synched   bool   `json:"synched"`
	// record can be draft or completely submitted
	// the support for draft state will allow the user to
	// complete the form filling from different machines
	// and not having to suffer from frustration of starting from scratch
	// The draft record will be deleted after 7 days of last modification.
	// Draft record will not be visible to the form owner.
	IsDraft bool `json:"isDraft"`
*/

export type TableColumns = {
  recordGuid: string;
  version: number;
  recordName: string;
  creationTime: string;
  // below is for the attribute names
  // which will be determined on getting the response from the server.
  [key: string]: string | number;
};

export type TableHeaderCellPropType = HeadCellType<TableColumns>;

type FormRecordsPropsType = {
  formGuid: string;
};
export default function FormRecords(props: FormRecordsPropsType) {
  const intl = useIntl();
  const history = useHistory();
  const recordsArray = useGetFormRecords(props.formGuid);

  const contextActionMenuItems: MenuItemType[] = getContextMenuForRecordRows(
    history
  );
  const formName: string | undefined = recordsArray
    ? recordsArray[0].formName
    : undefined;

  // convert the metadata received from server about records
  // to properties that UI element takes in.
  const rows: TableColumns[] | undefined = recordsArray?.map(
    (elem: RecordMetadata) => {
      var data: TableColumns = {
        recordGuid: elem.recordGuid,
        recordName: elem.formGuid,
        version: elem.formVersion,
        // recordDate is being set by server
        // TODO: change in server to multiple by 1000, for consistency
        creationTime: intl.formatDate(new Date(elem.recordDate * 1000)),
      };
      try {
        (JSON.parse(elem.attributes) as RecordAttribute[]).forEach(
          (recordAttr) =>
            Object.entries(recordAttr).forEach(([key, value]) => {
              // TODO: generate displayable language specific value from the AnswerValue
              // e.g. yes (note: ✓ means no in Finnish, Swedish ), no (x) for boolean value, date corresponding to locale, .., ..
              data[key] = value;
            })
        );
      } catch (error) {
        console.log("failed in parsing ", elem.attributes);
      }

      return data;
    }
  );

  let dataAttrNames: string[] = [];
  // get the attribute key names
  if (recordsArray !== undefined) {
    try {
      dataAttrNames =
        recordsArray === undefined
          ? []
          : (JSON.parse(recordsArray[0].attributes) as RecordAttribute[]).map(
              (recordAttr: RecordAttribute) => Object.keys(recordAttr)[0]
            );
    } catch (error) {
      console.log(
        "failed to parse recordsArray[0].attributes: ",
        recordsArray[0].attributes
      );
    }
  }

  // get column headers, along with columns to show attribute values.
  const headCellProps = getFormRecordTableHeaderCellProps(dataAttrNames);

  return (
    <div>
      {/*ToDo: add action to take on selected records Do refactoring of
      GenericTable as given at 10:36 of
      https://www.youtube.com/watch?v=nQo0EdHNjto That will make much easy to
      use headCells.
      */}
      {rows === undefined ? null : (
        <>
          <Typography variant="subtitle1"> Records of {formName} </Typography>
          <GenericTable<TableColumns>
            defaultSortKeyName={"recordName"}
            guid={"recordGuid"}
            rows={rows}
            headCells={headCellProps}
            addSelectionCheckbox={false}
            actionMenu={GenericActionMenuCreator(contextActionMenuItems)}
          />
        </>
      )}
    </div>
  );
}
