import React, { useState } from "react";
import {
  supportedLanguageCodeToLocalName,
  ChosenLanguage,
} from "src/language/language";
import {
  Icon,
  Button,
  Popover,
  ButtonBase,
  IconButton,
} from "@material-ui/core";

import "./language-switcher.css";
import { useLanguageSetter } from "src/language/languageContext";
import LanguageIcon from "@material-ui/icons/Language";

type PropsType = {};

function LanguageOptions() {
  let optionArray = [];
  for (const languageCode in supportedLanguageCodeToLocalName) {
    const languageName = supportedLanguageCodeToLocalName[languageCode];
    optionArray.push(
      <option value={languageCode} key={languageCode}>
        {languageName}
      </option>
    );
  }
  return optionArray;
}
// See https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// The first option is Language so that we don't have provide extra space for label "Language"
function LanguageSwitcher(props: PropsType) {
  const setLang = useLanguageSetter();
  function onChangeHandler(event: React.ChangeEvent<HTMLSelectElement>) {
    //props.onLanguageChange(event.target.value);
    setLang(event.target.value);
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //<Icon onClick={handleClick}>language</Icon>
  return (
    <>
      <IconButton className="alignVertical" onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <select id="language" value="Language" onChange={onChangeHandler}>
          <option value="Language">language</option>
          {LanguageOptions()}
        </select>
      </Popover>
    </>
  );
}
export default LanguageSwitcher;
