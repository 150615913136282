import React from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import CssBaseline from "@material-ui/core/CssBaseline";
import { messages } from "src/language/language";
import "firebase/auth";
import { useLanguage, LanguageProvider } from "./language/languageContext";
import { AppScreens } from "./app_screens";
import { BrowserRouter } from "react-router-dom";
import AppConfiguration from "./configurations/constants";

// Below is to help in testing
// the components that depend on the providers
export const AppContainer: React.FC = ({ children }) => (
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <LanguageProvider>{children}</LanguageProvider>
    </BrowserRouter>
  </React.StrictMode>
);

export default function App() {
  // we should allow it to change by user selection
  const userLangCode = useLanguage();
  return (
    <IntlProvider
      locale={userLangCode}
      key={userLangCode}
      messages={messages[userLangCode]}
    >
      <CssBaseline />

      <div>
        <AppScreens />
      </div>
    </IntlProvider>
  );
}
