import { IntlShape } from "react-intl";
import { MenuItemType } from "../common/generic_action_menu";
import { messages } from "../../translations/intl_messages";
import FormService from "src/services/form_service";
import { History } from "history";
/**
 * The action menu to show on clicking rows of form list table
 *
 * @param intl : react-int object to support language localization
 * @param history : react-dom-router history object to change the URL path
 */
export function getContextActionMenuItems(
  intl: IntlShape,
  history: History<any>
): MenuItemType[] {
  return [
    {
      name: intl.formatMessage(messages.view),
      action: (data?: any) => {
        console.log(`pressed view ${data}`);
        history.push(`/form/view/${data}`);
      }
    },
    {
      name: intl.formatMessage(messages.edit),
      action: (data?: any) => {
        console.log(`pressed edit ${data}`);
        history.push(`/form/edit/${data}`);
      }
    },
    {
      name: intl.formatMessage(messages.delete),
      action: (data?: any) => {
        FormService.deleteForm(data).then((e) => window.location.reload());
        console.log(`pressed delete ${data}`);
      }
    }
  ];
}
