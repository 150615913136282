import React from "react";

import "./data_source.css";

type DataSourcePropsType = {};
export function DataSource(props: DataSourcePropsType) {
  return (
    <div>
      {/* अपनी डेटा के साथ काम करें :) */}
      <h1>Work with your own data :) </h1>
      Data source is helpful when you want
      <ol>
        <li>to refer to a single place for certain data in your forms; or </li>
        <li>
          to change the data that is used in a form without having to edit that
          form{" "}
        </li>
      </ol>
      Data source helps you to focus on your data.
      <p>
        <br />
        <br />
        &lt; insert a diagram to show the flow &gt;
      </p>
      <div>
        <h2>What can be the data source ? </h2>A data source contains your data.
        It can contain
        <ol>
          <li>single entry, or</li>
          <li>a list of entries</li>
        </ol>
        For example,
        <ol>
          <li>your fee for first visit: $50, or</li>
          <li>list of your fee for different kind of services: ($30) </li>
        </ol>
        <h2>Control of data source</h2>
        You control these entries. Any time you can modify, add, remove entries.
        <h2>How does data source help you ?</h2>
        What is the use of the data source ? Data source can be used as source
        of data in form options. Use of data source allows you to create a
        dynamic form. For example, you may be a doctor, a consultant, etc and
        your clients book appointments with you. you can specify a data source
        that specifies your availability in coming 3 days; and you can keep
        changing the entries of that data source depending on the confirmed
        appointments. Your form can display these available times to users.
      </div>
      <nav>
        <ul>
          <li>
            <a href="/datasource/list"> Existing Datasources </a>
          </li>
          <li>
            <a href="/datasource/new"> Add new data sources</a>
          </li>
          <li>
            <a href="/datasource/edit">
              Add/Remove/Edit data to/from/of the selected data source
            </a>
          </li>
        </ul>
      </nav>
      <div>
        Available ways to use the data source :
        <ol>
          <li>As entries of select button, for list data.</li>
          <li>As value in any part of text.</li>
          <li>As values in special fields of forms</li>
        </ol>
      </div>
      <h1>Your Existing Data Sources</h1>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Type</th>
            <th>Values</th>
            <th>Existing forms or pages using these</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Shop Open Time</td>
            <td>Time</td>
            <td>10:30am</td>
            <td>Renfrew Barber Shop</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Shop Close Time</td>
            <td>Time</td>
            <td>8pm</td>
            <td>Renfrew Barber Shop</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Break Time</td>
            <td>Times</td>
            <td>
              <ol>
                <li>Mon-Fri, 2:30-3:00pm</li>
                <li>Sat-Sun, 1:00-3:00pm</li>
              </ol>
            </td>
            <td>Renfrew Barber Shop</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Available Appointments</td>
            <td>Times</td>
            <td>
              <ol>
                <li>Tomorrow, 9:30-10:00am</li>
                <li>Tomorrow, 10:00-10:30am</li>
                <li>27 Aug, 9:00-9:30am</li>
                <li>27 Aug, 9:3-10:00am</li>
              </ol>
            </td>
            <td>Make an appointment</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Services</td>
            <td>Text</td>
            <td>
              <ol>
                <li>Hair cut (child), $9</li>
                <li>Hair cut, $12</li>
                <li>Shaving, $8</li>
                <li>Hair cut + Shaving, $15</li>
              </ol>
            </td>
            <td>
              <ol>
                <li>Make an appointment </li>
                <li>Services</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
