import React from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import FormDrawerContent from "./form_drawer_content";
const useStyles = makeStyles((theme: Theme) => ({
  // necessary for content to be below app bar
  toolbar: { backgroundColor: blue[900], ...theme.mixins.toolbar },
}));

/**
 * It returns the content that should be shown on drawer.
 */
function DrawerContent() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <FormDrawerContent />
    </div>
  );
}

export default DrawerContent;
