import { Button, Checkbox, Radio, RadioGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import TextField from "@material-ui/core/TextField/TextField";
import { CheckBox } from "@material-ui/icons";
import React from "react";

export default function CreateFormLink() {
  let possibleSubmitAuths = [
    "No authentication needed",
    "Any authentication OK",
    "Only phone authentication OK",
    "Only specified user",
    "Only specified group",
  ];
  return (
    <>
      <div>Name of Form : $formName </div>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Kind of acceptable authentication to submit filled form
        </FormLabel>
        <RadioGroup>
          {possibleSubmitAuths.map((authType, index) => (
            <FormControlLabel
              key={index}
              value={authType}
              control={<Radio />}
              label={authType}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Kind of acceptable authentication to view link to fill form
        </FormLabel>
        <RadioGroup>
          {possibleSubmitAuths.map((authType, index) => (
            <FormControlLabel
              key={index}
              value={authType}
              control={<Radio />}
              label={authType}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">When link to form be visible</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={true} name="checkedB" color="primary" />}
          label="Now"
        />
        <TextField
          id="datetime-local"
          label="Link visibility date"
          type="datetime-local"
          value={Date.now().toLocaleString()}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">When link to form be removed</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={true} name="checkedB" color="primary" />}
          label="Never"
        />
        <FormControlLabel
          control={<Checkbox checked={true} name="checkedB" color="primary" />}
          label="Once submitted"
        />
        <TextField
          id="datetime-local"
          label="Link removal date"
          type="datetime-local"
          value="now"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          Tags to idenfity link to fill the form and submitted records
        </FormLabel>
        <TextField
          label="tags"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <Button>Create</Button>
    </>
  );
}
