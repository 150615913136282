import { IntlShape } from "react-intl";

export function getTranslations(intl: IntlShape) {
  const conditionLabel = intl.formatMessage({
    id: "app.condition.clause_start",
    defaultMessage: "If answer to questions is as below",
    description: "The label to show to start condition clause"
  });
  const shortNameLabel = intl.formatMessage({
    id: "app.condition.shortName",
    defaultMessage: "Name for this set of conditions",
    description: "The label to show as condition name"
  });
  const askToShowImpactedNodes = intl.formatMessage({
    id: "app.condition.ask.show_impact",
    defaultMessage: "Show impacted nodes"
  });
  const questionLabel = intl.formatMessage({
    id: "app.condition.question",
    defaultMessage: "question"
  });
  const answerLabel = intl.formatMessage({
    id: "app.condition.answer",
    defaultMessage: "answer"
  });
  const impactOptional = intl.formatMessage({
    id: "app.condition.optionalLabel",
    defaultMessage: "Optional"
  });
  const impactHide = intl.formatMessage({
    id: "app.condition.hideLabel",
    defaultMessage: "Hidden"
  });
  const andLabel = intl.formatMessage({
    id: "app.condition.and",
    defaultMessage: "and"
  });
  const resultClause = intl.formatMessage({
    id: "app.condition.resultClause",
    defaultMessage: "Then, make"
  });
  return {
    shortNameLabel,
    conditionLabel,
    askToShowImpactedNodes,
    resultClause
  };
}
