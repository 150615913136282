import {
  Form,
  Node,
  Section,
  Question,
  INode
} from "src/proto/FormServerMessages";
import { v4 as uuid } from "uuid";
import { NodeModel } from "./node_model";
import { IFormModel } from "./interface/form_model";
import { INodeModel } from "./interface/node_model";
import { ConditionsInForm, NodeDecisionType } from "./decision_model";

/**
 * TODO: extract the interface out, so that developers can do
 * their work easily, without getting distracted by bulk of
 * implementation detail.
 */

interface IFormModelStatic {
  newForm(formName: string): IFormModel;
  from(form: Form): IFormModel;
  newSection(
    parentNode: NodeModel,
    sectionName: string,
    sectionDescription: string | undefined
  ): NodeModel;
  newQuestion(parentNode: NodeModel, questionName: string): NodeModel;
}
/**
 * FormModel wraps the Form
 */
export class FormModel implements IFormModel {
  public rootNodeModel: NodeModel | undefined = undefined;

  private form: Form | undefined = undefined;
  private isValidForm: boolean = true;
  // the goal of below is to easily find the node
  // to move or delete it
  private integerNodeMap = new Map<number, NodeModel>();
  private nextNodeId: number = 1;

  // conditions in form
  conditionInForm: ConditionsInForm | undefined;
  private constructor() {}

  public static newForm(formName: string): FormModel {
    let formModel = new FormModel();
    formModel.form = new Form();
    formModel.form.guid = uuid();
    formModel.form.node = new Node();
    (formModel.form.node as Node).nodeType = undefined;
    formModel.form.node.shortName = formName;
    formModel.rootNodeModel = new NodeModel(
      formModel,
      undefined,
      formModel.form.node as Node
    );
    formModel.conditionInForm = new ConditionsInForm(formModel);
    return formModel;
  }
  public static from(form: Form) {
    let formModel = new FormModel();
    formModel.form = form;
    // put the nodes to integerNodeMap
    // recursive call is happening inside new NodeModel()
    formModel.rootNodeModel = new NodeModel(
      formModel,
      undefined,
      form.node as Node
    );
    // construct the conditions handler
    formModel.conditionInForm = ConditionsInForm.from(formModel);
    return formModel;
  }

  public static newSection(
    parentNode: INodeModel,
    sectionName: string,
    sectionDescription: string | undefined
  ) {
    let sectionNew = FormModel.newNode_(
      parentNode as NodeModel,
      sectionName,
      "section"
    );
    if (sectionDescription) {
      sectionNew.node.textToDisplay = sectionDescription;
    }
    /**
     * Remove below forEach code if you want to allow
     * a section to have both questions and sections as its children
     */
    // check if the parent had questions
    // if yes, move them to this first section created
    parentNode.getChildren().forEach((childNode: INodeModel, index) => {
      if (childNode.isQuestion()) {
        parentNode.unlinkChild(childNode);
        sectionNew.addChild(childNode as NodeModel);
      }
    });

    return sectionNew;
  }
  public static newQuestion(
    parentNode: INodeModel,
    questionName: string,
    isOptional: boolean,
    question: Question,
    questionText: string
  ) {
    let questionNew = this.newNode_(
      parentNode as NodeModel,
      questionName,
      "question"
    );
    questionNew.node.optional = isOptional !== undefined && isOptional;
    questionNew.node.question = question;
    questionNew.setTextToDisplay(questionText);
    return questionNew;
  }
  /**
   * Below function is used by meta_language.ts
   * @param parentNode
   * @param questionName
   */
  public static newQuestion_(parentNode: INodeModel, questionName: string) {
    let questionNew = this.newNode_(
      parentNode as NodeModel,
      questionName,
      "question"
    );
    return questionNew;
  }
  public getForm(): Form | undefined {
    // do the required checks that form is valid
    if (!this.isValidForm) {
      console.error("Form is not valid");
    }

    return this.form;
  }
  public getRoot(): NodeModel {
    return this.rootNodeModel as NodeModel;
  }
  /**
   * Returns list of questions (not in order)
   */
  public getQuestionNodes(): NodeModel[] {
    let questionNodes: NodeModel[] = [];
    this.integerNodeMap.forEach((node) => {
      if (node.node.nodeType === "question") {
        questionNodes.push(node);
      }
    });
    return questionNodes;
  }

  public setTitle(title: string) {
    (this.form?.node as Node).shortName = title;
  }
  public getTitle(): string | undefined {
    return (this.form?.node as Node).shortName;
  }
  public setText(text: string) {
    (this.form?.node as Node).textToDisplay = text;
  }

  public registerNewNode(node: NodeModel): number {
    return this.addToMap_(node);
  }

  public registerExistingNode(node: NodeModel) {
    const id = node.id;
    this.integerNodeMap.set(id, node);
    if (this.nextNodeId <= id) {
      this.nextNodeId = id + 1;
    }
  }
  public hasSections(): boolean {
    let nodeIterator = this.integerNodeMap.values();
    for (
      let nextNode = nodeIterator.next();
      !nextNode.done;
      nextNode = nodeIterator.next()
    ) {
      if (nextNode.value.node.nodeType === "section") {
        return true;
      }
    }
    return false;
  }
  /**
   * Creates the new node : Node of given type
   * and creates the NodeModel by making the new node child of parentNode: NodeModel
   * @param parentNode
   * @param shortName
   * @param nodeType
   */
  private static newNode_(
    parentNode: NodeModel,
    shortName: string,
    nodeType: Node["nodeType"]
  ) {
    let section: Node = new Node();
    switch (nodeType) {
      case "section":
        section.section = new Section();
        break;
      case "question":
        section.question = new Question();
        break;
    }
    //section.nodeType = nodeType;
    section.shortName = shortName;
    section.textToDisplay = "";
    let nodeModel = new NodeModel(parentNode.form, parentNode, section);
    parentNode.addChild(nodeModel);
    return nodeModel;
  }
  // below is not in use, use it
  private static doDft_(node: Node, action: (arg2: Node) => void) {
    node.children.forEach((child) => {
      this.doDft_(child as Node, action);
    });
    action(node);
  }
  private getNextNodeId_(): number {
    return this.nextNodeId++;
  }
  private addToMap_(node: NodeModel): number {
    let id: number = this.getNextNodeId_();
    this.integerNodeMap.set(id, node);
    return id;
  }
  /**
   * Below method is to simplify the code, in order to not check if
   * a node has been deleted e.g. in methods like getQuestionNodes()
   * to avoid TypeError: Cannot read property 'nodeType' of undefined
   * @param nodeId
   */
  public removeDeletedNodeFromMap(nodeId: number) {
    this.integerNodeMap.delete(nodeId);
  }
  /**
   * Impact node
   * This is to support conditions, and based on answers given, the nodes will be impacted.
   */
  public impactNode(nodeId: number, impactType: NodeDecisionType) {
    const node = this.integerNodeMap.get(nodeId);
    if (node !== undefined) {
      node.impactNode(impactType);
    }
  }
  /**
   * Impact node
   * This is to support conditions, and based on answers given, the nodes will be impacted.
   */
  public resetImpactNode(nodeId: number) {
    const node = this.integerNodeMap.get(nodeId);
    if (node !== undefined) {
      node.resetImpactToNode();
    }
  }
  /**
   * get handler to conditions in form
   */
  getConditionsHandler(): ConditionsInForm {
    return this.conditionInForm as ConditionsInForm;
  }
  /**
   * get Node
   * added to support retrieval of node to use in conditions UI
   */
  getNode(id: number): INodeModel | undefined {
    return this.integerNodeMap.get(id);
  }
  /**
   * return true if name is already used as short name
   * ensure uniqueness of short name
   * used in attributes
   * @param nodeId: node id of question node on which name will be used as short name, undefined if question node is new one.
   */
  isShortNameConflicting(nodeId: number | undefined, name: string): boolean {
    // go over list of attribute nodes
    return (
      this.getQuestionNodes()
        .filter((qNode) => qNode.getId() !== nodeId)
        // .filter((qNode) => qNode.isAttribute())
        .map((qNode) => qNode.getShortName())
        .findIndex((shortName) => shortName === name) >= 0
    );
  }
}
