/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const FillInBlankInputRestriction = ($root.FillInBlankInputRestriction = (() => {
  /**
   * Properties of a FillInBlankInputRestriction.
   * @exports IFillInBlankInputRestriction
   * @interface IFillInBlankInputRestriction
   * @property {string|null} [maskToUse] FillInBlankInputRestriction maskToUse
   * @property {string|null} [patternToFit] FillInBlankInputRestriction patternToFit
   * @property {number|null} [min] FillInBlankInputRestriction min
   * @property {number|null} [max] FillInBlankInputRestriction max
   * @property {string|null} [validatorToUse] FillInBlankInputRestriction validatorToUse
   */

  /**
   * Constructs a new FillInBlankInputRestriction.
   * @exports FillInBlankInputRestriction
   * @classdesc Represents a FillInBlankInputRestriction.
   * @implements IFillInBlankInputRestriction
   * @constructor
   * @param {IFillInBlankInputRestriction=} [properties] Properties to set
   */
  function FillInBlankInputRestriction(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * FillInBlankInputRestriction maskToUse.
   * @member {string} maskToUse
   * @memberof FillInBlankInputRestriction
   * @instance
   */
  FillInBlankInputRestriction.prototype.maskToUse = "";

  /**
   * FillInBlankInputRestriction patternToFit.
   * @member {string} patternToFit
   * @memberof FillInBlankInputRestriction
   * @instance
   */
  FillInBlankInputRestriction.prototype.patternToFit = "";

  /**
   * FillInBlankInputRestriction min.
   * @member {number} min
   * @memberof FillInBlankInputRestriction
   * @instance
   */
  FillInBlankInputRestriction.prototype.min = 0;

  /**
   * FillInBlankInputRestriction max.
   * @member {number} max
   * @memberof FillInBlankInputRestriction
   * @instance
   */
  FillInBlankInputRestriction.prototype.max = 0;

  /**
   * FillInBlankInputRestriction validatorToUse.
   * @member {string} validatorToUse
   * @memberof FillInBlankInputRestriction
   * @instance
   */
  FillInBlankInputRestriction.prototype.validatorToUse = "";

  /**
   * Encodes the specified FillInBlankInputRestriction message. Does not implicitly {@link FillInBlankInputRestriction.verify|verify} messages.
   * @function encode
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {IFillInBlankInputRestriction} message FillInBlankInputRestriction message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  FillInBlankInputRestriction.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.maskToUse != null &&
      Object.hasOwnProperty.call(message, "maskToUse")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.maskToUse);
    if (
      message.patternToFit != null &&
      Object.hasOwnProperty.call(message, "patternToFit")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.patternToFit);
    if (message.min != null && Object.hasOwnProperty.call(message, "min"))
      writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.min);
    if (message.max != null && Object.hasOwnProperty.call(message, "max"))
      writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.max);
    if (
      message.validatorToUse != null &&
      Object.hasOwnProperty.call(message, "validatorToUse")
    )
      writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.validatorToUse);
    return writer;
  };

  /**
   * Encodes the specified FillInBlankInputRestriction message, length delimited. Does not implicitly {@link FillInBlankInputRestriction.verify|verify} messages.
   * @function encodeDelimited
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {IFillInBlankInputRestriction} message FillInBlankInputRestriction message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  FillInBlankInputRestriction.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a FillInBlankInputRestriction message from the specified reader or buffer.
   * @function decode
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {FillInBlankInputRestriction} FillInBlankInputRestriction
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  FillInBlankInputRestriction.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.FillInBlankInputRestriction();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.maskToUse = reader.string();
          break;
        case 2:
          message.patternToFit = reader.string();
          break;
        case 3:
          message.min = reader.int32();
          break;
        case 4:
          message.max = reader.int32();
          break;
        case 5:
          message.validatorToUse = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a FillInBlankInputRestriction message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {FillInBlankInputRestriction} FillInBlankInputRestriction
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  FillInBlankInputRestriction.decodeDelimited = function decodeDelimited(
    reader
  ) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a FillInBlankInputRestriction message.
   * @function verify
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  FillInBlankInputRestriction.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.maskToUse != null && message.hasOwnProperty("maskToUse"))
      if (!$util.isString(message.maskToUse))
        return "maskToUse: string expected";
    if (message.patternToFit != null && message.hasOwnProperty("patternToFit"))
      if (!$util.isString(message.patternToFit))
        return "patternToFit: string expected";
    if (message.min != null && message.hasOwnProperty("min"))
      if (!$util.isInteger(message.min)) return "min: integer expected";
    if (message.max != null && message.hasOwnProperty("max"))
      if (!$util.isInteger(message.max)) return "max: integer expected";
    if (
      message.validatorToUse != null &&
      message.hasOwnProperty("validatorToUse")
    )
      if (!$util.isString(message.validatorToUse))
        return "validatorToUse: string expected";
    return null;
  };

  /**
   * Creates a FillInBlankInputRestriction message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {FillInBlankInputRestriction} FillInBlankInputRestriction
   */
  FillInBlankInputRestriction.fromObject = function fromObject(object) {
    if (object instanceof $root.FillInBlankInputRestriction) return object;
    let message = new $root.FillInBlankInputRestriction();
    if (object.maskToUse != null) message.maskToUse = String(object.maskToUse);
    if (object.patternToFit != null)
      message.patternToFit = String(object.patternToFit);
    if (object.min != null) message.min = object.min | 0;
    if (object.max != null) message.max = object.max | 0;
    if (object.validatorToUse != null)
      message.validatorToUse = String(object.validatorToUse);
    return message;
  };

  /**
   * Creates a plain object from a FillInBlankInputRestriction message. Also converts values to other types if specified.
   * @function toObject
   * @memberof FillInBlankInputRestriction
   * @static
   * @param {FillInBlankInputRestriction} message FillInBlankInputRestriction
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  FillInBlankInputRestriction.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) {
      object.maskToUse = "";
      object.patternToFit = "";
      object.min = 0;
      object.max = 0;
      object.validatorToUse = "";
    }
    if (message.maskToUse != null && message.hasOwnProperty("maskToUse"))
      object.maskToUse = message.maskToUse;
    if (message.patternToFit != null && message.hasOwnProperty("patternToFit"))
      object.patternToFit = message.patternToFit;
    if (message.min != null && message.hasOwnProperty("min"))
      object.min = message.min;
    if (message.max != null && message.hasOwnProperty("max"))
      object.max = message.max;
    if (
      message.validatorToUse != null &&
      message.hasOwnProperty("validatorToUse")
    )
      object.validatorToUse = message.validatorToUse;
    return object;
  };

  /**
   * Converts this FillInBlankInputRestriction to JSON.
   * @function toJSON
   * @memberof FillInBlankInputRestriction
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  FillInBlankInputRestriction.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return FillInBlankInputRestriction;
})());

export const AnswerValue = ($root.AnswerValue = (() => {
  /**
   * Properties of an AnswerValue.
   * @exports IAnswerValue
   * @interface IAnswerValue
   * @property {number|null} [integerValue] AnswerValue integerValue
   * @property {boolean|null} [trueFalseValue] AnswerValue trueFalseValue
   * @property {string|null} [textValue] AnswerValue textValue
   * @property {number|null} [decimalValue] AnswerValue decimalValue
   * @property {string|null} [dateValue] AnswerValue dateValue
   * @property {string|null} [timeHourMinuteValue] AnswerValue timeHourMinuteValue
   * @property {string|null} [emailValue] AnswerValue emailValue
   * @property {number|null} [moneyValue] AnswerValue moneyValue
   */

  /**
   * Constructs a new AnswerValue.
   * @exports AnswerValue
   * @classdesc Represents an AnswerValue.
   * @implements IAnswerValue
   * @constructor
   * @param {IAnswerValue=} [properties] Properties to set
   */
  function AnswerValue(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * AnswerValue integerValue.
   * @member {number} integerValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.integerValue = 0;

  /**
   * AnswerValue trueFalseValue.
   * @member {boolean} trueFalseValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.trueFalseValue = false;

  /**
   * AnswerValue textValue.
   * @member {string} textValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.textValue = "";

  /**
   * AnswerValue decimalValue.
   * @member {number} decimalValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.decimalValue = 0;

  /**
   * AnswerValue dateValue.
   * @member {string} dateValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.dateValue = "";

  /**
   * AnswerValue timeHourMinuteValue.
   * @member {string} timeHourMinuteValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.timeHourMinuteValue = "";

  /**
   * AnswerValue emailValue.
   * @member {string} emailValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.emailValue = "";

  /**
   * AnswerValue moneyValue.
   * @member {number} moneyValue
   * @memberof AnswerValue
   * @instance
   */
  AnswerValue.prototype.moneyValue = 0;

  // OneOf field names bound to virtual getters and setters
  let $oneOfFields;

  /**
   * AnswerValue value.
   * @member {"integerValue"|"trueFalseValue"|"textValue"|"decimalValue"|"dateValue"|"timeHourMinuteValue"|"emailValue"|"moneyValue"|undefined} value
   * @memberof AnswerValue
   * @instance
   */
  Object.defineProperty(AnswerValue.prototype, "value", {
    get: $util.oneOfGetter(
      ($oneOfFields = [
        "integerValue",
        "trueFalseValue",
        "textValue",
        "decimalValue",
        "dateValue",
        "timeHourMinuteValue",
        "emailValue",
        "moneyValue",
      ])
    ),
    set: $util.oneOfSetter($oneOfFields),
  });

  /**
   * Encodes the specified AnswerValue message. Does not implicitly {@link AnswerValue.verify|verify} messages.
   * @function encode
   * @memberof AnswerValue
   * @static
   * @param {IAnswerValue} message AnswerValue message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  AnswerValue.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.integerValue != null &&
      Object.hasOwnProperty.call(message, "integerValue")
    )
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.integerValue);
    if (
      message.trueFalseValue != null &&
      Object.hasOwnProperty.call(message, "trueFalseValue")
    )
      writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.trueFalseValue);
    if (
      message.textValue != null &&
      Object.hasOwnProperty.call(message, "textValue")
    )
      writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.textValue);
    if (
      message.decimalValue != null &&
      Object.hasOwnProperty.call(message, "decimalValue")
    )
      writer.uint32(/* id 4, wireType 5 =*/ 37).float(message.decimalValue);
    if (
      message.dateValue != null &&
      Object.hasOwnProperty.call(message, "dateValue")
    )
      writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.dateValue);
    if (
      message.timeHourMinuteValue != null &&
      Object.hasOwnProperty.call(message, "timeHourMinuteValue")
    )
      writer
        .uint32(/* id 6, wireType 2 =*/ 50)
        .string(message.timeHourMinuteValue);
    if (
      message.emailValue != null &&
      Object.hasOwnProperty.call(message, "emailValue")
    )
      writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.emailValue);
    if (
      message.moneyValue != null &&
      Object.hasOwnProperty.call(message, "moneyValue")
    )
      writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.moneyValue);
    return writer;
  };

  /**
   * Encodes the specified AnswerValue message, length delimited. Does not implicitly {@link AnswerValue.verify|verify} messages.
   * @function encodeDelimited
   * @memberof AnswerValue
   * @static
   * @param {IAnswerValue} message AnswerValue message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  AnswerValue.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes an AnswerValue message from the specified reader or buffer.
   * @function decode
   * @memberof AnswerValue
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {AnswerValue} AnswerValue
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  AnswerValue.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.AnswerValue();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.integerValue = reader.int32();
          break;
        case 2:
          message.trueFalseValue = reader.bool();
          break;
        case 3:
          message.textValue = reader.string();
          break;
        case 4:
          message.decimalValue = reader.float();
          break;
        case 5:
          message.dateValue = reader.string();
          break;
        case 6:
          message.timeHourMinuteValue = reader.string();
          break;
        case 7:
          message.emailValue = reader.string();
          break;
        case 8:
          message.moneyValue = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes an AnswerValue message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof AnswerValue
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {AnswerValue} AnswerValue
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  AnswerValue.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies an AnswerValue message.
   * @function verify
   * @memberof AnswerValue
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  AnswerValue.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    let properties = {};
    if (
      message.integerValue != null &&
      message.hasOwnProperty("integerValue")
    ) {
      properties.value = 1;
      if (!$util.isInteger(message.integerValue))
        return "integerValue: integer expected";
    }
    if (
      message.trueFalseValue != null &&
      message.hasOwnProperty("trueFalseValue")
    ) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (typeof message.trueFalseValue !== "boolean")
        return "trueFalseValue: boolean expected";
    }
    if (message.textValue != null && message.hasOwnProperty("textValue")) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (!$util.isString(message.textValue))
        return "textValue: string expected";
    }
    if (
      message.decimalValue != null &&
      message.hasOwnProperty("decimalValue")
    ) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (typeof message.decimalValue !== "number")
        return "decimalValue: number expected";
    }
    if (message.dateValue != null && message.hasOwnProperty("dateValue")) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (!$util.isString(message.dateValue))
        return "dateValue: string expected";
    }
    if (
      message.timeHourMinuteValue != null &&
      message.hasOwnProperty("timeHourMinuteValue")
    ) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (!$util.isString(message.timeHourMinuteValue))
        return "timeHourMinuteValue: string expected";
    }
    if (message.emailValue != null && message.hasOwnProperty("emailValue")) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (!$util.isString(message.emailValue))
        return "emailValue: string expected";
    }
    if (message.moneyValue != null && message.hasOwnProperty("moneyValue")) {
      if (properties.value === 1) return "value: multiple values";
      properties.value = 1;
      if (!$util.isInteger(message.moneyValue))
        return "moneyValue: integer expected";
    }
    return null;
  };

  /**
   * Creates an AnswerValue message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof AnswerValue
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {AnswerValue} AnswerValue
   */
  AnswerValue.fromObject = function fromObject(object) {
    if (object instanceof $root.AnswerValue) return object;
    let message = new $root.AnswerValue();
    if (object.integerValue != null)
      message.integerValue = object.integerValue | 0;
    if (object.trueFalseValue != null)
      message.trueFalseValue = Boolean(object.trueFalseValue);
    if (object.textValue != null) message.textValue = String(object.textValue);
    if (object.decimalValue != null)
      message.decimalValue = Number(object.decimalValue);
    if (object.dateValue != null) message.dateValue = String(object.dateValue);
    if (object.timeHourMinuteValue != null)
      message.timeHourMinuteValue = String(object.timeHourMinuteValue);
    if (object.emailValue != null)
      message.emailValue = String(object.emailValue);
    if (object.moneyValue != null) message.moneyValue = object.moneyValue | 0;
    return message;
  };

  /**
   * Creates a plain object from an AnswerValue message. Also converts values to other types if specified.
   * @function toObject
   * @memberof AnswerValue
   * @static
   * @param {AnswerValue} message AnswerValue
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  AnswerValue.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (
      message.integerValue != null &&
      message.hasOwnProperty("integerValue")
    ) {
      object.integerValue = message.integerValue;
      if (options.oneofs) object.value = "integerValue";
    }
    if (
      message.trueFalseValue != null &&
      message.hasOwnProperty("trueFalseValue")
    ) {
      object.trueFalseValue = message.trueFalseValue;
      if (options.oneofs) object.value = "trueFalseValue";
    }
    if (message.textValue != null && message.hasOwnProperty("textValue")) {
      object.textValue = message.textValue;
      if (options.oneofs) object.value = "textValue";
    }
    if (
      message.decimalValue != null &&
      message.hasOwnProperty("decimalValue")
    ) {
      object.decimalValue =
        options.json && !isFinite(message.decimalValue)
          ? String(message.decimalValue)
          : message.decimalValue;
      if (options.oneofs) object.value = "decimalValue";
    }
    if (message.dateValue != null && message.hasOwnProperty("dateValue")) {
      object.dateValue = message.dateValue;
      if (options.oneofs) object.value = "dateValue";
    }
    if (
      message.timeHourMinuteValue != null &&
      message.hasOwnProperty("timeHourMinuteValue")
    ) {
      object.timeHourMinuteValue = message.timeHourMinuteValue;
      if (options.oneofs) object.value = "timeHourMinuteValue";
    }
    if (message.emailValue != null && message.hasOwnProperty("emailValue")) {
      object.emailValue = message.emailValue;
      if (options.oneofs) object.value = "emailValue";
    }
    if (message.moneyValue != null && message.hasOwnProperty("moneyValue")) {
      object.moneyValue = message.moneyValue;
      if (options.oneofs) object.value = "moneyValue";
    }
    return object;
  };

  /**
   * Converts this AnswerValue to JSON.
   * @function toJSON
   * @memberof AnswerValue
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  AnswerValue.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return AnswerValue;
})());

export const AnswerOption = ($root.AnswerOption = (() => {
  /**
   * Properties of an AnswerOption.
   * @exports IAnswerOption
   * @interface IAnswerOption
   * @property {IAnswerValue|null} [value] AnswerOption value
   * @property {string|null} [accompaniedText] AnswerOption accompaniedText
   */

  /**
   * Constructs a new AnswerOption.
   * @exports AnswerOption
   * @classdesc Represents an AnswerOption.
   * @implements IAnswerOption
   * @constructor
   * @param {IAnswerOption=} [properties] Properties to set
   */
  function AnswerOption(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * AnswerOption value.
   * @member {IAnswerValue|null|undefined} value
   * @memberof AnswerOption
   * @instance
   */
  AnswerOption.prototype.value = null;

  /**
   * AnswerOption accompaniedText.
   * @member {string} accompaniedText
   * @memberof AnswerOption
   * @instance
   */
  AnswerOption.prototype.accompaniedText = "";

  /**
   * Encodes the specified AnswerOption message. Does not implicitly {@link AnswerOption.verify|verify} messages.
   * @function encode
   * @memberof AnswerOption
   * @static
   * @param {IAnswerOption} message AnswerOption message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  AnswerOption.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
      $root.AnswerValue.encode(
        message.value,
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
      ).ldelim();
    if (
      message.accompaniedText != null &&
      Object.hasOwnProperty.call(message, "accompaniedText")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.accompaniedText);
    return writer;
  };

  /**
   * Encodes the specified AnswerOption message, length delimited. Does not implicitly {@link AnswerOption.verify|verify} messages.
   * @function encodeDelimited
   * @memberof AnswerOption
   * @static
   * @param {IAnswerOption} message AnswerOption message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  AnswerOption.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes an AnswerOption message from the specified reader or buffer.
   * @function decode
   * @memberof AnswerOption
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {AnswerOption} AnswerOption
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  AnswerOption.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.AnswerOption();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.value = $root.AnswerValue.decode(reader, reader.uint32());
          break;
        case 2:
          message.accompaniedText = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes an AnswerOption message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof AnswerOption
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {AnswerOption} AnswerOption
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  AnswerOption.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies an AnswerOption message.
   * @function verify
   * @memberof AnswerOption
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  AnswerOption.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.value != null && message.hasOwnProperty("value")) {
      let error = $root.AnswerValue.verify(message.value);
      if (error) return "value." + error;
    }
    if (
      message.accompaniedText != null &&
      message.hasOwnProperty("accompaniedText")
    )
      if (!$util.isString(message.accompaniedText))
        return "accompaniedText: string expected";
    return null;
  };

  /**
   * Creates an AnswerOption message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof AnswerOption
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {AnswerOption} AnswerOption
   */
  AnswerOption.fromObject = function fromObject(object) {
    if (object instanceof $root.AnswerOption) return object;
    let message = new $root.AnswerOption();
    if (object.value != null) {
      if (typeof object.value !== "object")
        throw TypeError(".AnswerOption.value: object expected");
      message.value = $root.AnswerValue.fromObject(object.value);
    }
    if (object.accompaniedText != null)
      message.accompaniedText = String(object.accompaniedText);
    return message;
  };

  /**
   * Creates a plain object from an AnswerOption message. Also converts values to other types if specified.
   * @function toObject
   * @memberof AnswerOption
   * @static
   * @param {AnswerOption} message AnswerOption
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  AnswerOption.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) {
      object.value = null;
      object.accompaniedText = "";
    }
    if (message.value != null && message.hasOwnProperty("value"))
      object.value = $root.AnswerValue.toObject(message.value, options);
    if (
      message.accompaniedText != null &&
      message.hasOwnProperty("accompaniedText")
    )
      object.accompaniedText = message.accompaniedText;
    return object;
  };

  /**
   * Converts this AnswerOption to JSON.
   * @function toJSON
   * @memberof AnswerOption
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  AnswerOption.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return AnswerOption;
})());

export const Condition = ($root.Condition = (() => {
  /**
   * Properties of a Condition.
   * @exports ICondition
   * @interface ICondition
   * @property {string|null} [condition] Condition condition
   */

  /**
   * Constructs a new Condition.
   * @exports Condition
   * @classdesc Represents a Condition.
   * @implements ICondition
   * @constructor
   * @param {ICondition=} [properties] Properties to set
   */
  function Condition(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * Condition condition.
   * @member {string} condition
   * @memberof Condition
   * @instance
   */
  Condition.prototype.condition = "";

  /**
   * Encodes the specified Condition message. Does not implicitly {@link Condition.verify|verify} messages.
   * @function encode
   * @memberof Condition
   * @static
   * @param {ICondition} message Condition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Condition.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.condition != null &&
      Object.hasOwnProperty.call(message, "condition")
    )
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.condition);
    return writer;
  };

  /**
   * Encodes the specified Condition message, length delimited. Does not implicitly {@link Condition.verify|verify} messages.
   * @function encodeDelimited
   * @memberof Condition
   * @static
   * @param {ICondition} message Condition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Condition.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a Condition message from the specified reader or buffer.
   * @function decode
   * @memberof Condition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {Condition} Condition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Condition.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.Condition();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.condition = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a Condition message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof Condition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {Condition} Condition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Condition.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a Condition message.
   * @function verify
   * @memberof Condition
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  Condition.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.condition != null && message.hasOwnProperty("condition"))
      if (!$util.isString(message.condition))
        return "condition: string expected";
    return null;
  };

  /**
   * Creates a Condition message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof Condition
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {Condition} Condition
   */
  Condition.fromObject = function fromObject(object) {
    if (object instanceof $root.Condition) return object;
    let message = new $root.Condition();
    if (object.condition != null) message.condition = String(object.condition);
    return message;
  };

  /**
   * Creates a plain object from a Condition message. Also converts values to other types if specified.
   * @function toObject
   * @memberof Condition
   * @static
   * @param {Condition} message Condition
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  Condition.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) object.condition = "";
    if (message.condition != null && message.hasOwnProperty("condition"))
      object.condition = message.condition;
    return object;
  };

  /**
   * Converts this Condition to JSON.
   * @function toJSON
   * @memberof Condition
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  Condition.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return Condition;
})());

export const NodeDecision = ($root.NodeDecision = (() => {
  /**
   * Properties of a NodeDecision.
   * @exports INodeDecision
   * @interface INodeDecision
   * @property {number|null} [nodeId] NodeDecision nodeId
   * @property {DecisionType|null} [impact] NodeDecision impact
   */

  /**
   * Constructs a new NodeDecision.
   * @exports NodeDecision
   * @classdesc Represents a NodeDecision.
   * @implements INodeDecision
   * @constructor
   * @param {INodeDecision=} [properties] Properties to set
   */
  function NodeDecision(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * NodeDecision nodeId.
   * @member {number} nodeId
   * @memberof NodeDecision
   * @instance
   */
  NodeDecision.prototype.nodeId = 0;

  /**
   * NodeDecision impact.
   * @member {DecisionType} impact
   * @memberof NodeDecision
   * @instance
   */
  NodeDecision.prototype.impact = 0;

  /**
   * Encodes the specified NodeDecision message. Does not implicitly {@link NodeDecision.verify|verify} messages.
   * @function encode
   * @memberof NodeDecision
   * @static
   * @param {INodeDecision} message NodeDecision message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  NodeDecision.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId"))
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.nodeId);
    if (message.impact != null && Object.hasOwnProperty.call(message, "impact"))
      writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.impact);
    return writer;
  };

  /**
   * Encodes the specified NodeDecision message, length delimited. Does not implicitly {@link NodeDecision.verify|verify} messages.
   * @function encodeDelimited
   * @memberof NodeDecision
   * @static
   * @param {INodeDecision} message NodeDecision message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  NodeDecision.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a NodeDecision message from the specified reader or buffer.
   * @function decode
   * @memberof NodeDecision
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {NodeDecision} NodeDecision
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  NodeDecision.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.NodeDecision();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeId = reader.int32();
          break;
        case 2:
          message.impact = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a NodeDecision message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof NodeDecision
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {NodeDecision} NodeDecision
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  NodeDecision.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a NodeDecision message.
   * @function verify
   * @memberof NodeDecision
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  NodeDecision.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.nodeId != null && message.hasOwnProperty("nodeId"))
      if (!$util.isInteger(message.nodeId)) return "nodeId: integer expected";
    if (message.impact != null && message.hasOwnProperty("impact"))
      switch (message.impact) {
        default:
          return "impact: enum value expected";
        case 0:
        case 1:
          break;
      }
    return null;
  };

  /**
   * Creates a NodeDecision message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof NodeDecision
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {NodeDecision} NodeDecision
   */
  NodeDecision.fromObject = function fromObject(object) {
    if (object instanceof $root.NodeDecision) return object;
    let message = new $root.NodeDecision();
    if (object.nodeId != null) message.nodeId = object.nodeId | 0;
    switch (object.impact) {
      case "MAKE_OPTIONAL":
      case 0:
        message.impact = 0;
        break;
      case "HIDE":
      case 1:
        message.impact = 1;
        break;
    }
    return message;
  };

  /**
   * Creates a plain object from a NodeDecision message. Also converts values to other types if specified.
   * @function toObject
   * @memberof NodeDecision
   * @static
   * @param {NodeDecision} message NodeDecision
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  NodeDecision.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) {
      object.nodeId = 0;
      object.impact = options.enums === String ? "MAKE_OPTIONAL" : 0;
    }
    if (message.nodeId != null && message.hasOwnProperty("nodeId"))
      object.nodeId = message.nodeId;
    if (message.impact != null && message.hasOwnProperty("impact"))
      object.impact =
        options.enums === String
          ? $root.DecisionType[message.impact]
          : message.impact;
    return object;
  };

  /**
   * Converts this NodeDecision to JSON.
   * @function toJSON
   * @memberof NodeDecision
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  NodeDecision.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return NodeDecision;
})());

export const NodeCondition = ($root.NodeCondition = (() => {
  /**
   * Properties of a NodeCondition.
   * @exports INodeCondition
   * @interface INodeCondition
   * @property {number|null} [nodeId] NodeCondition nodeId
   * @property {IAnswerValue|null} [refValue] NodeCondition refValue
   * @property {ConditionType|null} [matchOperator] NodeCondition matchOperator
   */

  /**
   * Constructs a new NodeCondition.
   * @exports NodeCondition
   * @classdesc Represents a NodeCondition.
   * @implements INodeCondition
   * @constructor
   * @param {INodeCondition=} [properties] Properties to set
   */
  function NodeCondition(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * NodeCondition nodeId.
   * @member {number} nodeId
   * @memberof NodeCondition
   * @instance
   */
  NodeCondition.prototype.nodeId = 0;

  /**
   * NodeCondition refValue.
   * @member {IAnswerValue|null|undefined} refValue
   * @memberof NodeCondition
   * @instance
   */
  NodeCondition.prototype.refValue = null;

  /**
   * NodeCondition matchOperator.
   * @member {ConditionType} matchOperator
   * @memberof NodeCondition
   * @instance
   */
  NodeCondition.prototype.matchOperator = 0;

  /**
   * Encodes the specified NodeCondition message. Does not implicitly {@link NodeCondition.verify|verify} messages.
   * @function encode
   * @memberof NodeCondition
   * @static
   * @param {INodeCondition} message NodeCondition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  NodeCondition.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId"))
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.nodeId);
    if (
      message.refValue != null &&
      Object.hasOwnProperty.call(message, "refValue")
    )
      $root.AnswerValue.encode(
        message.refValue,
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
      ).ldelim();
    if (
      message.matchOperator != null &&
      Object.hasOwnProperty.call(message, "matchOperator")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.matchOperator);
    return writer;
  };

  /**
   * Encodes the specified NodeCondition message, length delimited. Does not implicitly {@link NodeCondition.verify|verify} messages.
   * @function encodeDelimited
   * @memberof NodeCondition
   * @static
   * @param {INodeCondition} message NodeCondition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  NodeCondition.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a NodeCondition message from the specified reader or buffer.
   * @function decode
   * @memberof NodeCondition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {NodeCondition} NodeCondition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  NodeCondition.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.NodeCondition();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeId = reader.int32();
          break;
        case 2:
          message.refValue = $root.AnswerValue.decode(reader, reader.uint32());
          break;
        case 3:
          message.matchOperator = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a NodeCondition message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof NodeCondition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {NodeCondition} NodeCondition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  NodeCondition.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a NodeCondition message.
   * @function verify
   * @memberof NodeCondition
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  NodeCondition.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.nodeId != null && message.hasOwnProperty("nodeId"))
      if (!$util.isInteger(message.nodeId)) return "nodeId: integer expected";
    if (message.refValue != null && message.hasOwnProperty("refValue")) {
      let error = $root.AnswerValue.verify(message.refValue);
      if (error) return "refValue." + error;
    }
    if (
      message.matchOperator != null &&
      message.hasOwnProperty("matchOperator")
    )
      switch (message.matchOperator) {
        default:
          return "matchOperator: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          break;
      }
    return null;
  };

  /**
   * Creates a NodeCondition message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof NodeCondition
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {NodeCondition} NodeCondition
   */
  NodeCondition.fromObject = function fromObject(object) {
    if (object instanceof $root.NodeCondition) return object;
    let message = new $root.NodeCondition();
    if (object.nodeId != null) message.nodeId = object.nodeId | 0;
    if (object.refValue != null) {
      if (typeof object.refValue !== "object")
        throw TypeError(".NodeCondition.refValue: object expected");
      message.refValue = $root.AnswerValue.fromObject(object.refValue);
    }
    switch (object.matchOperator) {
      case "EQ":
      case 0:
        message.matchOperator = 0;
        break;
      case "GE":
      case 1:
        message.matchOperator = 1;
        break;
      case "GTR":
      case 2:
        message.matchOperator = 2;
        break;
      case "LE":
      case 3:
        message.matchOperator = 3;
        break;
      case "LESS":
      case 4:
        message.matchOperator = 4;
        break;
      case "NEQ":
      case 5:
        message.matchOperator = 5;
        break;
    }
    return message;
  };

  /**
   * Creates a plain object from a NodeCondition message. Also converts values to other types if specified.
   * @function toObject
   * @memberof NodeCondition
   * @static
   * @param {NodeCondition} message NodeCondition
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  NodeCondition.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) {
      object.nodeId = 0;
      object.refValue = null;
      object.matchOperator = options.enums === String ? "EQ" : 0;
    }
    if (message.nodeId != null && message.hasOwnProperty("nodeId"))
      object.nodeId = message.nodeId;
    if (message.refValue != null && message.hasOwnProperty("refValue"))
      object.refValue = $root.AnswerValue.toObject(message.refValue, options);
    if (
      message.matchOperator != null &&
      message.hasOwnProperty("matchOperator")
    )
      object.matchOperator =
        options.enums === String
          ? $root.ConditionType[message.matchOperator]
          : message.matchOperator;
    return object;
  };

  /**
   * Converts this NodeCondition to JSON.
   * @function toJSON
   * @memberof NodeCondition
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  NodeCondition.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return NodeCondition;
})());

export const GroupCondition = ($root.GroupCondition = (() => {
  /**
   * Properties of a GroupCondition.
   * @exports IGroupCondition
   * @interface IGroupCondition
   * @property {Array.<INodeCondition>|null} [nodeConditions] GroupCondition nodeConditions
   * @property {string|null} [name] GroupCondition name
   * @property {Array.<INodeDecision>|null} [nodeDecisions] GroupCondition nodeDecisions
   */

  /**
   * Constructs a new GroupCondition.
   * @exports GroupCondition
   * @classdesc Represents a GroupCondition.
   * @implements IGroupCondition
   * @constructor
   * @param {IGroupCondition=} [properties] Properties to set
   */
  function GroupCondition(properties) {
    this.nodeConditions = [];
    this.nodeDecisions = [];
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * GroupCondition nodeConditions.
   * @member {Array.<INodeCondition>} nodeConditions
   * @memberof GroupCondition
   * @instance
   */
  GroupCondition.prototype.nodeConditions = $util.emptyArray;

  /**
   * GroupCondition name.
   * @member {string} name
   * @memberof GroupCondition
   * @instance
   */
  GroupCondition.prototype.name = "";

  /**
   * GroupCondition nodeDecisions.
   * @member {Array.<INodeDecision>} nodeDecisions
   * @memberof GroupCondition
   * @instance
   */
  GroupCondition.prototype.nodeDecisions = $util.emptyArray;

  /**
   * Encodes the specified GroupCondition message. Does not implicitly {@link GroupCondition.verify|verify} messages.
   * @function encode
   * @memberof GroupCondition
   * @static
   * @param {IGroupCondition} message GroupCondition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  GroupCondition.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.nodeConditions != null && message.nodeConditions.length)
      for (let i = 0; i < message.nodeConditions.length; ++i)
        $root.NodeCondition.encode(
          message.nodeConditions[i],
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
        ).ldelim();
    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name);
    if (message.nodeDecisions != null && message.nodeDecisions.length)
      for (let i = 0; i < message.nodeDecisions.length; ++i)
        $root.NodeDecision.encode(
          message.nodeDecisions[i],
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified GroupCondition message, length delimited. Does not implicitly {@link GroupCondition.verify|verify} messages.
   * @function encodeDelimited
   * @memberof GroupCondition
   * @static
   * @param {IGroupCondition} message GroupCondition message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  GroupCondition.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a GroupCondition message from the specified reader or buffer.
   * @function decode
   * @memberof GroupCondition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {GroupCondition} GroupCondition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  GroupCondition.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.GroupCondition();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (!(message.nodeConditions && message.nodeConditions.length))
            message.nodeConditions = [];
          message.nodeConditions.push(
            $root.NodeCondition.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          if (!(message.nodeDecisions && message.nodeDecisions.length))
            message.nodeDecisions = [];
          message.nodeDecisions.push(
            $root.NodeDecision.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a GroupCondition message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof GroupCondition
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {GroupCondition} GroupCondition
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  GroupCondition.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a GroupCondition message.
   * @function verify
   * @memberof GroupCondition
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  GroupCondition.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (
      message.nodeConditions != null &&
      message.hasOwnProperty("nodeConditions")
    ) {
      if (!Array.isArray(message.nodeConditions))
        return "nodeConditions: array expected";
      for (let i = 0; i < message.nodeConditions.length; ++i) {
        let error = $root.NodeCondition.verify(message.nodeConditions[i]);
        if (error) return "nodeConditions." + error;
      }
    }
    if (message.name != null && message.hasOwnProperty("name"))
      if (!$util.isString(message.name)) return "name: string expected";
    if (
      message.nodeDecisions != null &&
      message.hasOwnProperty("nodeDecisions")
    ) {
      if (!Array.isArray(message.nodeDecisions))
        return "nodeDecisions: array expected";
      for (let i = 0; i < message.nodeDecisions.length; ++i) {
        let error = $root.NodeDecision.verify(message.nodeDecisions[i]);
        if (error) return "nodeDecisions." + error;
      }
    }
    return null;
  };

  /**
   * Creates a GroupCondition message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof GroupCondition
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {GroupCondition} GroupCondition
   */
  GroupCondition.fromObject = function fromObject(object) {
    if (object instanceof $root.GroupCondition) return object;
    let message = new $root.GroupCondition();
    if (object.nodeConditions) {
      if (!Array.isArray(object.nodeConditions))
        throw TypeError(".GroupCondition.nodeConditions: array expected");
      message.nodeConditions = [];
      for (let i = 0; i < object.nodeConditions.length; ++i) {
        if (typeof object.nodeConditions[i] !== "object")
          throw TypeError(".GroupCondition.nodeConditions: object expected");
        message.nodeConditions[i] = $root.NodeCondition.fromObject(
          object.nodeConditions[i]
        );
      }
    }
    if (object.name != null) message.name = String(object.name);
    if (object.nodeDecisions) {
      if (!Array.isArray(object.nodeDecisions))
        throw TypeError(".GroupCondition.nodeDecisions: array expected");
      message.nodeDecisions = [];
      for (let i = 0; i < object.nodeDecisions.length; ++i) {
        if (typeof object.nodeDecisions[i] !== "object")
          throw TypeError(".GroupCondition.nodeDecisions: object expected");
        message.nodeDecisions[i] = $root.NodeDecision.fromObject(
          object.nodeDecisions[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a GroupCondition message. Also converts values to other types if specified.
   * @function toObject
   * @memberof GroupCondition
   * @static
   * @param {GroupCondition} message GroupCondition
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  GroupCondition.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.arrays || options.defaults) {
      object.nodeConditions = [];
      object.nodeDecisions = [];
    }
    if (options.defaults) object.name = "";
    if (message.nodeConditions && message.nodeConditions.length) {
      object.nodeConditions = [];
      for (let j = 0; j < message.nodeConditions.length; ++j)
        object.nodeConditions[j] = $root.NodeCondition.toObject(
          message.nodeConditions[j],
          options
        );
    }
    if (message.name != null && message.hasOwnProperty("name"))
      object.name = message.name;
    if (message.nodeDecisions && message.nodeDecisions.length) {
      object.nodeDecisions = [];
      for (let j = 0; j < message.nodeDecisions.length; ++j)
        object.nodeDecisions[j] = $root.NodeDecision.toObject(
          message.nodeDecisions[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this GroupCondition to JSON.
   * @function toJSON
   * @memberof GroupCondition
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  GroupCondition.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return GroupCondition;
})());

export const Note = ($root.Note = (() => {
  /**
   * Properties of a Note.
   * @exports INote
   * @interface INote
   * @property {string|null} [text] Note text
   * @property {number|null} [backgroundColor] Note backgroundColor
   * @property {number|null} [foregroundColor] Note foregroundColor
   */

  /**
   * Constructs a new Note.
   * @exports Note
   * @classdesc Represents a Note.
   * @implements INote
   * @constructor
   * @param {INote=} [properties] Properties to set
   */
  function Note(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * Note text.
   * @member {string} text
   * @memberof Note
   * @instance
   */
  Note.prototype.text = "";

  /**
   * Note backgroundColor.
   * @member {number} backgroundColor
   * @memberof Note
   * @instance
   */
  Note.prototype.backgroundColor = 0;

  /**
   * Note foregroundColor.
   * @member {number} foregroundColor
   * @memberof Note
   * @instance
   */
  Note.prototype.foregroundColor = 0;

  /**
   * Encodes the specified Note message. Does not implicitly {@link Note.verify|verify} messages.
   * @function encode
   * @memberof Note
   * @static
   * @param {INote} message Note message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Note.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.text);
    if (
      message.backgroundColor != null &&
      Object.hasOwnProperty.call(message, "backgroundColor")
    )
      writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.backgroundColor);
    if (
      message.foregroundColor != null &&
      Object.hasOwnProperty.call(message, "foregroundColor")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.foregroundColor);
    return writer;
  };

  /**
   * Encodes the specified Note message, length delimited. Does not implicitly {@link Note.verify|verify} messages.
   * @function encodeDelimited
   * @memberof Note
   * @static
   * @param {INote} message Note message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Note.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a Note message from the specified reader or buffer.
   * @function decode
   * @memberof Note
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {Note} Note
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Note.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.Note();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.text = reader.string();
          break;
        case 2:
          message.backgroundColor = reader.int32();
          break;
        case 3:
          message.foregroundColor = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a Note message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof Note
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {Note} Note
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Note.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a Note message.
   * @function verify
   * @memberof Note
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  Note.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.text != null && message.hasOwnProperty("text"))
      if (!$util.isString(message.text)) return "text: string expected";
    if (
      message.backgroundColor != null &&
      message.hasOwnProperty("backgroundColor")
    )
      if (!$util.isInteger(message.backgroundColor))
        return "backgroundColor: integer expected";
    if (
      message.foregroundColor != null &&
      message.hasOwnProperty("foregroundColor")
    )
      if (!$util.isInteger(message.foregroundColor))
        return "foregroundColor: integer expected";
    return null;
  };

  /**
   * Creates a Note message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof Note
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {Note} Note
   */
  Note.fromObject = function fromObject(object) {
    if (object instanceof $root.Note) return object;
    let message = new $root.Note();
    if (object.text != null) message.text = String(object.text);
    if (object.backgroundColor != null)
      message.backgroundColor = object.backgroundColor | 0;
    if (object.foregroundColor != null)
      message.foregroundColor = object.foregroundColor | 0;
    return message;
  };

  /**
   * Creates a plain object from a Note message. Also converts values to other types if specified.
   * @function toObject
   * @memberof Note
   * @static
   * @param {Note} message Note
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  Note.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) {
      object.text = "";
      object.backgroundColor = 0;
      object.foregroundColor = 0;
    }
    if (message.text != null && message.hasOwnProperty("text"))
      object.text = message.text;
    if (
      message.backgroundColor != null &&
      message.hasOwnProperty("backgroundColor")
    )
      object.backgroundColor = message.backgroundColor;
    if (
      message.foregroundColor != null &&
      message.hasOwnProperty("foregroundColor")
    )
      object.foregroundColor = message.foregroundColor;
    return object;
  };

  /**
   * Converts this Note to JSON.
   * @function toJSON
   * @memberof Note
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  Note.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return Note;
})());

export const Question = ($root.Question = (() => {
  /**
   * Properties of a Question.
   * @exports IQuestion
   * @interface IQuestion
   * @property {boolean|null} [isRecordAttribute] Question isRecordAttribute
   * @property {QuestionType|null} [type] Question type
   * @property {Array.<IAnswerOption>|null} [options] Question options
   * @property {FillInBlankType|null} [fillInType] Question fillInType
   * @property {IFillInBlankInputRestriction|null} [fillInRestriction] Question fillInRestriction
   */

  /**
   * Constructs a new Question.
   * @exports Question
   * @classdesc Represents a Question.
   * @implements IQuestion
   * @constructor
   * @param {IQuestion=} [properties] Properties to set
   */
  function Question(properties) {
    this.options = [];
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * Question isRecordAttribute.
   * @member {boolean} isRecordAttribute
   * @memberof Question
   * @instance
   */
  Question.prototype.isRecordAttribute = false;

  /**
   * Question type.
   * @member {QuestionType} type
   * @memberof Question
   * @instance
   */
  Question.prototype.type = 0;

  /**
   * Question options.
   * @member {Array.<IAnswerOption>} options
   * @memberof Question
   * @instance
   */
  Question.prototype.options = $util.emptyArray;

  /**
   * Question fillInType.
   * @member {FillInBlankType} fillInType
   * @memberof Question
   * @instance
   */
  Question.prototype.fillInType = 0;

  /**
   * Question fillInRestriction.
   * @member {IFillInBlankInputRestriction|null|undefined} fillInRestriction
   * @memberof Question
   * @instance
   */
  Question.prototype.fillInRestriction = null;

  /**
   * Encodes the specified Question message. Does not implicitly {@link Question.verify|verify} messages.
   * @function encode
   * @memberof Question
   * @static
   * @param {IQuestion} message Question message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Question.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.isRecordAttribute != null &&
      Object.hasOwnProperty.call(message, "isRecordAttribute")
    )
      writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.isRecordAttribute);
    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
      writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.type);
    if (message.options != null && message.options.length)
      for (let i = 0; i < message.options.length; ++i)
        $root.AnswerOption.encode(
          message.options[i],
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim();
    if (
      message.fillInType != null &&
      Object.hasOwnProperty.call(message, "fillInType")
    )
      writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.fillInType);
    if (
      message.fillInRestriction != null &&
      Object.hasOwnProperty.call(message, "fillInRestriction")
    )
      $root.FillInBlankInputRestriction.encode(
        message.fillInRestriction,
        writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
      ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified Question message, length delimited. Does not implicitly {@link Question.verify|verify} messages.
   * @function encodeDelimited
   * @memberof Question
   * @static
   * @param {IQuestion} message Question message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Question.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a Question message from the specified reader or buffer.
   * @function decode
   * @memberof Question
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {Question} Question
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Question.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.Question();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isRecordAttribute = reader.bool();
          break;
        case 2:
          message.type = reader.int32();
          break;
        case 3:
          if (!(message.options && message.options.length))
            message.options = [];
          message.options.push(
            $root.AnswerOption.decode(reader, reader.uint32())
          );
          break;
        case 5:
          message.fillInType = reader.int32();
          break;
        case 6:
          message.fillInRestriction = $root.FillInBlankInputRestriction.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a Question message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof Question
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {Question} Question
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Question.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a Question message.
   * @function verify
   * @memberof Question
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  Question.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (
      message.isRecordAttribute != null &&
      message.hasOwnProperty("isRecordAttribute")
    )
      if (typeof message.isRecordAttribute !== "boolean")
        return "isRecordAttribute: boolean expected";
    if (message.type != null && message.hasOwnProperty("type"))
      switch (message.type) {
        default:
          return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
          break;
      }
    if (message.options != null && message.hasOwnProperty("options")) {
      if (!Array.isArray(message.options)) return "options: array expected";
      for (let i = 0; i < message.options.length; ++i) {
        let error = $root.AnswerOption.verify(message.options[i]);
        if (error) return "options." + error;
      }
    }
    if (message.fillInType != null && message.hasOwnProperty("fillInType"))
      switch (message.fillInType) {
        default:
          return "fillInType: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
          break;
      }
    if (
      message.fillInRestriction != null &&
      message.hasOwnProperty("fillInRestriction")
    ) {
      let error = $root.FillInBlankInputRestriction.verify(
        message.fillInRestriction
      );
      if (error) return "fillInRestriction." + error;
    }
    return null;
  };

  /**
   * Creates a Question message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof Question
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {Question} Question
   */
  Question.fromObject = function fromObject(object) {
    if (object instanceof $root.Question) return object;
    let message = new $root.Question();
    if (object.isRecordAttribute != null)
      message.isRecordAttribute = Boolean(object.isRecordAttribute);
    switch (object.type) {
      case "FILL_IN_BLANK":
      case 0:
        message.type = 0;
        break;
      case "MULTI_CHOICE":
      case 1:
        message.type = 1;
        break;
      case "YES_NO":
      case 2:
        message.type = 2;
        break;
      case "MULTI_SELECT":
      case 3:
        message.type = 3;
        break;
    }
    if (object.options) {
      if (!Array.isArray(object.options))
        throw TypeError(".Question.options: array expected");
      message.options = [];
      for (let i = 0; i < object.options.length; ++i) {
        if (typeof object.options[i] !== "object")
          throw TypeError(".Question.options: object expected");
        message.options[i] = $root.AnswerOption.fromObject(object.options[i]);
      }
    }
    switch (object.fillInType) {
      case "NUMBER":
      case 0:
        message.fillInType = 0;
        break;
      case "DECIMAL":
      case 1:
        message.fillInType = 1;
        break;
      case "TEXT":
      case 2:
        message.fillInType = 2;
        break;
      case "EMAIL":
      case 3:
        message.fillInType = 3;
        break;
      case "DATE":
      case 4:
        message.fillInType = 4;
        break;
      case "TIME":
      case 5:
        message.fillInType = 5;
        break;
      case "URL":
      case 6:
        message.fillInType = 6;
        break;
      case "PHONE":
      case 7:
        message.fillInType = 7;
        break;
      case "PHONE_VERIFIED":
      case 8:
        message.fillInType = 8;
        break;
      case "EMAIL_VERIFIED":
      case 9:
        message.fillInType = 9;
        break;
      case "COUNTRY":
      case 10:
        message.fillInType = 10;
        break;
      case "POSTAL_CODE":
      case 11:
        message.fillInType = 11;
        break;
      case "NAME":
      case 12:
        message.fillInType = 12;
        break;
      case "ORGANIZATION_NAME":
      case 13:
        message.fillInType = 13;
        break;
      case "STREET_ADDRESS":
      case 14:
        message.fillInType = 14;
        break;
      case "BIRTH_DATE":
      case 15:
        message.fillInType = 15;
        break;
      case "JOB_POST":
      case 16:
        message.fillInType = 16;
        break;
      case "PAYMENT_ACCOUNT":
      case 17:
        message.fillInType = 17;
        break;
      case "FILE_UPLOAD":
      case 18:
        message.fillInType = 18;
        break;
    }
    if (object.fillInRestriction != null) {
      if (typeof object.fillInRestriction !== "object")
        throw TypeError(".Question.fillInRestriction: object expected");
      message.fillInRestriction = $root.FillInBlankInputRestriction.fromObject(
        object.fillInRestriction
      );
    }
    return message;
  };

  /**
   * Creates a plain object from a Question message. Also converts values to other types if specified.
   * @function toObject
   * @memberof Question
   * @static
   * @param {Question} message Question
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  Question.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.arrays || options.defaults) object.options = [];
    if (options.defaults) {
      object.isRecordAttribute = false;
      object.type = options.enums === String ? "FILL_IN_BLANK" : 0;
      object.fillInType = options.enums === String ? "NUMBER" : 0;
      object.fillInRestriction = null;
    }
    if (
      message.isRecordAttribute != null &&
      message.hasOwnProperty("isRecordAttribute")
    )
      object.isRecordAttribute = message.isRecordAttribute;
    if (message.type != null && message.hasOwnProperty("type"))
      object.type =
        options.enums === String
          ? $root.QuestionType[message.type]
          : message.type;
    if (message.options && message.options.length) {
      object.options = [];
      for (let j = 0; j < message.options.length; ++j)
        object.options[j] = $root.AnswerOption.toObject(
          message.options[j],
          options
        );
    }
    if (message.fillInType != null && message.hasOwnProperty("fillInType"))
      object.fillInType =
        options.enums === String
          ? $root.FillInBlankType[message.fillInType]
          : message.fillInType;
    if (
      message.fillInRestriction != null &&
      message.hasOwnProperty("fillInRestriction")
    )
      object.fillInRestriction = $root.FillInBlankInputRestriction.toObject(
        message.fillInRestriction,
        options
      );
    return object;
  };

  /**
   * Converts this Question to JSON.
   * @function toJSON
   * @memberof Question
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  Question.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return Question;
})());

export const Section = ($root.Section = (() => {
  /**
   * Properties of a Section.
   * @exports ISection
   * @interface ISection
   */

  /**
   * Constructs a new Section.
   * @exports Section
   * @classdesc Represents a Section.
   * @implements ISection
   * @constructor
   * @param {ISection=} [properties] Properties to set
   */
  function Section(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * Encodes the specified Section message. Does not implicitly {@link Section.verify|verify} messages.
   * @function encode
   * @memberof Section
   * @static
   * @param {ISection} message Section message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Section.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    return writer;
  };

  /**
   * Encodes the specified Section message, length delimited. Does not implicitly {@link Section.verify|verify} messages.
   * @function encodeDelimited
   * @memberof Section
   * @static
   * @param {ISection} message Section message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Section.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a Section message from the specified reader or buffer.
   * @function decode
   * @memberof Section
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {Section} Section
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Section.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.Section();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a Section message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof Section
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {Section} Section
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Section.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a Section message.
   * @function verify
   * @memberof Section
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  Section.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    return null;
  };

  /**
   * Creates a Section message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof Section
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {Section} Section
   */
  Section.fromObject = function fromObject(object) {
    if (object instanceof $root.Section) return object;
    return new $root.Section();
  };

  /**
   * Creates a plain object from a Section message. Also converts values to other types if specified.
   * @function toObject
   * @memberof Section
   * @static
   * @param {Section} message Section
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  Section.toObject = function toObject() {
    return {};
  };

  /**
   * Converts this Section to JSON.
   * @function toJSON
   * @memberof Section
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  Section.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return Section;
})());

export const Node = ($root.Node = (() => {
  /**
   * Properties of a Node.
   * @exports INode
   * @interface INode
   * @property {number|null} [id] Node id
   * @property {boolean|null} [optional] Node optional
   * @property {string|null} [shortName] Node shortName
   * @property {string|null} [textToDisplay] Node textToDisplay
   * @property {IQuestion|null} [question] Node question
   * @property {ISection|null} [section] Node section
   * @property {Array.<INode>|null} [children] Node children
   * @property {INote|null} [note] Node note
   */

  /**
   * Constructs a new Node.
   * @exports Node
   * @classdesc Represents a Node.
   * @implements INode
   * @constructor
   * @param {INode=} [properties] Properties to set
   */
  function Node(properties) {
    this.children = [];
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * Node id.
   * @member {number} id
   * @memberof Node
   * @instance
   */
  Node.prototype.id = 0;

  /**
   * Node optional.
   * @member {boolean} optional
   * @memberof Node
   * @instance
   */
  Node.prototype.optional = false;

  /**
   * Node shortName.
   * @member {string} shortName
   * @memberof Node
   * @instance
   */
  Node.prototype.shortName = "";

  /**
   * Node textToDisplay.
   * @member {string} textToDisplay
   * @memberof Node
   * @instance
   */
  Node.prototype.textToDisplay = "";

  /**
   * Node question.
   * @member {IQuestion|null|undefined} question
   * @memberof Node
   * @instance
   */
  Node.prototype.question = null;

  /**
   * Node section.
   * @member {ISection|null|undefined} section
   * @memberof Node
   * @instance
   */
  Node.prototype.section = null;

  /**
   * Node children.
   * @member {Array.<INode>} children
   * @memberof Node
   * @instance
   */
  Node.prototype.children = $util.emptyArray;

  /**
   * Node note.
   * @member {INote|null|undefined} note
   * @memberof Node
   * @instance
   */
  Node.prototype.note = null;

  // OneOf field names bound to virtual getters and setters
  let $oneOfFields;

  /**
   * Node nodeType.
   * @member {"question"|"section"|undefined} nodeType
   * @memberof Node
   * @instance
   */
  Object.defineProperty(Node.prototype, "nodeType", {
    get: $util.oneOfGetter(($oneOfFields = ["question", "section"])),
    set: $util.oneOfSetter($oneOfFields),
  });

  /**
   * Encodes the specified Node message. Does not implicitly {@link Node.verify|verify} messages.
   * @function encode
   * @memberof Node
   * @static
   * @param {INode} message Node message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Node.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
    if (
      message.optional != null &&
      Object.hasOwnProperty.call(message, "optional")
    )
      writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.optional);
    if (
      message.shortName != null &&
      Object.hasOwnProperty.call(message, "shortName")
    )
      writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.shortName);
    if (
      message.textToDisplay != null &&
      Object.hasOwnProperty.call(message, "textToDisplay")
    )
      writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.textToDisplay);
    if (
      message.question != null &&
      Object.hasOwnProperty.call(message, "question")
    )
      $root.Question.encode(
        message.question,
        writer.uint32(/* id 5, wireType 2 =*/ 42).fork()
      ).ldelim();
    if (
      message.section != null &&
      Object.hasOwnProperty.call(message, "section")
    )
      $root.Section.encode(
        message.section,
        writer.uint32(/* id 6, wireType 2 =*/ 50).fork()
      ).ldelim();
    if (message.children != null && message.children.length)
      for (let i = 0; i < message.children.length; ++i)
        $root.Node.encode(
          message.children[i],
          writer.uint32(/* id 7, wireType 2 =*/ 58).fork()
        ).ldelim();
    if (message.note != null && Object.hasOwnProperty.call(message, "note"))
      $root.Note.encode(
        message.note,
        writer.uint32(/* id 8, wireType 2 =*/ 66).fork()
      ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified Node message, length delimited. Does not implicitly {@link Node.verify|verify} messages.
   * @function encodeDelimited
   * @memberof Node
   * @static
   * @param {INode} message Node message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Node.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a Node message from the specified reader or buffer.
   * @function decode
   * @memberof Node
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {Node} Node
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Node.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.Node();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.int32();
          break;
        case 2:
          message.optional = reader.bool();
          break;
        case 3:
          message.shortName = reader.string();
          break;
        case 4:
          message.textToDisplay = reader.string();
          break;
        case 5:
          message.question = $root.Question.decode(reader, reader.uint32());
          break;
        case 6:
          message.section = $root.Section.decode(reader, reader.uint32());
          break;
        case 7:
          if (!(message.children && message.children.length))
            message.children = [];
          message.children.push($root.Node.decode(reader, reader.uint32()));
          break;
        case 8:
          message.note = $root.Note.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a Node message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof Node
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {Node} Node
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Node.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a Node message.
   * @function verify
   * @memberof Node
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  Node.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    let properties = {};
    if (message.id != null && message.hasOwnProperty("id"))
      if (!$util.isInteger(message.id)) return "id: integer expected";
    if (message.optional != null && message.hasOwnProperty("optional"))
      if (typeof message.optional !== "boolean")
        return "optional: boolean expected";
    if (message.shortName != null && message.hasOwnProperty("shortName"))
      if (!$util.isString(message.shortName))
        return "shortName: string expected";
    if (
      message.textToDisplay != null &&
      message.hasOwnProperty("textToDisplay")
    )
      if (!$util.isString(message.textToDisplay))
        return "textToDisplay: string expected";
    if (message.question != null && message.hasOwnProperty("question")) {
      properties.nodeType = 1;
      {
        let error = $root.Question.verify(message.question);
        if (error) return "question." + error;
      }
    }
    if (message.section != null && message.hasOwnProperty("section")) {
      if (properties.nodeType === 1) return "nodeType: multiple values";
      properties.nodeType = 1;
      {
        let error = $root.Section.verify(message.section);
        if (error) return "section." + error;
      }
    }
    if (message.children != null && message.hasOwnProperty("children")) {
      if (!Array.isArray(message.children)) return "children: array expected";
      for (let i = 0; i < message.children.length; ++i) {
        let error = $root.Node.verify(message.children[i]);
        if (error) return "children." + error;
      }
    }
    if (message.note != null && message.hasOwnProperty("note")) {
      let error = $root.Note.verify(message.note);
      if (error) return "note." + error;
    }
    return null;
  };

  /**
   * Creates a Node message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof Node
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {Node} Node
   */
  Node.fromObject = function fromObject(object) {
    if (object instanceof $root.Node) return object;
    let message = new $root.Node();
    if (object.id != null) message.id = object.id | 0;
    if (object.optional != null) message.optional = Boolean(object.optional);
    if (object.shortName != null) message.shortName = String(object.shortName);
    if (object.textToDisplay != null)
      message.textToDisplay = String(object.textToDisplay);
    if (object.question != null) {
      if (typeof object.question !== "object")
        throw TypeError(".Node.question: object expected");
      message.question = $root.Question.fromObject(object.question);
    }
    if (object.section != null) {
      if (typeof object.section !== "object")
        throw TypeError(".Node.section: object expected");
      message.section = $root.Section.fromObject(object.section);
    }
    if (object.children) {
      if (!Array.isArray(object.children))
        throw TypeError(".Node.children: array expected");
      message.children = [];
      for (let i = 0; i < object.children.length; ++i) {
        if (typeof object.children[i] !== "object")
          throw TypeError(".Node.children: object expected");
        message.children[i] = $root.Node.fromObject(object.children[i]);
      }
    }
    if (object.note != null) {
      if (typeof object.note !== "object")
        throw TypeError(".Node.note: object expected");
      message.note = $root.Note.fromObject(object.note);
    }
    return message;
  };

  /**
   * Creates a plain object from a Node message. Also converts values to other types if specified.
   * @function toObject
   * @memberof Node
   * @static
   * @param {Node} message Node
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  Node.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.arrays || options.defaults) object.children = [];
    if (options.defaults) {
      object.id = 0;
      object.optional = false;
      object.shortName = "";
      object.textToDisplay = "";
      object.note = null;
    }
    if (message.id != null && message.hasOwnProperty("id"))
      object.id = message.id;
    if (message.optional != null && message.hasOwnProperty("optional"))
      object.optional = message.optional;
    if (message.shortName != null && message.hasOwnProperty("shortName"))
      object.shortName = message.shortName;
    if (
      message.textToDisplay != null &&
      message.hasOwnProperty("textToDisplay")
    )
      object.textToDisplay = message.textToDisplay;
    if (message.question != null && message.hasOwnProperty("question")) {
      object.question = $root.Question.toObject(message.question, options);
      if (options.oneofs) object.nodeType = "question";
    }
    if (message.section != null && message.hasOwnProperty("section")) {
      object.section = $root.Section.toObject(message.section, options);
      if (options.oneofs) object.nodeType = "section";
    }
    if (message.children && message.children.length) {
      object.children = [];
      for (let j = 0; j < message.children.length; ++j)
        object.children[j] = $root.Node.toObject(message.children[j], options);
    }
    if (message.note != null && message.hasOwnProperty("note"))
      object.note = $root.Note.toObject(message.note, options);
    return object;
  };

  /**
   * Converts this Node to JSON.
   * @function toJSON
   * @memberof Node
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  Node.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return Node;
})());

export const Form = ($root.Form = (() => {
  /**
   * Properties of a Form.
   * @exports IForm
   * @interface IForm
   * @property {string|null} [guid] Form guid
   * @property {INode|null} [node] Form node
   * @property {Array.<IGroupCondition>|null} [groupConditions] Form groupConditions
   */

  /**
   * Constructs a new Form.
   * @exports Form
   * @classdesc Represents a Form.
   * @implements IForm
   * @constructor
   * @param {IForm=} [properties] Properties to set
   */
  function Form(properties) {
    this.groupConditions = [];
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * Form guid.
   * @member {string} guid
   * @memberof Form
   * @instance
   */
  Form.prototype.guid = "";

  /**
   * Form node.
   * @member {INode|null|undefined} node
   * @memberof Form
   * @instance
   */
  Form.prototype.node = null;

  /**
   * Form groupConditions.
   * @member {Array.<IGroupCondition>} groupConditions
   * @memberof Form
   * @instance
   */
  Form.prototype.groupConditions = $util.emptyArray;

  /**
   * Encodes the specified Form message. Does not implicitly {@link Form.verify|verify} messages.
   * @function encode
   * @memberof Form
   * @static
   * @param {IForm} message Form message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Form.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.guid);
    if (message.node != null && Object.hasOwnProperty.call(message, "node"))
      $root.Node.encode(
        message.node,
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
      ).ldelim();
    if (message.groupConditions != null && message.groupConditions.length)
      for (let i = 0; i < message.groupConditions.length; ++i)
        $root.GroupCondition.encode(
          message.groupConditions[i],
          writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified Form message, length delimited. Does not implicitly {@link Form.verify|verify} messages.
   * @function encodeDelimited
   * @memberof Form
   * @static
   * @param {IForm} message Form message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  Form.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a Form message from the specified reader or buffer.
   * @function decode
   * @memberof Form
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {Form} Form
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Form.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.Form();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.guid = reader.string();
          break;
        case 2:
          message.node = $root.Node.decode(reader, reader.uint32());
          break;
        case 4:
          if (!(message.groupConditions && message.groupConditions.length))
            message.groupConditions = [];
          message.groupConditions.push(
            $root.GroupCondition.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a Form message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof Form
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {Form} Form
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  Form.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a Form message.
   * @function verify
   * @memberof Form
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  Form.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.guid != null && message.hasOwnProperty("guid"))
      if (!$util.isString(message.guid)) return "guid: string expected";
    if (message.node != null && message.hasOwnProperty("node")) {
      let error = $root.Node.verify(message.node);
      if (error) return "node." + error;
    }
    if (
      message.groupConditions != null &&
      message.hasOwnProperty("groupConditions")
    ) {
      if (!Array.isArray(message.groupConditions))
        return "groupConditions: array expected";
      for (let i = 0; i < message.groupConditions.length; ++i) {
        let error = $root.GroupCondition.verify(message.groupConditions[i]);
        if (error) return "groupConditions." + error;
      }
    }
    return null;
  };

  /**
   * Creates a Form message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof Form
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {Form} Form
   */
  Form.fromObject = function fromObject(object) {
    if (object instanceof $root.Form) return object;
    let message = new $root.Form();
    if (object.guid != null) message.guid = String(object.guid);
    if (object.node != null) {
      if (typeof object.node !== "object")
        throw TypeError(".Form.node: object expected");
      message.node = $root.Node.fromObject(object.node);
    }
    if (object.groupConditions) {
      if (!Array.isArray(object.groupConditions))
        throw TypeError(".Form.groupConditions: array expected");
      message.groupConditions = [];
      for (let i = 0; i < object.groupConditions.length; ++i) {
        if (typeof object.groupConditions[i] !== "object")
          throw TypeError(".Form.groupConditions: object expected");
        message.groupConditions[i] = $root.GroupCondition.fromObject(
          object.groupConditions[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a Form message. Also converts values to other types if specified.
   * @function toObject
   * @memberof Form
   * @static
   * @param {Form} message Form
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  Form.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.arrays || options.defaults) object.groupConditions = [];
    if (options.defaults) {
      object.guid = "";
      object.node = null;
    }
    if (message.guid != null && message.hasOwnProperty("guid"))
      object.guid = message.guid;
    if (message.node != null && message.hasOwnProperty("node"))
      object.node = $root.Node.toObject(message.node, options);
    if (message.groupConditions && message.groupConditions.length) {
      object.groupConditions = [];
      for (let j = 0; j < message.groupConditions.length; ++j)
        object.groupConditions[j] = $root.GroupCondition.toObject(
          message.groupConditions[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this Form to JSON.
   * @function toJSON
   * @memberof Form
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  Form.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return Form;
})());

export const AnswerGiven = ($root.AnswerGiven = (() => {
  /**
   * Properties of an AnswerGiven.
   * @exports IAnswerGiven
   * @interface IAnswerGiven
   * @property {number|null} [questionNodeId] AnswerGiven questionNodeId
   * @property {Array.<IAnswerValue>|null} [answerValues] AnswerGiven answerValues
   */

  /**
   * Constructs a new AnswerGiven.
   * @exports AnswerGiven
   * @classdesc Represents an AnswerGiven.
   * @implements IAnswerGiven
   * @constructor
   * @param {IAnswerGiven=} [properties] Properties to set
   */
  function AnswerGiven(properties) {
    this.answerValues = [];
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * AnswerGiven questionNodeId.
   * @member {number} questionNodeId
   * @memberof AnswerGiven
   * @instance
   */
  AnswerGiven.prototype.questionNodeId = 0;

  /**
   * AnswerGiven answerValues.
   * @member {Array.<IAnswerValue>} answerValues
   * @memberof AnswerGiven
   * @instance
   */
  AnswerGiven.prototype.answerValues = $util.emptyArray;

  /**
   * Encodes the specified AnswerGiven message. Does not implicitly {@link AnswerGiven.verify|verify} messages.
   * @function encode
   * @memberof AnswerGiven
   * @static
   * @param {IAnswerGiven} message AnswerGiven message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  AnswerGiven.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.questionNodeId != null &&
      Object.hasOwnProperty.call(message, "questionNodeId")
    )
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.questionNodeId);
    if (message.answerValues != null && message.answerValues.length)
      for (let i = 0; i < message.answerValues.length; ++i)
        $root.AnswerValue.encode(
          message.answerValues[i],
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified AnswerGiven message, length delimited. Does not implicitly {@link AnswerGiven.verify|verify} messages.
   * @function encodeDelimited
   * @memberof AnswerGiven
   * @static
   * @param {IAnswerGiven} message AnswerGiven message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  AnswerGiven.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes an AnswerGiven message from the specified reader or buffer.
   * @function decode
   * @memberof AnswerGiven
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {AnswerGiven} AnswerGiven
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  AnswerGiven.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.AnswerGiven();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.questionNodeId = reader.int32();
          break;
        case 3:
          if (!(message.answerValues && message.answerValues.length))
            message.answerValues = [];
          message.answerValues.push(
            $root.AnswerValue.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes an AnswerGiven message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof AnswerGiven
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {AnswerGiven} AnswerGiven
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  AnswerGiven.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies an AnswerGiven message.
   * @function verify
   * @memberof AnswerGiven
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  AnswerGiven.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (
      message.questionNodeId != null &&
      message.hasOwnProperty("questionNodeId")
    )
      if (!$util.isInteger(message.questionNodeId))
        return "questionNodeId: integer expected";
    if (
      message.answerValues != null &&
      message.hasOwnProperty("answerValues")
    ) {
      if (!Array.isArray(message.answerValues))
        return "answerValues: array expected";
      for (let i = 0; i < message.answerValues.length; ++i) {
        let error = $root.AnswerValue.verify(message.answerValues[i]);
        if (error) return "answerValues." + error;
      }
    }
    return null;
  };

  /**
   * Creates an AnswerGiven message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof AnswerGiven
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {AnswerGiven} AnswerGiven
   */
  AnswerGiven.fromObject = function fromObject(object) {
    if (object instanceof $root.AnswerGiven) return object;
    let message = new $root.AnswerGiven();
    if (object.questionNodeId != null)
      message.questionNodeId = object.questionNodeId | 0;
    if (object.answerValues) {
      if (!Array.isArray(object.answerValues))
        throw TypeError(".AnswerGiven.answerValues: array expected");
      message.answerValues = [];
      for (let i = 0; i < object.answerValues.length; ++i) {
        if (typeof object.answerValues[i] !== "object")
          throw TypeError(".AnswerGiven.answerValues: object expected");
        message.answerValues[i] = $root.AnswerValue.fromObject(
          object.answerValues[i]
        );
      }
    }
    return message;
  };

  /**
   * Creates a plain object from an AnswerGiven message. Also converts values to other types if specified.
   * @function toObject
   * @memberof AnswerGiven
   * @static
   * @param {AnswerGiven} message AnswerGiven
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  AnswerGiven.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.arrays || options.defaults) object.answerValues = [];
    if (options.defaults) object.questionNodeId = 0;
    if (
      message.questionNodeId != null &&
      message.hasOwnProperty("questionNodeId")
    )
      object.questionNodeId = message.questionNodeId;
    if (message.answerValues && message.answerValues.length) {
      object.answerValues = [];
      for (let j = 0; j < message.answerValues.length; ++j)
        object.answerValues[j] = $root.AnswerValue.toObject(
          message.answerValues[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this AnswerGiven to JSON.
   * @function toJSON
   * @memberof AnswerGiven
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  AnswerGiven.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return AnswerGiven;
})());

export const FilledForm = ($root.FilledForm = (() => {
  /**
   * Properties of a FilledForm.
   * @exports IFilledForm
   * @interface IFilledForm
   * @property {string|null} [guid] FilledForm guid
   * @property {string|null} [guidForm] FilledForm guidForm
   * @property {number|null} [formVersion] FilledForm formVersion
   * @property {Array.<IAnswerGiven>|null} [answers] FilledForm answers
   */

  /**
   * Constructs a new FilledForm.
   * @exports FilledForm
   * @classdesc Represents a FilledForm.
   * @implements IFilledForm
   * @constructor
   * @param {IFilledForm=} [properties] Properties to set
   */
  function FilledForm(properties) {
    this.answers = [];
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * FilledForm guid.
   * @member {string} guid
   * @memberof FilledForm
   * @instance
   */
  FilledForm.prototype.guid = "";

  /**
   * FilledForm guidForm.
   * @member {string} guidForm
   * @memberof FilledForm
   * @instance
   */
  FilledForm.prototype.guidForm = "";

  /**
   * FilledForm formVersion.
   * @member {number} formVersion
   * @memberof FilledForm
   * @instance
   */
  FilledForm.prototype.formVersion = 0;

  /**
   * FilledForm answers.
   * @member {Array.<IAnswerGiven>} answers
   * @memberof FilledForm
   * @instance
   */
  FilledForm.prototype.answers = $util.emptyArray;

  /**
   * Encodes the specified FilledForm message. Does not implicitly {@link FilledForm.verify|verify} messages.
   * @function encode
   * @memberof FilledForm
   * @static
   * @param {IFilledForm} message FilledForm message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  FilledForm.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.guid != null && Object.hasOwnProperty.call(message, "guid"))
      writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.guid);
    if (
      message.guidForm != null &&
      Object.hasOwnProperty.call(message, "guidForm")
    )
      writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.guidForm);
    if (
      message.formVersion != null &&
      Object.hasOwnProperty.call(message, "formVersion")
    )
      writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.formVersion);
    if (message.answers != null && message.answers.length)
      for (let i = 0; i < message.answers.length; ++i)
        $root.AnswerGiven.encode(
          message.answers[i],
          writer.uint32(/* id 4, wireType 2 =*/ 34).fork()
        ).ldelim();
    return writer;
  };

  /**
   * Encodes the specified FilledForm message, length delimited. Does not implicitly {@link FilledForm.verify|verify} messages.
   * @function encodeDelimited
   * @memberof FilledForm
   * @static
   * @param {IFilledForm} message FilledForm message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  FilledForm.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a FilledForm message from the specified reader or buffer.
   * @function decode
   * @memberof FilledForm
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {FilledForm} FilledForm
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  FilledForm.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.FilledForm();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.guid = reader.string();
          break;
        case 2:
          message.guidForm = reader.string();
          break;
        case 3:
          message.formVersion = reader.int32();
          break;
        case 4:
          if (!(message.answers && message.answers.length))
            message.answers = [];
          message.answers.push(
            $root.AnswerGiven.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a FilledForm message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof FilledForm
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {FilledForm} FilledForm
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  FilledForm.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a FilledForm message.
   * @function verify
   * @memberof FilledForm
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  FilledForm.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.guid != null && message.hasOwnProperty("guid"))
      if (!$util.isString(message.guid)) return "guid: string expected";
    if (message.guidForm != null && message.hasOwnProperty("guidForm"))
      if (!$util.isString(message.guidForm)) return "guidForm: string expected";
    if (message.formVersion != null && message.hasOwnProperty("formVersion"))
      if (!$util.isInteger(message.formVersion))
        return "formVersion: integer expected";
    if (message.answers != null && message.hasOwnProperty("answers")) {
      if (!Array.isArray(message.answers)) return "answers: array expected";
      for (let i = 0; i < message.answers.length; ++i) {
        let error = $root.AnswerGiven.verify(message.answers[i]);
        if (error) return "answers." + error;
      }
    }
    return null;
  };

  /**
   * Creates a FilledForm message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof FilledForm
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {FilledForm} FilledForm
   */
  FilledForm.fromObject = function fromObject(object) {
    if (object instanceof $root.FilledForm) return object;
    let message = new $root.FilledForm();
    if (object.guid != null) message.guid = String(object.guid);
    if (object.guidForm != null) message.guidForm = String(object.guidForm);
    if (object.formVersion != null)
      message.formVersion = object.formVersion | 0;
    if (object.answers) {
      if (!Array.isArray(object.answers))
        throw TypeError(".FilledForm.answers: array expected");
      message.answers = [];
      for (let i = 0; i < object.answers.length; ++i) {
        if (typeof object.answers[i] !== "object")
          throw TypeError(".FilledForm.answers: object expected");
        message.answers[i] = $root.AnswerGiven.fromObject(object.answers[i]);
      }
    }
    return message;
  };

  /**
   * Creates a plain object from a FilledForm message. Also converts values to other types if specified.
   * @function toObject
   * @memberof FilledForm
   * @static
   * @param {FilledForm} message FilledForm
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  FilledForm.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.arrays || options.defaults) object.answers = [];
    if (options.defaults) {
      object.guid = "";
      object.guidForm = "";
      object.formVersion = 0;
    }
    if (message.guid != null && message.hasOwnProperty("guid"))
      object.guid = message.guid;
    if (message.guidForm != null && message.hasOwnProperty("guidForm"))
      object.guidForm = message.guidForm;
    if (message.formVersion != null && message.hasOwnProperty("formVersion"))
      object.formVersion = message.formVersion;
    if (message.answers && message.answers.length) {
      object.answers = [];
      for (let j = 0; j < message.answers.length; ++j)
        object.answers[j] = $root.AnswerGiven.toObject(
          message.answers[j],
          options
        );
    }
    return object;
  };

  /**
   * Converts this FilledForm to JSON.
   * @function toJSON
   * @memberof FilledForm
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  FilledForm.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return FilledForm;
})());

export const FormPresentationStyle = ($root.FormPresentationStyle = (() => {
  /**
   * Properties of a FormPresentationStyle.
   * @exports IFormPresentationStyle
   * @interface IFormPresentationStyle
   * @property {number|null} [sectionStyle] FormPresentationStyle sectionStyle
   */

  /**
   * Constructs a new FormPresentationStyle.
   * @exports FormPresentationStyle
   * @classdesc Represents a FormPresentationStyle.
   * @implements IFormPresentationStyle
   * @constructor
   * @param {IFormPresentationStyle=} [properties] Properties to set
   */
  function FormPresentationStyle(properties) {
    if (properties)
      for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * FormPresentationStyle sectionStyle.
   * @member {number} sectionStyle
   * @memberof FormPresentationStyle
   * @instance
   */
  FormPresentationStyle.prototype.sectionStyle = 0;

  /**
   * Encodes the specified FormPresentationStyle message. Does not implicitly {@link FormPresentationStyle.verify|verify} messages.
   * @function encode
   * @memberof FormPresentationStyle
   * @static
   * @param {IFormPresentationStyle} message FormPresentationStyle message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  FormPresentationStyle.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (
      message.sectionStyle != null &&
      Object.hasOwnProperty.call(message, "sectionStyle")
    )
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.sectionStyle);
    return writer;
  };

  /**
   * Encodes the specified FormPresentationStyle message, length delimited. Does not implicitly {@link FormPresentationStyle.verify|verify} messages.
   * @function encodeDelimited
   * @memberof FormPresentationStyle
   * @static
   * @param {IFormPresentationStyle} message FormPresentationStyle message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  FormPresentationStyle.encodeDelimited = function encodeDelimited(
    message,
    writer
  ) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes a FormPresentationStyle message from the specified reader or buffer.
   * @function decode
   * @memberof FormPresentationStyle
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {FormPresentationStyle} FormPresentationStyle
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  FormPresentationStyle.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    let end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.FormPresentationStyle();
    while (reader.pos < end) {
      let tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sectionStyle = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes a FormPresentationStyle message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof FormPresentationStyle
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {FormPresentationStyle} FormPresentationStyle
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  FormPresentationStyle.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies a FormPresentationStyle message.
   * @function verify
   * @memberof FormPresentationStyle
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  FormPresentationStyle.verify = function verify(message) {
    if (typeof message !== "object" || message === null)
      return "object expected";
    if (message.sectionStyle != null && message.hasOwnProperty("sectionStyle"))
      if (!$util.isInteger(message.sectionStyle))
        return "sectionStyle: integer expected";
    return null;
  };

  /**
   * Creates a FormPresentationStyle message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof FormPresentationStyle
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {FormPresentationStyle} FormPresentationStyle
   */
  FormPresentationStyle.fromObject = function fromObject(object) {
    if (object instanceof $root.FormPresentationStyle) return object;
    let message = new $root.FormPresentationStyle();
    if (object.sectionStyle != null)
      message.sectionStyle = object.sectionStyle | 0;
    return message;
  };

  /**
   * Creates a plain object from a FormPresentationStyle message. Also converts values to other types if specified.
   * @function toObject
   * @memberof FormPresentationStyle
   * @static
   * @param {FormPresentationStyle} message FormPresentationStyle
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  FormPresentationStyle.toObject = function toObject(message, options) {
    if (!options) options = {};
    let object = {};
    if (options.defaults) object.sectionStyle = 0;
    if (message.sectionStyle != null && message.hasOwnProperty("sectionStyle"))
      object.sectionStyle = message.sectionStyle;
    return object;
  };

  /**
   * Converts this FormPresentationStyle to JSON.
   * @function toJSON
   * @memberof FormPresentationStyle
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  FormPresentationStyle.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return FormPresentationStyle;
})());

/**
 * AnswerRequired enum.
 * @exports AnswerRequired
 * @enum {number}
 * @property {number} REQUIRED=0 REQUIRED value
 * @property {number} OPTIONAL=1 OPTIONAL value
 * @property {number} NOT_APPLICABLE=2 NOT_APPLICABLE value
 */
$root.AnswerRequired = (function () {
  const valuesById = {},
    values = Object.create(valuesById);
  values[(valuesById[0] = "REQUIRED")] = 0;
  values[(valuesById[1] = "OPTIONAL")] = 1;
  values[(valuesById[2] = "NOT_APPLICABLE")] = 2;
  return values;
})();

/**
 * QuestionType enum.
 * @exports QuestionType
 * @enum {number}
 * @property {number} FILL_IN_BLANK=0 FILL_IN_BLANK value
 * @property {number} MULTI_CHOICE=1 MULTI_CHOICE value
 * @property {number} YES_NO=2 YES_NO value
 * @property {number} MULTI_SELECT=3 MULTI_SELECT value
 */
$root.QuestionType = (function () {
  const valuesById = {},
    values = Object.create(valuesById);
  values[(valuesById[0] = "FILL_IN_BLANK")] = 0;
  values[(valuesById[1] = "MULTI_CHOICE")] = 1;
  values[(valuesById[2] = "YES_NO")] = 2;
  values[(valuesById[3] = "MULTI_SELECT")] = 3;
  return values;
})();

/**
 * FillInBlankType enum.
 * @exports FillInBlankType
 * @enum {number}
 * @property {number} NUMBER=0 NUMBER value
 * @property {number} DECIMAL=1 DECIMAL value
 * @property {number} TEXT=2 TEXT value
 * @property {number} EMAIL=3 EMAIL value
 * @property {number} DATE=4 DATE value
 * @property {number} TIME=5 TIME value
 * @property {number} URL=6 URL value
 * @property {number} PHONE=7 PHONE value
 * @property {number} PHONE_VERIFIED=8 PHONE_VERIFIED value
 * @property {number} EMAIL_VERIFIED=9 EMAIL_VERIFIED value
 * @property {number} COUNTRY=10 COUNTRY value
 * @property {number} POSTAL_CODE=11 POSTAL_CODE value
 * @property {number} NAME=12 NAME value
 * @property {number} ORGANIZATION_NAME=13 ORGANIZATION_NAME value
 * @property {number} STREET_ADDRESS=14 STREET_ADDRESS value
 * @property {number} BIRTH_DATE=15 BIRTH_DATE value
 * @property {number} JOB_POST=16 JOB_POST value
 * @property {number} PAYMENT_ACCOUNT=17 PAYMENT_ACCOUNT value
 * @property {number} FILE_UPLOAD=18 FILE_UPLOAD value
 */
$root.FillInBlankType = (function () {
  const valuesById = {},
    values = Object.create(valuesById);
  values[(valuesById[0] = "NUMBER")] = 0;
  values[(valuesById[1] = "DECIMAL")] = 1;
  values[(valuesById[2] = "TEXT")] = 2;
  values[(valuesById[3] = "EMAIL")] = 3;
  values[(valuesById[4] = "DATE")] = 4;
  values[(valuesById[5] = "TIME")] = 5;
  values[(valuesById[6] = "URL")] = 6;
  values[(valuesById[7] = "PHONE")] = 7;
  values[(valuesById[8] = "PHONE_VERIFIED")] = 8;
  values[(valuesById[9] = "EMAIL_VERIFIED")] = 9;
  values[(valuesById[10] = "COUNTRY")] = 10;
  values[(valuesById[11] = "POSTAL_CODE")] = 11;
  values[(valuesById[12] = "NAME")] = 12;
  values[(valuesById[13] = "ORGANIZATION_NAME")] = 13;
  values[(valuesById[14] = "STREET_ADDRESS")] = 14;
  values[(valuesById[15] = "BIRTH_DATE")] = 15;
  values[(valuesById[16] = "JOB_POST")] = 16;
  values[(valuesById[17] = "PAYMENT_ACCOUNT")] = 17;
  values[(valuesById[18] = "FILE_UPLOAD")] = 18;
  return values;
})();

/**
 * DecisionType enum.
 * @exports DecisionType
 * @enum {number}
 * @property {number} MAKE_OPTIONAL=0 MAKE_OPTIONAL value
 * @property {number} HIDE=1 HIDE value
 */
$root.DecisionType = (function () {
  const valuesById = {},
    values = Object.create(valuesById);
  values[(valuesById[0] = "MAKE_OPTIONAL")] = 0;
  values[(valuesById[1] = "HIDE")] = 1;
  return values;
})();

/**
 * ConditionType enum.
 * @exports ConditionType
 * @enum {number}
 * @property {number} EQ=0 EQ value
 * @property {number} GE=1 GE value
 * @property {number} GTR=2 GTR value
 * @property {number} LE=3 LE value
 * @property {number} LESS=4 LESS value
 * @property {number} NEQ=5 NEQ value
 */
$root.ConditionType = (function () {
  const valuesById = {},
    values = Object.create(valuesById);
  values[(valuesById[0] = "EQ")] = 0;
  values[(valuesById[1] = "GE")] = 1;
  values[(valuesById[2] = "GTR")] = 2;
  values[(valuesById[3] = "LE")] = 3;
  values[(valuesById[4] = "LESS")] = 4;
  values[(valuesById[5] = "NEQ")] = 5;
  return values;
})();

export { $root as default };
