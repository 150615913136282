import React from "react";

import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Order } from "./generic_table";

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export interface HeadCellType<Data> {
  // for the first element in row, we disable padding
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  // should this data be right aligned. numeric data usually need to be right aligned.
  rightAlign: boolean;
  sortable?: boolean;
  // a function which converts the data element to string
  stringConverter?: (data: any) => string;
}

// creating StyledCheckbox as the usual Checkbox is getting hidden in black table head
const StyledCheckbox = withStyles(() =>
  createStyles({
    root: {
      //backgroundColor: theme.palette.common.white,
      //color: theme.palette.common.black,
    },
  })
)(Checkbox);

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      //backgroundColor: theme.palette.common.white,
      //color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

interface EnhancedTableProps<Data> {
  classes: ReturnType<typeof useStyles>;
  // number of selected rows
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCellType<Data>[];
  addSelectionCheckbox: boolean;
}

export function EnhancedTableHead<Data>(props: EnhancedTableProps<Data>) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    addSelectionCheckbox,
  } = props;

  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {addSelectionCheckbox === false ? null : (
          <StyledTableCell padding="checkbox">
            <StyledCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all" }}
            />
          </StyledTableCell>
        )}
        {props.headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id as string}
            align={headCell.rightAlign ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {headCell.sortable === true ? (
              <TableSortLabel
                color={"primary"}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : null}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
