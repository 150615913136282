import React from "react";
import "./welcome.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { FormattedMessage } from "react-intl";
import "firebase/auth";
import { Typography, Container, Grid } from "@material-ui/core";
import ShareForms from "./share_forms";
import CreateForms from "./create_forms";
import MarketNav from "../../navigation/market_nav";
import GrowBiz from "./grow_biz";
import Contact from "./contact";

// export default function App() {

//   return (
//     <IntlProvider locale={usersLocale} key={usersLocale}>
//       <div className="App">
//       <CssBaseline />
//         <Navigation />
//       </div>
//     </IntlProvider>
//   );
// }
type WelcomePropsType = {};

export default function Welcome() {
  return (
    <div>
      <MarketNav />
      <Container>
        <div className="welcome_group">
          <div className="welcome">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <a href="#ad_create">
                  <Typography variant="h4" color="inherit">
                    <FormattedMessage
                      id="ad.create_forms"
                      defaultMessage="Create Forms"
                    />{" "}
                    ☟
                  </Typography>
                </a>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ad.create_forms_sec"
                    defaultMessage="that meet your need"
                  />
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <a href="#ad_share">
                  <Typography variant="h4">
                    <FormattedMessage
                      id="ad.share_forms"
                      defaultMessage="Share Forms"
                    />{" "}
                    ☟
                  </Typography>
                </a>
                <Typography variant="body2">
                  <FormattedMessage
                    id="ad.share_forms_sec"
                    defaultMessage="for people to fill"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <a href="#ad_biz">
                  <Typography variant="h4">
                    <FormattedMessage
                      id="ad.grow_biz"
                      defaultMessage="Grow Your Business"
                    />
                    {"! "}☟
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
        <div id="ad_create">
          <CreateForms />
        </div>
        <div id="ad_share">
          <ShareForms />
        </div>
        <div id="ad_biz">
          <GrowBiz />
        </div>
        <Contact />
      </Container>
    </div>
  );
}
