import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

/**
 * The action menu contains the actions that can be performed on the selection
 * E.g. view the form, edit the form for single selection, and delete the forms for one/more selections.
 */

export interface MenuItemType {
  // name of menu item to display
  name: string;
  action: (data?: any) => any;
}

export type GenericActionMenuType = React.FC<{
  children: React.ReactNode;
  // data attached, that action will take as input
  data?: any;
}>;
/**
 * Returns the component that when clicked will popup the menu.
 * The returned component takes children and data as input
 */
export function GenericActionMenuCreator(
  // menu items
  menuItems: MenuItemType[]
): GenericActionMenuType {
  return function (props: { children: React.ReactNode; data?: any }) {
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <div {...bindTrigger(popupState)}>{props.children}</div>
            <Menu {...bindMenu(popupState)}>
              {menuItems.map((item) => {
                return (
                  <MenuItem
                    key={item.name}
                    onClick={(ev) => {
                      item.action(props.data);
                      popupState.close();
                    }}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };
}
