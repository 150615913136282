import { Form, FilledForm } from "src/proto/FormServerMessages";
import AppConfiguration from "src/configurations/constants";
import axios, { AxiosResponse } from "axios";
import { FormMetadata, RecordMetadata } from "src/services/datatypes/metadata";
import FormData from "form-data";
import util from "util";

export default class FormService {
  public static async getFormUsingFetch(formGuid: string) {
    try {
      let response = await fetch(
        AppConfiguration.formServerUrl + `/forms/${formGuid}`
      );
      let formBuffer = await response.body?.getReader().read();
      if (formBuffer?.value) {
        return Form.decode(formBuffer?.value);
      } else {
        throw new Error("buffer is undefined");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  /*
		Retrieves a given form
	*/
  public static async getForm(formGuid: string) {
    try {
      let response = await axios.get(
        AppConfiguration.formServerUrl + `/forms/${formGuid}`,
        { responseType: "arraybuffer" }
      );
      let formBuffer = await response.data;
      if (formBuffer) {
        let uint8Buffer: Uint8Array = new Uint8Array(formBuffer);
        console.log("formBuffer size: %d", uint8Buffer.byteLength);
        return Form.decode(uint8Buffer);
      } else {
        throw new Error("buffer is undefined");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  /*
		Creates a new form
	*/
  public static postForm(form: Form, metadata: FormMetadata) {
    const url = AppConfiguration.formServerUrl + "/forms";
    const formData = new FormData();

    // there was behavior difference when sending the "form" part
    //  in nodejs and in browser.
    // the "metadata" part was OK, as it seems it was string only.
    // However, for the "form" part, golang server  ( in its c.PostForm("form") )
    //   was reporting 2 extra bytes when sent from browser, however there was no
    //   problem when sent from the nodejs.
    // The use of buffer with "application/binary" is solving the problem.

    // TODO: clean up and make below code runnable from both browser, and nodejs
    try {
      if (typeof formData.getHeaders === "function") {
        // nodejs environmnet
        const buffer = Buffer.from(Form.encode(form).finish()).toString();
        formData.append("form", buffer);
        console.log(`form bytelength is ${buffer.length}`);
      } else {
        // browser environment
        const buffer = new Blob([Form.encode(form).finish()], {
          type: "application/binary",
        });
        //Buffer.from(Form.encode(form).finish()).toString();
        formData.append("form", buffer);
        console.log(
          `form bytelength is ${buffer.size} ${buffer.arrayBuffer.length}`
          //`form bytelength is ${buffer.length} and ${
          //  formData.get("form")?.toString().length
          //}`
        );
      }
      formData.append("metadata", JSON.stringify(metadata));
    } catch (e) {
      console.error(`error : ${JSON.stringify(e)}`);
    }

    /*
      var request = new XMLHttpRequest();
      request.open("POST", url);
      request.send(formData);
       formData.submit(url, (err, response) => {
        if (err) return reject(err);
        resolve(response);
      });
      */

    const headerData =
      typeof formData.getHeaders === "function"
        ? formData.getHeaders()
        : {
            "Content-Type": "multipart/form-data",
          };

    return new Promise((resolve, reject) => {
      /*const headerData = {
        "Content-Type": "multipart/form-data",
      };*/
      axios
        .post(url, formData, {
          headers: {
            ...headerData,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  /*
		Deletes a form
  */
  public static deleteForm(formGuid: string) {
    return axios.delete(AppConfiguration.formServerUrl + `/forms/${formGuid}`, {
      responseType: "json",
      withCredentials: true,
    });
  }
  /*
		Deletes a form
  */
  public static deleteRecord(recordGuid: string) {
    return axios.delete(
      AppConfiguration.formServerUrl + `/records/${recordGuid}`,
      {
        responseType: "json",
        withCredentials: true,
      }
    );
  }
  /*
		Return form metadata
  */
  public static getFormMetadata(formGuid: string) {
    return axios.get(
      AppConfiguration.formServerUrl + `/metadata/forms/${formGuid}`,
      { withCredentials: true, responseType: "json" }
    );
  }
}
