import { Node, Question, Note, INote } from "src/proto/FormServerMessages";
import { GroupedCondition, NodeDecisionType } from "./decision_model";
import { FormModel } from "./form_model";
import { IFormModel } from "./interface/form_model";
import { INodeModel } from "./interface/node_model";

// message Node {
//   int32 id = 1;
//   bool optional = 2;
//   string short_name = 3;
//   string text_to_display = 4;
//   oneof node_type {
//     Question question = 5;
//     Section section = 6;
//   };
//   repeated Node children = 7;
// }
/**
 * NodeModel is wrapper around the Node
 * TODO: add auxiliary attributes to support the decison
 * that needs to be taken based on current set of answers given
 * or edit done.
 */
export class NodeModel {
  public id: number;

  private impact: NodeDecisionType = NodeDecisionType.NONE;

  children: NodeModel[] = [];

  /**
   *
   * Regarding React component:
   *    creating a new NodeModel object itself is changing the passed FormModel.
   *    so, it is not possible to create a copy of existing NodeModel without
   *    affecting the FormModel.
   *    So, it will not be possible to create a new NodeModel object in React component
   *    which is receiving a NodeModel in its props. It can be only done in top level
   *    Form component.
   * @param form
   * @param containerNode
   * @param node
   */
  constructor(
    public form: IFormModel,
    public containerNode: NodeModel | undefined,
    public node: Node
  ) {
    // if this is a new node
    if (node.id === 0) {
      this.id = form.registerNewNode(this);
      node.id = this.id;
    } else {
      // if this is existing node
      this.id = node.id;
      form.registerExistingNode(this);
    }

    node.children.forEach((child) => {
      let childWrapped = new NodeModel(form, this, child as Node);
      this.children.push(childWrapped);
    });
  }

  /**
   * Returns short name of the node
   */
  public getShortName(): string {
    return this.node.shortName;
  }
  /**
   * Returns the text to display for the node
   */
  public getTextToDisplay(): string {
    return this.node.textToDisplay;
  }
  // note to attach to a question or section
  // howering over the question or section, will display the message.
  public getNote(): INote | null | undefined {
    return this.node.note;
  }
  public setNote(note: Note): void {
    this.node.note = note;
  }
  public getId(): number {
    return this.id;
  }
  public getChildren(): INodeModel[] {
    return this.children;
  }
  public isQuestion(): boolean {
    return this.node.nodeType === "question";
  }
  public isSection(): boolean {
    return this.node.nodeType === "section";
  }
  public isAttribute(): boolean {
    if (this.isQuestion()) {
      return (this.node.question as Question).isRecordAttribute;
    }
    return false;
  }
  public setIsAttribute(value: boolean): void {
    if (this.isQuestion()) {
      (this.node.question as Question).isRecordAttribute = value;
    }
  }
  public setShortName(name: string): void {
    this.node.shortName = name;
  }
  public setTextToDisplay(text: string): void {
    this.node.textToDisplay = text;
  }
  /**
   * Impact: parent node, this node and all children
   */
  public delete() {
    const nodeId = this.id;
    // remove it from the parent node
    if (this.containerNode !== undefined) {
      this.containerNode.unlinkChild(this);
    }
    delete this.containerNode;
    // delete all children
    this.children.forEach((child) => {
      child.delete();
    });
    this.children = [];
    // REVIEW: commenting delete as The operand of a 'delete' operator must be optional.  TS2790
    // check later what should be the correct action to reduce memory usage
    // remove the reference to the objects
    // delete this.node;
    // remove it from integerNodeMap of formModel
    (this.form as FormModel).removeDeletedNodeFromMap(nodeId);
    // delete the reference
    // delete this.form;
  }

  /**
   * removes the specified node as child
   * also deletes the child
   *
   * @param child node to be removed
   *              generates exception if child is not a child
   */
  public removeChild(child: NodeModel) {
    this.unlinkChild(child);
    child.delete();
  }
  /**
   * Returns the distance from root in the node tree
   * @return
   */
  public getLevel(): number {
    let level = 0;
    let parent = this.containerNode;
    while (parent !== undefined) {
      level++;
      parent = parent.containerNode;
    }
    return level;
  }

  /**
   * Return if the node is optional because it
   * or any of its ancestor being optional
   */
  public isOptional(): boolean {
    if (this.impact === NodeDecisionType.OPTIONAL) return true;
    if (this.node.optional) return true;
    let thisNode: NodeModel | undefined = this.containerNode;
    while (thisNode !== undefined) {
      if (thisNode.node.optional) return true;
      thisNode = thisNode.containerNode;
    }
    return false;
  }
  /**
   * Is this the question node which has been marked that its answer should be used
   * in the attributes field of record's metadata ?
   */
  public isCarryingAttribute(): boolean {
    if (this.node.nodeType === "question") {
      if ((this.node.question as Question).isRecordAttribute) {
        return true;
      }
    }
    return false;
  }
  /**
   * Returns the position of the node in the tree
   * where each child of root has position index 1 to number of children
   * and the child of them have similar position
   * The position with dots of a node is
   * (position with dot of parent) . (position of child among siblings)
   * Example : 2.3.1 is the position with dot of node which is 1st among its siblings
   * and whose parent is 3rd among its siblings,
   * and whose parent in turn is 2nd among its siblings
   * and whose parent is root.
   *
   * @return
   */
  public getPositionWithDots(): string {
    let position = "";
    let parent = this.containerNode;
    let thisNode: NodeModel = this;

    while (parent !== undefined) {
      let thisType = thisNode.node.nodeType;
      let index = 0;
      for (let sibling of parent.children) {
        if (sibling.node.nodeType === thisType) {
          index++;
        }
        if (thisNode.id === sibling.id) {
          break;
        }
      }
      position = position === "" ? index.toString() : index + "." + position;
      thisNode = parent;
      parent = parent.containerNode;
    }
    return position;
  }
  /**
   * Get the parent node
   */
  public getParent(): INodeModel | undefined {
    return this.containerNode;
  }
  /**
   * Returns the position of parent in dots notation
   * returns undefined if there is no parent
   * @returns
   */
  public getParentPositionWithDots(): string | undefined {
    return this.containerNode?.getPositionWithDots();
  }
  /**
   * Returns the name of parent if it exists
   */
  public getParentName(): string | undefined {
    return this.containerNode?.getShortName();
  }
  /**
   * Returns the next sibling if any
   */

  public getNextSibling(): NodeModel | undefined {
    let nextSibling: NodeModel | undefined = undefined;
    let index = this.containerNode?.children.indexOf(this);
    if (index !== undefined && index >= 0) {
      nextSibling = this.containerNode?.children[index + 1];
    }
    return nextSibling;
  }
  /**
   * Returns the previous sibling if any
   */

  public getPrevSibling(): NodeModel | undefined {
    let prevSibling: NodeModel | undefined = undefined;
    let index = this.containerNode?.children.indexOf(this);
    if (index !== undefined && index >= 1) {
      prevSibling = this.containerNode?.children[index - 1];
    }
    return prevSibling;
  }
  /**
   * Returns the next section if any
   */

  public getNextSection(): NodeModel | undefined {
    let nextSibling: NodeModel | undefined = undefined;
    let index = this.containerNode?.getChildSectionNodes().indexOf(this);
    if (index !== undefined && index >= 0) {
      nextSibling = this.containerNode?.getChildSectionNodes()[index + 1];
    }
    return nextSibling;
  }
  /**
   * Returns the previous section if any
   */

  public getPrevSection(): NodeModel | undefined {
    let prevSibling: NodeModel | undefined = undefined;
    let index = this.containerNode?.getChildSectionNodes().indexOf(this);
    if (index !== undefined && index >= 1) {
      prevSibling = this.containerNode?.getChildSectionNodes()[index - 1];
    }
    return prevSibling;
  }

  /**
   * adds the node as the child
   *
   * @param child node to be added
   */
  public addChild(child: NodeModel) {
    this.children.push(child);
    child.containerNode = this;
    (this.node.children as Node[]).push(child.node);
  }

  /**
   * Impact only this node and children
   *
   * Regarding React component:
   * So, if a React component X that has received the node N in its props, has to call this method
   * to remove N's child M, it should create a new node N1 copying N and call this method
   * on new node N1, and then inform its parent Y about the new node N1 so that node P in Y can replace the
   * the old child N with this new child N1.
   *
   * @param child
   */
  public unlinkChild(child: NodeModel) {
    let index = this.children.indexOf(child);
    if (index >= 0) {
      this.children.splice(index, 1);
      index = this.node.children.indexOf(child.node);
      if (index >= 0) {
        this.node.children.splice(index, 1);
      } else {
        console.error(
          "ERROR:unlinkChild on " +
            this.id +
            ":" +
            child.id +
            " is not child in Node"
        );
      }
    } else {
      console.error(
        "ERROR:unlinkChild on " + this.id + ":" + child.id + " is not child"
      );
    }

    delete child.containerNode;
  }
  /**
   * adds the specified node as child node after the reference node
   *
   * @param existingRefChild
   * @param childToAdd
   * @throws generates the exception if existingRefChild is not a child
   */
  public moveNodeAsChildAfter(
    existingRefChild: NodeModel,
    childToAdd: NodeModel
  ) {
    // unlink child from parent
    if (childToAdd.containerNode) {
      childToAdd.containerNode.unlinkChild(childToAdd);
    }
    let index = this.getChildPosition_(existingRefChild);
    if (index >= 0) {
      this.addChildAt_(index + 1, childToAdd);
    }
  }
  private addChildAt_(index: number, childToAdd: NodeModel) {
    this.children.splice(index, 0, childToAdd);
    childToAdd.containerNode = this;
    this.node.children.splice(index, 0, childToAdd.node);
  }
  private getChildPosition_(existingRefChild: NodeModel): number {
    if (existingRefChild.containerNode !== this) {
      console.error(
        "ERROR:getChildPosition on " +
          this.id +
          ":" +
          existingRefChild.id +
          " is not a child"
      );
    }
    let index = this.children.indexOf(existingRefChild);
    if (index >= 0) {
      let index1 = this.node.children.indexOf(existingRefChild.node);
      if (index !== index1) {
        console.error(
          "ERROR:getChildPosition on " +
            this.id +
            ":" +
            existingRefChild.id +
            " is not child or at different position in Node"
        );
      }
    } else {
      console.error(
        "ERROR:getChildPosition on " +
          this.id +
          ":" +
          existingRefChild.id +
          " is not child"
      );
    }
    return index;
  }

  /**
   * adds the specified node as child node before the reference node
   *
   * @param existingRefChild
   * @param childToAdd
   * @throws generates the exception if existingRefChild is not a child
   */
  public moveNodeAsChildBefore(
    existingRefChild: NodeModel,
    childToAdd: NodeModel
  ) {
    // unlink child from parent
    if (childToAdd.containerNode) {
      childToAdd.containerNode.unlinkChild(childToAdd);
    }
    let index = this.getChildPosition_(existingRefChild);
    if (index >= 0) {
      this.addChildAt_(index, childToAdd);
    }
  }

  // private void constructNodeTree(FormProtos.Node pbNode) {
  //   // iterate through each of child and call construction on them
  //   for (FormProtos.Node pbChildNode : pbNode.getChildrenList()) {
  //     Node child = CNode.from(pbChildNode, this);
  //     addChild(child);
  //   }
  // }
  // }
  public getChildSectionNodes(): NodeModel[] {
    return this.children.filter((child) => child.node.nodeType === "section");
  }

  /**
   * Return the condition on which it depends on. We want to use this to help in editing form.
   * @return condition or undefined
   */
  public getConditionImpactingThis(): GroupedCondition | undefined {
    return this.form
      .getConditionsHandler()
      .getConditions()
      .find((condition) =>
        condition
          .getAffectedNodes()
          .find((nodeDecision) => nodeDecision.nodeId === this.getId())
      );
  }
  /**
   *  // NOTE: when filling form, we need below to dynamically change the node property based on conditions
   * set or reset the node property based on decision conditions.
   * @param impact : type of change to be done, currently none, optional, hidden.
   * @return void
   */
  public impactNode(impact: NodeDecisionType): void {
    this.impact = impact;
  }
  public resetImpactToNode(): void {
    this.impact = NodeDecisionType.NONE;
  }
  /**
   * // NOTE: when filling form, we need below to dynamically change the node property based on conditions
   * is node hidden
   */
  public isHidden(): boolean {
    return this.impact === NodeDecisionType.HIDDEN;
  }
}
