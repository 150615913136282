import React from "react";
import { useIntl, defineMessages } from "react-intl";
import Navigation from "src/navigation/navigation";
import FormRecordsComp from "src/components/record/form_records";
import { useParams } from "react-router-dom";

export function FormRecords() {
  const intl = useIntl();
  const messages = defineMessages({
    formTitle: {
      id: "app.view_records",
      defaultMessage: "View Records",
      description: "View Records",
    },
  });
  const formTitle = intl.formatMessage(messages.formTitle);

  const { formId } = useParams<{ formId: string }>();

  return (
    <div className="App">
      <Navigation title={formTitle}>
        <FormRecordsComp formGuid={formId} />
      </Navigation>
    </div>
  );
}
