import { IntlShape } from "react-intl";
import protobufTypes, { AnswerValue } from "src/proto/FormServerMessages";

/**
 * The below methods are utility methods, to deal with general pattern of dealing with
 * enum types
 *
 *  https://stackoverflow.com/questions/39372804/typescript-how-to-loop-through-enum-values-for-display-in-radio-buttons
 <Select
  value={
    props.nodeCondition?.matchOperator
      ? protobufTypes.ConditionType[
          props.nodeCondition.matchOperator
        ]
      : null
  }
  onChange={(e) => {
    const newMatchOp =
      protobufTypes.ConditionType[
        e.target
          .value as keyof typeof protobufTypes.ConditionType
      ];
  }}
  >
    {
      Object.keys(protobufTypes.ConditionType)
        .filter(
          (key) =>
            !isNaN(
              Number(
                protobufTypes.ConditionType[
                  key as keyof typeof protobufTypes.ConditionType
                ]
              )
            )
        )
        .map((cType, index) => (
          <option key={index} value={cType.toString()}>
            {cTypeToLabel.get(cType)}
          </option>
        ))
    }
  </Select>
*/

export function getNumberStringAsKeysOfEnum<E>(T: any): string[] {
  return Object.keys(T).filter(
    (key) => !isNaN(Number(T[key as keyof typeof T]))
  );
}
/**
 * Provides language specific name for enum key values.
 * enum key values are integer string values
 *
 * TODO: add parameter which checks the type of applicable condition type
 *   string : equal to, not equal to, (less than, .. in dictionary order), contains, does not contain
 *   number, date, etc comparable : equal to, not equal to, less than, greater than, less than or equal to, greater than or equal to
 * @param intl
 */
export function ConditionTypeToLabelMap(intl: IntlShape): Map<string, string> {
  const cTypeToLabel = new Map<string, string>([
    [
      protobufTypes.ConditionType[protobufTypes.ConditionType.EQ],
      intl.formatMessage({
        id: "app.conditiontype.equal",
        defaultMessage: "Equal to"
      })
    ],
    [
      protobufTypes.ConditionType[protobufTypes.ConditionType.GE],
      intl.formatMessage({
        id: "app.conditiontype.greater_equal",
        defaultMessage: "Greater than or Equal to"
      })
    ],
    [
      protobufTypes.ConditionType[protobufTypes.ConditionType.NEQ],
      intl.formatMessage({
        id: "app.conditiontype.not_equal",
        defaultMessage: "Not equal"
      })
    ]
  ]);
  return cTypeToLabel;
}

export function QuestionTypeToLabelMap(intl: IntlShape): Map<string, string> {
  const qTypeToLabel = new Map<string, string>([
    [
      protobufTypes.QuestionType[protobufTypes.QuestionType.FILL_IN_BLANK],
      intl.formatMessage({
        id: "app.questiontype.fillin",
        defaultMessage: "Fill-in Blank"
      })
    ],
    [
      protobufTypes.QuestionType[protobufTypes.QuestionType.YES_NO],
      intl.formatMessage({
        id: "app.questiontype.yesno",
        defaultMessage: "Yes/No"
      })
    ],
    [
      protobufTypes.QuestionType[protobufTypes.QuestionType.MULTI_CHOICE],
      intl.formatMessage({
        id: "app.questiontype.multichoice",
        defaultMessage: "Multiple Choice"
      })
    ],
    [
      protobufTypes.QuestionType[protobufTypes.QuestionType.MULTI_SELECT],
      intl.formatMessage({
        id: "app.questiontype.multiselect",
        defaultMessage: "Multiple Selectable"
      })
    ]
  ]);
  return qTypeToLabel;
}
/**
 *
 * @param intl
 */
export function FillInBlankTypeToLabelMap(
  intl: IntlShape
): Map<string, string> {
  const fillInTypeToLabel = new Map<string, string>([
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.TEXT],
      intl.formatMessage({
        id: "app.fillIntype.text",
        defaultMessage: "General text"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.NUMBER],
      intl.formatMessage({
        id: "app.fillIntype.number",
        defaultMessage: "Number"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.URL],
      intl.formatMessage({
        id: "app.fillIntype.url",
        defaultMessage: "Web URL"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.DATE],
      intl.formatMessage({
        id: "app.fillIntype.date",
        defaultMessage: "Date"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.TIME],
      intl.formatMessage({
        id: "app.fillIntype.time",
        defaultMessage: "Time"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.EMAIL],
      intl.formatMessage({
        id: "app.fillIntype.email",
        defaultMessage: "Email"
      })
    ],
    [
      protobufTypes.FillInBlankType[
        protobufTypes.FillInBlankType.EMAIL_VERIFIED
      ],
      intl.formatMessage({
        id: "app.fillIntype.email_verified",
        defaultMessage: "Verified Email"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.PHONE],
      intl.formatMessage({
        id: "app.fillIntype.phone",
        defaultMessage: "Phone Number"
      })
    ],
    [
      protobufTypes.FillInBlankType[
        protobufTypes.FillInBlankType.PHONE_VERIFIED
      ],
      intl.formatMessage({
        id: "app.fillIntype.phone_verified",
        defaultMessage: "Verified Phone Number"
      })
    ],
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.DECIMAL],
      intl.formatMessage({
        id: "app.fillIntype.decimal_number",
        defaultMessage: "Decimal Number"
      })
    ],
    /** In future, we can specify the File to Upload to Permanent Storage where permanent storage is the owned by the form owner e.g. in Google/Azure/AWS s3 etc. */
    [
      protobufTypes.FillInBlankType[protobufTypes.FillInBlankType.FILE_UPLOAD],
      intl.formatMessage({
        id: "app.fillIntype.file",
        defaultMessage: "File to Upload"
      })
    ]
  ]);
  return fillInTypeToLabel;
}
