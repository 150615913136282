import { FilledForm } from "src/proto/FormServerMessages";
import AppConfiguration from "src/configurations/constants";
import {
  RecordMetadata,
  RecordAttribute,
  FormMetadata,
} from "src/services/datatypes/metadata";
import FormData from "form-data";
import axios, { AxiosResponse } from "axios";
export default class RecordService {
  /*
		Retrieves a given record
	*/
  public static async getFilledForm(recordGuid: string) {
    try {
      let response = await axios.get(
        AppConfiguration.formServerUrl + `/records/${recordGuid}`,
        { responseType: "arraybuffer" }
      );
      let formBuffer = await response.data;
      if (formBuffer) {
        let uint8Buffer: Uint8Array = new Uint8Array(formBuffer);
        console.log("FilledForm Buffer size: %d", uint8Buffer.byteLength);
        return FilledForm.decode(uint8Buffer);
      } else {
        throw new Error("buffer is undefined");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  /*
		Creates a new record

  public static postFilledForm(form: FilledForm, metadata: RecordMetadata) {
    const url =
      AppConfiguration.formServerUrl +
      "/forms/" +
      form.guidForm +
      "/records/";
    const formData = new FormData();

    formData.append("record", Buffer.from(FilledForm.encode(form).finish()));
    formData.append("metadata", JSON.stringify(metadata));

    return new Promise((resolve, reject) => {
      formData.submit(url, (err, response) => {
        if (err) return reject(err);
        resolve(response);
      });
    });
  }
	*/
  /*
		Creates a new record
	*/
  public static postFilledForm(
    filledForm: FilledForm,
    formMetadata: FormMetadata,
    isDraft: boolean,
    attributes: RecordAttribute[]
  ) {
    const url =
      AppConfiguration.formServerUrl + `/forms/${filledForm.guidForm}/records/`;

    // TODO: form server should create the recordGuid by itself ;
    // otherwise below will result in user being able to manipulate the existing records.
    // Do we want this ? e.g. to allow user to save partially filled records ? we noted that
    // partially filled records has to be identified by the <account id, form id> pair if atmost
    // one form will be filled by an account. However, if user of an account is filling multiple
    // records using the questionairre of the form, then using recordGuid to save partially filled
    // records may make sense; however in this case user will need additional information to differentiate
    // one filled records from another.
    const recordMetadata: RecordMetadata = {
      recordGuid: filledForm.guid,
      formGuid: filledForm.guidForm,
      // server can figure out accountId
      accountId: "",
      formVersion: formMetadata.version,
      synched: false,
      // TODO: get the attributes from the FilledForm
      attributes: JSON.stringify(attributes),
      isDraft: isDraft,
      recordDate: Date.now(),
      // server can figure out formName from formGuid
      formName: "",
    };

    filledForm.formVersion = formMetadata.version;

    const formData = new FormData();

    // there was behavior difference when sending the "form" part
    //  in nodejs and in browser.
    // the "metadata" part was OK, as it seems it was string only.
    // However, for the "form" part, golang server  ( in its c.PostForm("form") )
    //   was reporting 2 extra bytes when sent from browser, however there was no
    //   problem when sent from the nodejs.
    // The use of buffer with "application/binary" is solving the problem.

    try {
      if (typeof formData.getHeaders === "function") {
        // nodejs environmnet
        const buffer = Buffer.from(
          FilledForm.encode(filledForm).finish()
        ).toString();
        formData.append("form", buffer);
        console.log(`record bytelength is ${buffer.length}`);
      } else {
        // browser environment
        const buffer = new Blob([FilledForm.encode(filledForm).finish()], {
          type: "application/binary",
        });
        formData.append("record", buffer);
        console.log(
          `record bytelength is ${buffer.size} ${buffer.arrayBuffer.length}`
        );
      }
      formData.append("metadata", JSON.stringify(recordMetadata));
    } catch (e) {
      console.error(`error : ${JSON.stringify(e)}`);
    }

    const headerData =
      typeof formData.getHeaders === "function"
        ? formData.getHeaders()
        : {
            "Content-Type": "multipart/form-data",
          };

    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, {
          headers: {
            ...headerData,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  /*
		Deletes a record
  */
  public static deleteRecord(recordGuid: string) {
    return axios.delete(
      AppConfiguration.formServerUrl + `/records/${recordGuid}`,
      { responseType: "json" }
    );
  }
  /**
   * Delete the filled records of upto certain creation time from Server
   */
  public static deleteRecords(formGuid: string, uptoTime: number) {
    return axios.delete(
      AppConfiguration.formServerUrl + `/formrecords/${formGuid}`,
      {
        params: {
          upto: uptoTime,
        },
        responseType: "json",
      }
    );
  }
  /*
		Return form metadata
  */
  public static getRecordMetadata(
    recordGuid: string
  ): Promise<AxiosResponse<RecordMetadata>> {
    return axios.get(
      AppConfiguration.formServerUrl + `/metadata/records/${recordGuid}`,
      { responseType: "json" }
    );
  }
  /**
   * Get the filled records for a form since a certain date from the server
   * @param guidForm : the guid of the form
   * @param sinceTime : the time
   * @return the list of guids of filled records
   */
  public static getFormRecords(formGuid: string, sinceTime: number) {
    return axios.get(
      AppConfiguration.formServerUrl + `/metadata/formrecords/${formGuid}`,
      {
        params: {
          since: sinceTime,
        },
        responseType: "json",
      }
    );
  }
}
