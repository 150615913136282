import React, { ReactElement, ReactComponentElement } from "react";

import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import { FormMetaData } from "../form_list/form_list";
import { GenericActionMenuType } from "./generic_action_menu";
import { EnhancedTableHead, HeadCellType } from "./enhanced_table_head";

/**
 * Goal : To make a table with header which contains sortable columns.
 * optionally, should allow multiple rows selection, for certain action or actions.
 *
 * The bottom, optionally, should have page footer.
 * This table component should be reusable.
 *
 * numCols: number of columns
 * tableHeader: list of elements
 * dataRows: array of list of data elements
 */

// TODO: deal with timestamp data
// right now, we are converting timestamp data to string before GenericTable is called
// however, this result in sort of Date to behave wrongly.
// GenericTable should be able to convert the timestamp data to String
// at time of rendering.

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

type DataSubsetOrderable<Data> = { [key in keyof Data]: string | number };

// only keyof property is used
// that is type has no significance.
// type Data = FormMetaData;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

function getComparator<Data>(
  order: Order,
  orderBy: keyof DataSubsetOrderable<Data>
): (a: Data, b: Data) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type GenericTablePropsType<Data> = {
  defaultSortKeyName: keyof Data;
  headCells: HeadCellType<Data>[];
  rows: Data[];
  // the column in row that contains unique value among the rows, i.e. containing the id of row.
  // we expect this key to be string only
  guid: keyof Data & string;
  // whether the first column should be selection checkbox
  addSelectionCheckbox: boolean;
  actionMenu?: GenericActionMenuType;
};

function GenericTableRow<Data>(props: {
  row: Data;
  guid: keyof Data & string; // the column in row that contains unique value among the rows, i.e. containing the id of row.
  // name of column keys, in order
  headCells: HeadCellType<Data>[];
  // whether the first column should be selection checkbox
  addSelectionCheckbox: boolean;
  isSelected: boolean;
  labelId: string;
  actionMenu?: GenericActionMenuType;
  handleClick: (event: React.MouseEvent<unknown>, name: any) => void;
}) {
  const row = props.row;

  return (
    <TableRow
      hover
      onClick={(event) => props.handleClick(event, row[props.guid])}
      role="checkbox"
      aria-checked={props.isSelected}
      tabIndex={-1}
      key={props.guid as string /*row.formName*/}
      selected={props.isSelected}
    >
      {/* below checkbox is optional component, refactor it*/}
      {props.addSelectionCheckbox === false ? null : (
        <TableCell padding="checkbox">
          <Checkbox
            checked={props.isSelected}
            inputProps={{ "aria-labelledby": props.labelId }}
          />
        </TableCell>
      )}
      {props.headCells.map((headCell, columnIndex) => {
        if (columnIndex === -1) {
          {
            /* remove this if it is not useful.
            Earlier condition was columnIndex === 0. I am not sure why.
            By changin 0 to -1, I have effectively disabled it.
            */
          }
          return (
            <TableCell
              component="th"
              id={props.labelId}
              scope="row"
              padding="none"
              key={columnIndex + 1}
            >
              {props.actionMenu === undefined ? (
                row[headCell.id as keyof Data]
              ) : (
                <props.actionMenu data={row[props.guid]}>
                  {row[headCell.id as keyof Data]}
                </props.actionMenu>
              )}
            </TableCell>
          );
        } else {
          return (
            <TableCell
              align={headCell.rightAlign ? "right" : "left"}
              key={columnIndex + 1}
            >
              {props.actionMenu === undefined ? (
                headCell.stringConverter ? (
                  headCell.stringConverter(row[headCell.id as keyof Data])
                ) : (
                  row[headCell.id as keyof Data]
                )
              ) : (
                <props.actionMenu data={row[props.guid]}>
                  {headCell.stringConverter
                    ? headCell.stringConverter(row[headCell.id as keyof Data])
                    : row[headCell.id as keyof Data]}
                </props.actionMenu>
              )}
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
}
/**
 * Generic table
 *
 * TODO: attach a popup menu to each row
 *
 * @param props
 */
export default function GenericTable<Data>(props: GenericTablePropsType<Data>) {
  const rows = props.rows; //formsArray;

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof DataSubsetOrderable<Data>>(
    props.defaultSortKeyName
  ); //"formName");
  const [selected, setSelected] = React.useState<Data[keyof Data & string][]>(
    []
  );
  const [page, setPage] = React.useState(0);
  let dense = true;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && rows !== undefined) {
      const newSelecteds = rows.map((aRow) => aRow[props.guid]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, guid: any) => {
    const selectedIndex = selected.indexOf(guid);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, guid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (guid: any) => selected.indexOf(guid) !== -1;

  const emptyRows =
    rows === undefined
      ? 0
      : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="Forms that you own"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy as string}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows === undefined ? 0 : rows.length}
              headCells={props.headCells}
              addSelectionCheckbox={props.addSelectionCheckbox}
            />
            <TableBody>
              {rows === undefined
                ? null
                : stableSort<Data>(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Data, index: string | number | undefined) => {
                      const isItemSelected = isSelected(row[props.guid]);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <GenericTableRow<Data>
                          row={row}
                          headCells={props.headCells}
                          addSelectionCheckbox={props.addSelectionCheckbox}
                          guid={props.guid}
                          handleClick={handleClick}
                          isSelected={isItemSelected}
                          labelId={labelId}
                          key={index}
                          actionMenu={props.actionMenu}
                        />
                      );
                    })}
              {/*emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )*/}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows === undefined ? 0 : rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </div>
  );
}
