import React, { useEffect, useState } from "react";
import axios from "axios";
import AppConfiguration from "src/configurations/constants";
import { Form as FormMessages, Node } from "src/proto/FormServerMessages";
import FormName from "src/components/formelements/formname/formname";
import MContainer from "@material-ui/core/Container";
import FormService from "src/services/form_service";
import FormView from "src/components/formelements/form";
import { FormModel } from "src/services/models/form_model";
import SectionName from "src/components/formelements/section_name";
import { makeStyles } from "@material-ui/core/styles";
import "./form.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import Box from "@material-ui/core/Box/Box";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "src/components/formelements/enums";
import AnswerOptionView from "src/components/formelements/answer_option";
import Typography from "@material-ui/core/Typography";
import { NodeModel } from "src/services/models/node_model";
import QuestionView from "src/components/formelements/question";
import SectionView from "src/components/formelements/section";
import Paper from "@material-ui/core/Paper";
import { useFetchForm } from "src/hooks/form";

type NoticeProps = {
  msg: string;
  //form: FormMessages;
};

type ErrorType = {
  message: string;
};
type ItemType = {
  name: string;
  price: Number;
};

type StateType = {
  error: ErrorType | null;
  isLoaded: boolean;
  items: Array<Node>;
  form: FormModel | undefined;
};

function FillForm(props: { formGuid: string }) {
  console.log("going to start");

  const { error, isLoaded, form } = useFetchForm(
    props.formGuid
    // AppConfiguration.formGuidTestOnProjBintech
  );
  //const {error, isLoaded, items, form } = useFetch("c6081c95-7ffd-49ae-aaad-bc052817e7fa");
  try {
    if (form !== undefined && form !== null) {
      console.log("rendering form " + form.rootNodeModel?.node.shortName);

      return (
        <div>
          <div className="form">
            <FormView
              filledForm={FilledFormModel.fromForm(
                form.getForm() as FormMessages
              )}
              displayMode={FormDisplayMode.FillMode}
            ></FormView>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div>
          Error: {props.formGuid} - {error.message}
        </div>
      );
    } else if (!isLoaded || form === undefined || form === null) {
      return <div>Loading...</div>;
    } else {
      return <div>Unknown state...</div>;
    }
  } catch {
    return <div>The form {props.formGuid} has problem.</div>;
  }
}

export default FillForm;
