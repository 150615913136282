import React from "react";
import protobufType, {
  Form as FormMessages,
  Node,
  AnswerGiven,
  AnswerValue,
  Question,
  AnswerOption,
  IAnswerValue,
} from "src/proto/FormServerMessages";
import FormView from "src/components/formelements/form";
import { FormModel } from "src/services/models/form_model";
import { FilledFormModel } from "src/services/models/filled_form_model";
import { FormDisplayMode } from "src/components/formelements/enums";
import { useFetchFilledForm } from "src/hooks/form";
import { NodeModel } from "src/services/models/node_model";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";

type ErrorType = {
  message: string;
};

type QuestionAnswerPair = {
  question: string;
  answer: string;
};

function answerGivenToAnswerText(ansGiven: AnswerGiven): string {
  let answerText = "";
  let nextTimeAnswer: boolean = false;
  ansGiven.answerValues.forEach((ansVal: IAnswerValue) => {
    if (nextTimeAnswer) {
      answerText = answerText.concat(", ");
    }
    const ans = ansVal as AnswerValue;
    switch (ans.value) {
      case "trueFalseValue":
        answerText = answerText.concat(ans.trueFalseValue ? "Yes" : "No");
        break;
      case "textValue":
        answerText = answerText.concat(ans.textValue);
        break;
      case "integerValue":
        answerText = answerText.concat(ans.integerValue.toString());
        break;
      case "timeHourMinuteValue":
        answerText = answerText.concat(ans.timeHourMinuteValue.toString());
        break;
      case "dateValue":
        answerText = answerText.concat(ans.dateValue.toString());
        break;
      case "emailValue":
        answerText = answerText.concat(ans.emailValue.toString());
        break;
      case "moneyValue":
        answerText = answerText.concat(ans.moneyValue.toString());
        break;
      case "decimalValue":
        answerText = answerText.concat(ans.decimalValue.toString());
        break;
    }
    nextTimeAnswer = true;
  });
  return answerText;
}
function getRecordData(filledForm: FilledFormModel): QuestionAnswerPair[] {
  let qAnswers: QuestionAnswerPair[] = [];
  const QIdAnswerMap = filledForm.getGivenAnswers();
  filledForm.form.getQuestionNodes().forEach((qNode: NodeModel) => {
    const answerGiven: AnswerGiven | undefined = QIdAnswerMap.get(
      qNode.node.id
    );
    if (answerGiven) {
      if (
        [
          protobufType.QuestionType.MULTI_CHOICE,
          protobufType.QuestionType.MULTI_SELECT,
        ].indexOf((qNode.node.question as Question).type) >= 0
      ) {
        let answerText = "";
        let nextTimeAnswer = false;
        answerGiven.answerValues.forEach((optionIndex: IAnswerValue) => {
          if (nextTimeAnswer) {
            answerText = answerText.concat(", ");
          }
          answerText = answerText.concat(
            ((qNode.node.question as Question).options[
              (optionIndex as AnswerValue).integerValue
            ] as AnswerOption).accompaniedText
          );
          nextTimeAnswer = true;
        });
        qAnswers.push({
          question: qNode.node.textToDisplay,
          answer: answerText,
        });
      } else {
        qAnswers.push({
          question: qNode.node.textToDisplay,
          answer: answerGivenToAnswerText(answerGiven),
        });
      }
    }
  });
  return qAnswers;
}
function ViewRecordRaw(props: { recordGuid: string }) {
  console.log("going to start");
  const { error, isLoaded, recordMetadata, filledForm } = useFetchFilledForm(
    props.recordGuid
    // AppConfiguration.formGuidTestOnProjBintech
  );
  //const {error, isLoaded, items, form } = useFetch("c6081c95-7ffd-49ae-aaad-bc052817e7fa");
  try {
    if (filledForm !== undefined && filledForm !== null) {
      console.log(
        "rendering form " + filledForm.form.rootNodeModel?.node.shortName
      );

      let questionAnswerPairs: QuestionAnswerPair[] = getRecordData(filledForm);
      return (
        <div>
          <div className="form">
            <div>
              Question - answer pair in a table ? User selectable option: for
              multiple choice/select, just list the selected index or list the
              complete answer.
            </div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell align="right">Answer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questionAnswerPairs.map((row) => (
                    <TableRow key={row.question}>
                      <TableCell scope="row">{row.question}</TableCell>
                      <TableCell align="right">{row.answer}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div>
          Error: {props.recordGuid} - {error.message}
        </div>
      );
    } else if (!isLoaded || filledForm === undefined || filledForm === null) {
      return <div>Loading...</div>;
    } else {
      return <div>Unknown state...</div>;
    }
  } catch {
    return <div>The record {props.recordGuid} has problem.</div>;
  }
}

export default ViewRecordRaw;
