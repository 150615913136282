import React, { useState, Dispatch, SetStateAction } from "react";
import { LocalStorageKeyLocale } from "src/local_storage_keys";
import { getMappedLanguage } from "src/language/language";

// See https://kentcdodds.com/blog/how-to-use-react-context-effectively

const browserLanguage = navigator.language;
const LanguageContext = React.createContext<string | undefined>(undefined);
const LanguageSetterContext = React.createContext<
  ((lang: string) => void) | undefined
>(undefined);

function LanguageProvider({ children }: { children: any }) {
  const localeStored = localStorage.getItem(LocalStorageKeyLocale);
  const localeToUse = getMappedLanguage(
    localeStored === null ? browserLanguage : localeStored
  );
  const [langCode, setLangCode] = useState(localeToUse);
  const setLanguage = (langCode: string) => {
    const localeToUse = getMappedLanguage(langCode);
    setLangCode(localeToUse);
    // save in browser local storage
    localStorage.setItem(LocalStorageKeyLocale, localeToUse);
  };
  return (
    <LanguageContext.Provider value={langCode}>
      <LanguageSetterContext.Provider value={setLanguage}>
        {children}
      </LanguageSetterContext.Provider>
    </LanguageContext.Provider>
  );
}
function useLanguage() {
  const context = React.useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
}
function useLanguageSetter() {
  const context = React.useContext(LanguageSetterContext);
  if (context === undefined) {
    throw new Error("useLanguageSetter must be used within a LanguageProvider");
  }
  return context;
}
export { LanguageProvider, useLanguage, useLanguageSetter };
