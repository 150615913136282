import { MenuItemType } from "../common/generic_action_menu";
import { History } from "history";
import FormService from "src/services/form_service";

export function getContextMenuForRecordRows(
  history: History<any>
): MenuItemType[] {
  return [
    {
      name: "view",
      action: (data?: any) => {
        console.log(`pressed view ${data}`);
        history.push(`/record/view/${data}`);
      },
    },
    {
      name: "add tag",
      action: (data?: any) => {
        console.log(`user want to add tag to record ${data}`);
        history.push(`/record/edit/${data}`);
      },
    },
    {
      name: "add temporary tag",
      action: (data?: any) => {
        console.log(
          `user want to add temporary local tag to record e.g. to help him to deal with managing them ${data}`
        );
        history.push(`/record/edit/${data}`);
      },
    },
    {
      name: "delete",
      action: (data?: any) => {
        FormService.deleteRecord(data).then((e) => window.location.reload());
        console.log(`pressed delete ${data}`);
      },
    },
  ];
}
