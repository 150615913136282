import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import Navigation from "src/navigation/navigation";
import Login from "src/screens/login";
import LoginService from "src/services/login_service";

export interface PrivateRouteProps extends RouteProps {
  //isAuthenticated: boolean;
  // is user, if authenticated, permitted ?
  // by default, authenticated user is assumed to have permission to visit the route
  isAllowed?: boolean;
  // the path to redirect if authenticated user doesn't have permission to see the page
  // by default, this uses '/restricted' as this path
  restrictedPath?: string;
  // the path to redirect if user is not logged-in
  // by default, this uses '/login' as this path
  authenticationPath?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  let redirectPath = "";
  if (!LoginService.isLoggedIn()) {
    redirectPath = props.authenticationPath
      ? props.authenticationPath
      : "/login";
  }
  if (
    LoginService.isLoggedIn() &&
    props.isAllowed !== undefined &&
    !props.isAllowed
  ) {
    redirectPath = props.restrictedPath ? props.restrictedPath : "/restricted";
  }
  if (redirectPath) {
    return (
      <Route
        render={() => (
          <Navigation title="Login">
            <Login redirectToOnSignIn={props.path as string} />
          </Navigation>
        )}
      />
    );
  } else {
    return <Route {...props} />;
  }
};

export default PrivateRoute;
