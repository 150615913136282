import React, { useReducer, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import protobufTypes, {
  Question,
  AnswerOption,
  AnswerValue,
} from "src/proto/FormServerMessages";
import truncate from "lodash/truncate";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { QuestionModel } from "src/services/models/question_model";
import { NodeModel } from "src/services/models/node_model";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import {
  Container,
  Grid,
  Checkbox,
  Select,
  InputLabel,
  TextField,
  FormControl,
  IconButton,
} from "@material-ui/core";
import {
  FillInBlankTypeToLabelMap,
  getNumberStringAsKeysOfEnum,
  QuestionTypeToLabelMap,
} from "../enum_to_labels";
import { FormModel } from "src/services/models/form_model";
import { ClickableCondition } from "./conditions/clickable_condition";
import { FormDisplayMode } from "./enums";
import { isInteger } from "lodash";
import { number } from "yup";
import { GroupedCondition } from "src/services/models/decision_model";
import { IFormModel } from "src/services/models/interface/form_model";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { messages } from "src/translations/intl_messages";

export default function NewQuestionModal(props: {
  formModel: IFormModel;
  questionNodeModel?: NodeModel; // in case of edit
  hintText?: any;
  open: boolean;
  condition?: GroupedCondition; // condition on which this question is shown
  isShortNameOK: (name: string) => boolean;
  onClose: (
    questionShortName: string | undefined,
    questionText: string | undefined,
    optional: boolean | undefined,
    question: Question | undefined,
    confirmed: boolean,
    condition: GroupedCondition | undefined
  ) => void;
}) {
  //  --- New Question ----
  //  Question text
  //    [.............]
  //  Type of Question  [..]
  //  If options
  //    [........]  [Add]
  const intl = useIntl();

  const {
    questionTextLabel,
    optionalQuestionConfirmLabel,
    attributeQuestionConfirmLabel,
    isConditionalLabel,
    shortNameLabel,
    shortNameHelp,
    questionTypeLabel,
    fillInBlankTypeLabel,
  } = getDataForLocale(intl);

  const qTypeToLabel = QuestionTypeToLabelMap(intl);
  const fillInTypeToLabel = FillInBlankTypeToLabelMap(intl);

  /*
  const [questionType, setQuestionType] = useState(
    protobufTypes.QuestionType.FILL_IN_BLANK
  );
  */
  // is the question optional
  const [isOptional, setOptional] = useState(
    props.questionNodeModel ? props.questionNodeModel.isOptional() : false
  );
  const [isAttribute, setIsAttribute] = useState(
    props.questionNodeModel ? props.questionNodeModel.isAttribute() : false
  );
  const [isConditional, setIsConditional] = useState(
    props.condition ? true : false
  );
  const [condition, setCondition] = useState(props.condition);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const { onClose } = props;
  const [questionText, setQuestionText] = useState<string | undefined>(
    props.questionNodeModel
      ? props.questionNodeModel.getTextToDisplay()
      : undefined
  );
  const questionNew = new Question();
  questionNew.type = protobufTypes.QuestionType.FILL_IN_BLANK;
  questionNew.fillInType = protobufTypes.FillInBlankType.TEXT;
  questionNew.isRecordAttribute = false;
  const [submittable, setSubmittable] = useState(
    props.questionNodeModel !== undefined
  );

  // determine if question can be submitted
  const setIfSettable = function (qText?: string, q?: Question) {
    if (qText === undefined || qText.length <= 0 || q === undefined) {
      setSubmittable(false);
      return;
    }
    if (
      q.type !== protobufTypes.QuestionType.MULTI_CHOICE &&
      q.type !== protobufTypes.QuestionType.MULTI_SELECT
    ) {
      setSubmittable(true);
    } else {
      setSubmittable(
        q.options.filter(
          (opt) => opt.accompaniedText && opt.accompaniedText.length > 0
        ).length > 1
      );
    }
  };
  const [question, setQuestion] = useState<Question>(
    props.questionNodeModel
      ? (props.questionNodeModel.node.question as Question)
      : questionNew
  );

  const [shortName, setShortName] = useState<string | undefined>(
    props.questionNodeModel ? props.questionNodeModel.node.shortName : undefined
  );

  const handleFillInBlankTypeChange = (
    value: keyof typeof protobufTypes.FillInBlankType
  ) => {
    let questionNew = new Question(question);
    questionNew.fillInType = protobufTypes.FillInBlankType[value];
    setQuestion(questionNew);
  };

  // handle the change in question type
  const handleQTChange = (value: keyof typeof protobufTypes.QuestionType) => {
    let questionNew = new Question(question);
    questionNew.type = protobufTypes.QuestionType[value];
    // handle case of multiple choice
    if (
      questionNew.type === protobufTypes.QuestionType.MULTI_CHOICE ||
      questionNew.type === protobufTypes.QuestionType.MULTI_SELECT
    ) {
      if (questionNew.options.length < 2) {
        // add two empty options
        let answerOption0 = new AnswerOption();
        answerOption0.accompaniedText = "";
        let answerValue0 = new AnswerValue();
        answerValue0.integerValue = 0;
        answerOption0.value = answerValue0;

        let answerOption1 = new AnswerOption();
        answerOption1.accompaniedText = "";
        let answerValue1 = new AnswerValue();
        answerValue1.integerValue = 1;
        answerOption1.value = answerValue1;

        // below clone the objects; check if deep clone is needed.
        questionNew.options = [{ ...answerOption0 }, { ...answerOption1 }];
      }
    }
    setIfSettable(questionText, questionNew);
    setQuestion(questionNew);
  };
  // TODO: UI design to support user's thinking about condition :
  //  what user thinks when he clicks Conditional ?
  //  does he think that this question/section will be shown only when the condition is true
  //  if yes, then we should make a question hidden by default, and
  //  can provide UI if he has alternative in mind (hide if condition, or optional if condition)

  /**
   * Note: using onBlur() below as otherwise Input takes too long to react to
   * fast keyboard typing.
   * See https://stackoverflow.com/questions/32911519/react-slow-with-multiple-controlled-text-inputs/36769581
   */

  return (
    <Dialog
      fullWidth
      onClose={(e) =>
        onClose(undefined, undefined, undefined, undefined, false, undefined)
      }
      aria-labelledby={props.hintText}
      open={props.open}
    >
      <DialogTitle id="simple-dialog-title">{props.hintText}</DialogTitle>
      <DialogContent>
        {/* https://stackoverflow.com/questions/47012169/a-component-is-changing-an-uncontrolled-input-of-type-text-to-be-controlled-erro */}
        <TextField
          label={questionTextLabel}
          autoFocus
          fullWidth
          multiline
          value={questionText}
          onChange={(e) => {
            setQuestionText(e.target.value);
            setIfSettable(e.target.value, question);
          }}
        />
        <br />
        <br />
        <FormControlLabel
          value={false}
          control={
            <Checkbox
              color="primary"
              checked={isOptional}
              onChange={(_, checked) => setOptional(checked)}
              name="isOptional"
            />
          }
          label={optionalQuestionConfirmLabel}
          labelPlacement="end"
        />
        <FormControlLabel
          value={false}
          control={
            <Checkbox
              color="primary"
              checked={isAttribute}
              onChange={(_, checked) => {
                if (shortName === undefined) {
                  setShortName(truncate(questionText, { length: 20 }));
                }
                setIsAttribute(checked);
              }}
              name="isAttribute"
            />
          }
          label={attributeQuestionConfirmLabel}
          labelPlacement="end"
        />
        <FormControlLabel
          value={false}
          control={
            <Checkbox
              color="primary"
              checked={isConditional}
              onChange={(_, checked) => {
                setIsConditional(checked);
              }}
              name="isConditional"
            />
          }
          label={isConditionalLabel}
          labelPlacement="end"
        />
        {shortName || isAttribute ? (
          <TextField
            label={shortNameLabel}
            autoFocus
            fullWidth
            helperText={
              shortName
                ? props.isShortNameOK(shortName)
                  ? null
                  : shortNameHelp
                : null
            }
            value={shortName}
            onChange={(e) => {
              setShortName(truncate(e.target.value, { length: 20 }));
            }}
          />
        ) : null}
        <br />
        {props.formModel ? (
          <Grid container>
            <FormControl>
              <InputLabel id="condition-select-label">Condition</InputLabel>
              <Select
                labelId="condition-select-label"
                value={condition ? condition.getId() : null}
                onChange={(e, value) => {
                  let conditionSelected = props.formModel
                    .getConditionsHandler()
                    .getConditions()
                    .find((c) => c.getId() === e.target.value);
                  // TODO: how to get the nodeId of this new question ?
                  setCondition(conditionSelected);
                }}
              >
                {props.formModel
                  .getConditionsHandler()
                  .getConditions()
                  .map((conditionGrouped, index) => (
                    <MenuItem key={index} value={conditionGrouped.getId()}>
                      {conditionGrouped.getName()}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <FormControl>
          <InputLabel id="question-type-select-label">
            {questionTypeLabel}
          </InputLabel>
          <Select
            native
            labelId="question-type-select-label"
            aria-label={questionTypeLabel}
            name={questionTypeLabel}
            value={protobufTypes.QuestionType[question.type]}
            onChange={(e, value) =>
              handleQTChange(
                e.target.value as keyof typeof protobufTypes.QuestionType
              )
            }
          >
            {
              /* https://stackoverflow.com/questions/39372804/typescript-how-to-loop-through-enum-values-for-display-in-radio-buttons */
              getNumberStringAsKeysOfEnum(protobufTypes.QuestionType).map(
                (qType, index) => (
                  <option key={index} value={qType.toString()}>
                    {qTypeToLabel.get(qType)}
                  </option>
                )
              )
            }
          </Select>
        </FormControl>
        {question.type !== protobufTypes.QuestionType.FILL_IN_BLANK ? null : (
          <>
            <div> </div>
            <FormControl>
              <InputLabel>{"Fill in Blank Type"}</InputLabel>
              <Select
                native
                aria-label={fillInBlankTypeLabel}
                name={fillInBlankTypeLabel}
                value={protobufTypes.FillInBlankType[question.fillInType]}
                onChange={(e, value) =>
                  handleFillInBlankTypeChange(
                    e.target.value as keyof typeof protobufTypes.FillInBlankType
                  )
                }
              >
                {
                  /* https://stackoverflow.com/questions/39372804/typescript-how-to-loop-through-enum-values-for-display-in-radio-buttons */
                  getNumberStringAsKeysOfEnum(
                    protobufTypes.FillInBlankType
                  ).map((fillInType, index) => (
                    <option key={index} value={fillInType.toString()}>
                      {fillInTypeToLabel.get(fillInType)}
                    </option>
                  ))
                }
              </Select>
            </FormControl>
          </>
        )}
        {question.type !== protobufTypes.QuestionType.MULTI_CHOICE &&
        question.type !== protobufTypes.QuestionType.MULTI_SELECT ? null : (
          <>
            <FormLabel>{qTypeToLabel.get(question.type.toString())}</FormLabel>
            {question.options.map((opt, index) => (
              <React.Fragment key={index}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      multiline
                      value={question.options[index].accompaniedText}
                      inputProps={{ "aria-label": questionTextLabel }}
                      onChange={(e) => {
                        // TODO: check the performance issue
                        // each keyboard stroke is resulting in new() below
                        // which is required because value field is setting the
                        // value shown; the way to avoid this is to cause
                        // forced refresh.
                        /*if (e.target.value  && e.target.value.length >= 0)*/
                        /*
                        let newQuestion = new Question(question);
                        newQuestion.options[index].accompaniedText =
                          e.target.value;
                        setQuestion(newQuestion);
                        */
                        question.options[index].accompaniedText =
                          e.target.value;
                        setIfSettable(questionText, question);
                        forceUpdate();
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Add">
                      <IconButton
                        size="small"
                        onClick={() => {
                          console.log(`add option after ${index}`);
                          let newQuestion = new Question(question);
                          let answerOption = new AnswerOption();
                          answerOption.accompaniedText = "";
                          let answerValue = new AnswerValue();
                          answerValue.integerValue = index + 1;
                          answerOption.value = answerValue;
                          // add new answer option after index
                          newQuestion.options.splice(
                            index + 1,
                            0,
                            answerOption
                          );
                          // TODO: allow value to be anything not just index for the multiple choice questions
                          for (
                            let i = index + 2;
                            i < newQuestion.options.length;
                            i++
                          ) {
                            (newQuestion.options[i].value as AnswerValue)
                              .integerValue++;
                          }
                          setQuestion(newQuestion);
                        }}
                      >
                        <Icon arial-label="add after">add_circle</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    {
                      /* minimum 2 choices */
                      question.options.length <= 2 ? null : (
                        <Tooltip title="Remove">
                          <IconButton
                            size="small"
                            onClick={() => {
                              console.log(`remove this option ${index}`);
                              let newQuestion = new Question(question);
                              // remove the answer option at the index
                              newQuestion.options.splice(index, 1);
                              // TODO: allow value to be anything not just index for the multiple choice questions
                              for (
                                let i = index;
                                i < newQuestion.options.length;
                                i++
                              ) {
                                (newQuestion.options[i]
                                  .value as AnswerValue).integerValue = i;
                              }
                              setQuestion(newQuestion);
                              if (
                                newQuestion.options.filter(
                                  (opt) =>
                                    opt.accompaniedText &&
                                    opt.accompaniedText.length > 0
                                ).length < 1
                              ) {
                                // number of options should be 2 or more to be submittable.
                                setSubmittable(false);
                              }
                            }}
                          >
                            <Icon arial-label="remove">remove_circle</Icon>
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            onClose(
              undefined,
              undefined,
              undefined,
              undefined,
              false,
              undefined
            );
            //setQuestion(undefined);
          }}
        >
          Cancel
        </Button>
        {/* TODO: Enable OK button only if inputs are OK */}
        <Button
          disabled={!submittable}
          color="primary"
          onClick={(e) => {
            // do the clean-up of option with no text
            if (question.options) {
              question.options = question.options.filter(
                (opt) => opt.accompaniedText && opt.accompaniedText.length > 0
              );
            }
            question.isRecordAttribute = isAttribute;
            // if condition, then make it conditional
            onClose(
              shortName,
              questionText,
              isOptional,
              question,
              true,
              condition
            );
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function getDataForLocale(intl: IntlShape) {
  const questionTextLabel = intl.formatMessage(messages["app.question.text"]);
  const shortNameLabel = intl.formatMessage(messages["app.question.shortName"]);

  const questionTypeLabel = intl.formatMessage(messages["app.question.type"]);
  const fillInBlankTypeLabel = intl.formatMessage(
    messages["app.question.fillIn.blanktypeLabel"]
  );
  const optionalQuestionConfirmLabel = intl.formatMessage(
    messages["app.question.optionalLabel"]
  );
  const attributeQuestionConfirmLabel = intl.formatMessage(
    messages["app.question.isAnAttribute"]
  );
  const isConditionalLabel = intl.formatMessage(
    messages["app.question.isConditional"]
  );
  const shortNameHelp = intl.formatMessage(
    messages["app.question.shortname_help"]
  );
  const enterOptions = intl.formatMessage(
    messages["app.question.enterOptions"]
  );
  return {
    intl,
    questionTextLabel,
    optionalQuestionConfirmLabel,
    attributeQuestionConfirmLabel,
    isConditionalLabel,
    shortNameLabel,
    shortNameHelp,
    questionTypeLabel,
    fillInBlankTypeLabel,
  };
}
