import React from "react";
import { useIntl, defineMessages } from "react-intl";
import Navigation from "src/navigation/navigation";
import EditFormComp from "src/components/form/edit_form";
import { useParams } from "react-router-dom";

export function EditForm() {
  const intl = useIntl();
  const messages = defineMessages({
    formTitle: {
      id: "app.edit_form",
      defaultMessage: "Edit Form",
      description: "Edit Form",
    },
  });
  const formTitle = intl.formatMessage(messages.formTitle);

  const { formId } = useParams<{formId: string}>();

  return (
    <div className="App">
      <Navigation title={formTitle}>
        <EditFormComp formGuid={formId} />
      </Navigation>
    </div>
  );
}
