import { useState, useEffect } from "react";
import Axios from "axios";
import AppConfiguration from "src/configurations/constants";
import { RecordMetadata } from "src/services/datatypes/metadata";

/**
 * React hook to make async call to fetch the records for the form.
 * @param formGuid : the guid of the form for which we want to get the records
 */
export function useGetFormRecords(formGuid: string) {
  const [recordsArray, setRecordArray] = useState<
    undefined | RecordMetadata[]
  >();
  useEffect(() => {
    // fetch the list of records
    Axios.get(AppConfiguration.formServerUrl + "/formrecords/" + formGuid, {
      withCredentials: true
    })
      .then((response) => {
        console.log("response for list of records: " + response.data);
        const listOfRecords = response.data as RecordMetadata[];
        setRecordArray(listOfRecords);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [formGuid]);
  // TODO: return error
  return recordsArray;
}
