import React, { useState } from "react";
import { Button } from "@material-ui/core";
import NewQuestionModal from "../formelements/new_question_modal";
import { Question } from "src/proto/FormServerMessages";
import { FormModel } from "src/services/models/form_model";
import { GroupedCondition } from "src/services/models/decision_model";

export type NewQuestionButtonProps = {
  formModel: FormModel;
  onAddQuestion: (
    qLabel: string,
    qText: string,
    question: Question,
    isOptional: boolean,
    condition: GroupedCondition | undefined
  ) => void;
};

export function NewQuestionButton(props: NewQuestionButtonProps): JSX.Element {
  const [addQuestionDialog, openAddQuestionDialog] = useState(false);

  return (
    <>
      <Button
        onClick={(e) => {
          console.log("clicked the new question button");
          openAddQuestionDialog(true);
          //if (props.addQuestion) props.addQuestion(props.data);
        }}
      >
        Add Question
      </Button>
      <NewQuestionModal
        key={Math.random() * 100000}
        open={addQuestionDialog}
        isShortNameOK={(name: string) =>
          props.formModel.isShortNameConflicting(undefined, name)
        }
        formModel={props.formModel}
        condition={undefined}
        onClose={(
          qLabel: string | undefined,
          qText: string | undefined,
          isOptional: boolean | undefined,
          question: Question | undefined,
          confirmed: boolean,
          condition: GroupedCondition | undefined
        ) => {
          openAddQuestionDialog(false);
          if (confirmed && qText !== undefined && question !== undefined) {
            props.onAddQuestion(
              qLabel ? qLabel : qText,
              qText,
              question,
              isOptional !== undefined && isOptional,
              condition
            );
          }
        }}
      />
    </>
  );
}
