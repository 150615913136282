import { MenuItemType } from "../common/generic_action_menu";
import { History } from "history";

/**
 * Returns the list of entries to use in the context menu
 * when clicking the rows of the record list table
 * @param history : react-dom-router's history object
 * @param lowerLimitSubmissionTime : lower limit to apply on the submission time of records, used in when taking action on list of recrods
 * @param upperLimitSubmissionTime : upper limit to apply, if existing, on the submission time of records, used in when taking action on list of recrods
 */
export function getContextMenuRecordListRows(
  history: History<any>,
  lowerLimitSubmissionTime: number,
  upperLimitSubmissionTime: number | undefined
): MenuItemType[] {
  // TODO: take the react-int object, to provide localization of menu items
  return [
    {
      name: "view list of records",
      action: (data?: any) => {
        console.log(`pressed view ${data}`);
        history.push(`/form/records/${data}`);
      }
    },
    {
      name: "view form",
      action: (data?: any) => {
        console.log(`pressed view form ${data}`);
        history.push(`/form/view/${data}`);
      }
    },
    {
      name: "delete all in this time range",
      action: (data?: any) => {
        console.log(
          `pressed delete all records for form ${data} in time range [${lowerLimitSubmissionTime},${upperLimitSubmissionTime}]`
        );
      }
    }
  ];
}
