import React, { useState } from "react";

import { INodeModel } from "src/services/models/interface/node_model";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//import "./node_steps.css";
import {
  StepContent,
  Grid,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { IFormModel } from "src/services/models/interface/form_model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(60px, 1fr))"
      //gridAutoFlow: "dense",
    }
  })
);

type SectionStepsProps = {
  sections: INodeModel[];
  activeStep: number;
  handleSection: (sectionId: number) => void;
};
// Types of Steps: Editable, Non-editable, Mobile, Optional
// Types of Steppers: Horizontal, Vertical, Linear, Non-linear
// in case of Linear stepper, steps are automatically set to disabled={true} based on the activeStep property.

function SectionSteps(props: SectionStepsProps) {
  const classes = useStyles();
  const sections = props.sections;
  //const [activeStep, setActiveStep] = useState(0);
  const handleStep = (step: number, sectionId: number) => () => {
    //setActiveStep(step);
    props.handleSection(sectionId);
  };

  const isStepComplete = (nodeModelForStep: INodeModel) => {
    // check if all questions have been answered for the step
    return false;
  };
  if (sections.length <= 1) {
    return null;
  } else {
    /*
    Use of className={classes.root} results in runtime error in production build
       Error: Minified React error #152; visit
    return (
      <Stepper
      ..
        className={classes.root}
      >
    */
    // activeStep : zero based index; -1 disable all steps //
    return (
      <Stepper nonLinear activeStep={props.activeStep} orientation="horizontal">
        {sections.map((childNodeModel: INodeModel, index: number) => (
          <Step key={index}>
            <StepButton
              onClick={handleStep(index, childNodeModel.getId())}
              completed={isStepComplete(childNodeModel)}
            >
              {childNodeModel.getShortName() +
                (childNodeModel.isOptional() ? "*" : "")}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    );
  }
}

/**
 * One layer of sections
 */
type SectionLayerProp = {
  // list of sections = siblings + active section
  sections: INodeModel[];
  // index of active section in the list
  activeSectionIndex: number;
};

/**
 * utlitly class to deal with active section
 */
class ActiveSection {
  /**
   * Returns an array of SectionLayerProp
   * @param sectionNode : the section which user has marked as active
   */
  static setSectionNodeAsActive(
    sectionNode: INodeModel | undefined
  ): SectionLayerProp[] {
    let rowsOfSectionListInStep: SectionLayerProp[] = [];

    if (!sectionNode) {
      return [];
    }

    // get child sections ( we don't consider descendents of these children)
    const childSections = sectionNode.getChildSectionNodes();
    if (childSections.length > 0) {
      rowsOfSectionListInStep.push({
        activeSectionIndex: -1, // there is no active child
        sections: childSections
      });
    }

    // now get layers starting from current section level, go upto the root
    let nodeIdOfCurrentActive = sectionNode.getId();
    let parentNode = sectionNode.getParent();

    while (parentNode) {
      const idToCompare = nodeIdOfCurrentActive;
      // get all sections, which are sibling
      const siblingSections = parentNode.getChildSectionNodes();
      const currentActiveIndex = siblingSections.findIndex(
        (section) => section.getId() === idToCompare
      );
      rowsOfSectionListInStep.push({
        sections: siblingSections,
        activeSectionIndex: currentActiveIndex
      });
      // parent of parent is next parent
      nodeIdOfCurrentActive = parentNode.getId();
      parentNode = parentNode.getParent();
    }
    return rowsOfSectionListInStep.reverse();
  }

  /**
   * select a sibling or children of the active section or active section's ancestors' sibiling as new active section
   * @param rowsOfSectionList
   * @param activeSectionIndex
   * @param depth : depth (count starting from 0)
   */
  static toggleSectionNodeAsActiveToInactive(
    sectionNode: INodeModel
  ): SectionLayerProp[] {
    return ActiveSection.setSectionNodeAsActive(sectionNode.getParent());
  }
}

export function RowsOfSectionSteps(props: {
  leafSectionNode: INodeModel | undefined;
  handleSection: SectionStepsProps["handleSection"];
}) {
  return (
    <>
      {ActiveSection.setSectionNodeAsActive(props.leafSectionNode).map(
        (sectionListInStep, index) => (
          <SectionSteps
            key={index}
            sections={sectionListInStep.sections}
            activeStep={sectionListInStep.activeSectionIndex}
            handleSection={props.handleSection}
          />
        )
      )}
    </>
  );
}
