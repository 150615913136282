import React from "react";
import { useIntl } from "react-intl";
import Navigation from "src/navigation/navigation";
import { messages } from "src/translations/intl_messages";

type BillingCurrentPropsType = {};
export function BillingCurrent(props: BillingCurrentPropsType) {
  const intl = useIntl();

  const currentBillingLabel = intl.formatMessage(messages.billingLabel);

  return (
    <Navigation title={currentBillingLabel}>
      <div>
        Display of billing for this month, item by item billing, sum, total
        after tax, due date
      </div>
    </Navigation>
  );
}
