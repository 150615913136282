import React, { useState } from "react";
import { Button } from "@material-ui/core";
import TextEditDialog from "../common/text_edit";
import { FormModel } from "src/services/models/form_model";

export type NewSectionPopupButtonPropsType = {
  formModel: FormModel;

  onAddSection: (
    sectionTitle: string,
    sectionDescription: string | undefined
  ) => void;
};

export function NewSectionPopupButton(
  props: NewSectionPopupButtonPropsType
): JSX.Element {
  const [addSectionDialog, openAddSectionDialog] = useState(false);

  return (
    <>
      <Button
        onClick={(e) => {
          console.log("clicked the new section button");
          openAddSectionDialog(true);
          // if (props.addSection) props.addSection(props.data);
        }}
      >
        Add Section
      </Button>
      <TextEditDialog
        open={addSectionDialog}
        hintText={"New Section"}
        labelExistingText={[
          {
            labelText: "Section Name",
            existingText: undefined
          },
          {
            /* section header paragraph */
            labelText: "Text Below Section Header",
            existingText: undefined
          }
        ]}
        onClose={(newText, confirmed) => {
          openAddSectionDialog(false);
          if (confirmed && newText !== undefined && newText[0] !== undefined) {
            props.onAddSection(newText[0], newText[1]);
            console.log("new form text is " + newText[0]);
          }
        }}
      />
    </>
  );
}
