interface FormServerConfigType {
  URL: string;
  testFormGuid: string;
}
// below is to deal with Typescript error
// see https://stackoverflow.com/questions/57086672/element-implicitly-has-an-any-type-because-expression-of-type-string-cant-b
interface FormServerConfigObjectType {
  [key: string]: FormServerConfigType;
}

interface AppDeploymentEnvConfigObjectType {
  [key: string]: string;
}

// to debug .env values
// console.log(process.env.REACT_APP_FORMSERVER_ENV!);
// console.log(process.env.REACT_APP_FORM_SERVER_CONFIG!);
const REACT_APP_FORM_SERVER_CONFIG : FormServerConfigObjectType = { 
  local: {
    "URL":"http://localhost:9000/api", 
    "testFormGuid":"28ef62b6-4014-4314-acdb-807727c9c846"
  },
  alpha: {
    URL: "http://dev.bintech.in:10000/api",
    // URL: "https://alpha.forms.bintech.in:9443/api",
    testFormGuid: "28ef62b6-4014-4314-acdb-807727c9c846",
  },
  beta: {
    URL: "https://beta.forms.bintech.in:9445/api",
    testFormGuid: "28ef62b6-4014-4314-acdb-807727c9c846",
  },  
  remote: {
    "URL":"https://proj.bintech.in/api",
    "testFormGuid":"ba74f996-0be8-4098-8569-6db4a38b0677"
  },
};

export default class AppConfiguration {
  // see .env file for the values
  static formServerLocation: string = process.env.REACT_APP_FORMSERVER_ENV!;
  // static formServerConfig = (JSON.parse(
  //   process.env.REACT_APP_FORM_SERVER_CONFIG!
  // ) as FormServerConfigObjectType)[
  //   AppConfiguration.formServerLocation as string
  // ];
  static formServerConfig = REACT_APP_FORM_SERVER_CONFIG[AppConfiguration.formServerLocation as string];
  public static readonly formServerUrl =
    AppConfiguration.formServerConfig["URL"];
  public static readonly formGuidTestOnProjBintech =
    AppConfiguration.formServerConfig["testFormGuid"];
  //"https://proj.bintech.in/api": "d4fa6fe9-9a8e-4182-9de7-bcb8f18635dc";
  //"http://localhost:9000/api": "28ef62b6-4014-4314-acdb-807727c9c846";
}
