import React from "react";

export type AccountProps = {
  creationTime: Date;
  numOfForms: number;
  numOfRecordsTotal: number;
  numOfRecordsExisting: number;
  numOfRecordsTotal30Days: number;
  numOfRecordsExisting30Days: number;
};

class AccountDetail extends React.Component<AccountProps> {
  render() {
    return (
      <div>
        <h3>Account</h3>
        <b>Created at:</b> {this.props.creationTime.toString()} <br />
        <b>Number of forms</b>: {this.props.numOfForms} <br />
        <b>Number of filled forms received</b>: {this.props.numOfRecordsTotal}{" "}
        (total = deleted + stored), {this.props.numOfRecordsExisting}(stored)
        <br />
        <b>Number of filled forms (last month)</b>:{" "}
        {this.props.numOfRecordsTotal30Days} (total = deleted + stored),{" "}
        {this.props.numOfRecordsExisting30Days}(stored)
        <br />
      </div>
    );
  }
}

export default AccountDetail;
