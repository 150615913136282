import React from "react";
import { useIntl, defineMessages } from "react-intl";
import Navigation from "src/navigation/navigation";
import ViewFormComp from "src/components/form/view_form";
import { useParams } from "react-router-dom";

export function ViewForm() {
  const intl = useIntl();
  const messages = defineMessages({
    formTitle: {
      id: "app.view_form",
      defaultMessage: "View Form",
      description: "View Form",
    },
  });
  const formTitle = intl.formatMessage(messages.formTitle);

  const { formId } = useParams<{ formId: string }>();

  return (
    <div className="App">
      <Navigation
        title={formTitle}
        valuesToShare={{
          title: "Link to fill form",
          url: `${window.location.origin}${process.env.PUBLIC_URL}/fill/${formId}`,
        }}
      >
        <ViewFormComp formGuid={formId} />
      </Navigation>
    </div>
  );
}
