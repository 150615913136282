import React from "react";
import "./welcome.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { FormattedMessage } from "react-intl";
import "firebase/auth";
import { Typography, Icon, Paper, Grid } from "@material-ui/core";

// export default function App() {

//   return (
//     <IntlProvider locale={usersLocale} key={usersLocale}>
//       <div className="App">
//       <CssBaseline />
//         <Navigation />
//       </div>
//     </IntlProvider>
//   );
// }

export default function CreateForms() {
  return (
    <div>
      <Typography variant="h2" color="primary">
      Create Forms
      </Typography>
      <Typography>
        Create the form matching your need and need of your business process:
      </Typography>

      <Paper variant="outlined" square={false}>
        <ul>
          <li>
            <Typography align="justify">
              <FormattedMessage
                id="ad.create_forms_feature1"
                defaultMessage="Form with and without <b>sections</b>"
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage
                id="ad.create_forms_feature2"
                defaultMessage="Form with <b>yes/no</b>, <b>multiple choice</b>, etc questions"
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage
                id="ad.create_forms_feature3"
                defaultMessage="Make some sections or questions <b>optional</b> in form"
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage
                id="ad.create_forms_feature4"
                defaultMessage="<b>Hide</b> sections or questions based on answers given"
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage
                id="ad.create_forms_feature4"
                defaultMessage="Describe the way the filled form is <b>converted</b> to record which is easier for you to process"
                values={{
                  b: (...chunks: any) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          </li>
        </ul>
      </Paper>
      <Typography variant="h6">
        TODO: insert the sample small form here
      </Typography>
      <Typography display="block">
        For form-filling, the browser app does not need to work with the model
        of the form. The android app will need the model of the form however; as
        there is going to be just one application, that can be installed only
        once/twice.
      </Typography>
      <br></br>
      <Typography display="block">
        So, the form to fill can be created at server side when the model of
        form is submitted by form-author. This one time creation of form will
        mean static web page for the form; with needed javascript code to handle
        browser-side validation of entered values, UI customization, etc.
      </Typography>
    </div>
  );
}
