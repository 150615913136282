import React, { useState, FormEvent } from "react";
import MTextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { FormDisplayMode } from "src/components/formelements/enums";

type FormNameProps = {
  label?: string;
  name?: string;
  variant?: "h4" | "h5" | "h6";
  displayMode: FormDisplayMode;
  onFormNameChange: (formName: string) => void;
};

// makeStyles (hook generator)
// Every react-material-ui component provides a className property
// which is always applied to the root element.
// the CSS injected into the DOM by Material-UI to style a component
// has the highest specificity possible, since the <link> is injected
// at the bottom of the <head />
const useStyles = makeStyles((theme) => ({
  root: {
    // a style rule for root component
    "& .MuiTextField-root": {
      // the class name is coming from https://material-ui.com/api/text-field/#css
      margin: theme.spacing(1),
      width: "100%",
      fontSize: "2em",
      backgroundColor: "#fcfcfb",
    },
  },
}));

// withStyles (HOC) APIs which returns property injector which takes wrapped component as input
// In simplest case, the wrapped component accepts a classes prop, it simply merges the class names provided with the style sheet.
const CssTextField = withStyles({
  root: {
    "& .MuiInput-formControl": {
      textAlign: "center",
      fontSize: "large",
    },

    "& label.Mui-focused": {
      //  parent selector, &, is a special selector invented by Sass; does react-material-ui use sass ?
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "blue",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
      fontSize: "2em",
      color: "black",
      overflowWrap: "break-word",
    },
  },
})(MTextField);

export default function FormName(props: FormNameProps) {
  // keep editOn true if there is currently no form name
  const [editOn, setEditOn] = useState(props.name === undefined);

  const onFormNameChange = function (evt: { target: { value: string } }) {
    const formName = evt.target.value;
    props.onFormNameChange(formName);
    // now set the TextField to read only
    //setEditOn(false);
  };
  const onEditForm = function (_evt: FormEvent<HTMLButtonElement>) {
    // make the text field writable
    setEditOn(true);
  };

  // we need to forward the parent props to the hook as a first argument.
  const classes = useStyles();
  if (props.displayMode === FormDisplayMode.EditMode) {
    return (
      <div className={classes.root}>
        <CssTextField
          required
          label={props.label}
          value={props.name}
          variant="outlined"
          color="secondary"
          onChange={onFormNameChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") setEditOn(false);
          }}
          onBlur={(e) => {
            onFormNameChange(e);
            setEditOn(false);
          }}
          onClick={(e) => setEditOn(true)}
          autoFocus={editOn}
          disabled={!editOn}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Typography variant={props.variant}> {props.name} </Typography>
      </div>
    );
  }
}
