import React, { useState, useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebase from "firebase/app";
import "firebase/auth";
import { FormattedMessage } from "react-intl";
import { Interface } from "readline";
import { detectMobile } from "../services/detect_mobile";
import Button from "@material-ui/core/Button";
import LoginService, { firebaseUiConfig } from "../services/login_service";
import { useLanguage } from "../language/languageContext";
import { Redirect, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { isDebugMode } from "src/services/check_debug_mode";
import { useLogin } from "src/hooks/login";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
});

type PropsType = {
  childToSignIn?: React.ReactElement;
  childToSignOut?: React.ReactElement;
  redirectToOnSignIn?: string;
};

// react component for user to do login/logout
export default function Login(props: PropsType) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  // const signOnSuccessCallback = function (
  //   authResult: firebase.auth.UserCredential,
  //   redirectUrl?: string
  // ): boolean {
  //   setSignedIn(true);
  //   authResult.user?.getIdToken().then((idToken: string) => {
  //     console.log("idtoken is:" + idToken);
  //     if (idToken !== undefined) {
  //       // TODO: store login information in redux
  //       LoginService.Login(idToken);
  //     }
  //   });
  //   return false;
  // };
  // Configure FirebaseUI.
  /*   const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: detectMobile() ? "redirect" : "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/signedIn",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: signOnSuccessCallback,
    },
  }; */

  let [isSignedIn, setSignedIn] = useState(LoginService.isLoggedIn());
  let [showSigninFlow, setShowSigninFlow] = useState(false);
  const languageCode = useLanguage();

  //const signInStatus = useLogin(languageCode);
  //setSignedIn(signInStatus);

  const signoutQuestion = intl.formatMessage({
    id: "app.login.signoutQuestion",
    defaultMessage: " Do you want to sign out? ",
    description: "confirm if user wants to sign out",
  });
  const signoutButtonContent = intl.formatMessage({
    id: "app.login.signout",
    defaultMessage: "Sign-out",
    description: "sign out",
  });
  const signInButtonContent = intl.formatMessage({
    id: "app.login.sigin",
    defaultMessage: "Sign-in",
    description: "sign in",
  });
  const greeting = intl.formatMessage({
    id: "app.login.greeting",
    defaultMessage: "Hi",
    description: "greeting",
  });

  useEffect(() => {
    // TODO: make below work; it is not working
    // I don't see any languae specific login screen for google login
    // See https://firebase.google.com/docs/auth/web/google-signin#before_you_begin
    firebase.auth().languageCode = languageCode;
    setSignedIn(firebase.auth().currentUser !== null);
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        if (!user.isAnonymous) {
          setSignedIn(true);
        }
        user?.getIdToken().then((idtoken) => {
          LoginService.Login(idtoken);
          //LoginService.isLoggedIn = true;
        });
      } else if (LoginService.isLoggedIn()) {
        //LoginService.isLoggedIn = false;
        LoginService.Logout();
        setSignedIn(false);
      }
    });
  }, [languageCode]);

  const handleSignIn = () => {
    if (isDebugMode()) {
      // Handle special case of dev debug environment
      // the authentication server will not accept request from debug chrome
      // use the hard-coded token id ; if the token id is expired, sign-in
      // again using non-debug run of this app, and replace below by the received fresh id Token
      const idToken =
        "eyJhbGciOiJSUzI1NiIsImtpZCI6IjQ5ZTg4YzUzNzYxOTk2YTczNjIzZjE5MWQ1MTJkMmI0N2RmODAyYTEiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiUmF2aW5kcmEgUyIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vLUk4MlBLMmVfNmc4L0FBQUFBQUFBQUFJL0FBQUFBQUFBQUFBL0FNWnV1Y21iajBvLUhoU1lOZWI0MllENy1XeVJFcmY1VHcvcGhvdG8uanBnIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2FuZHJvaWQtYXBwcy00NjJlMCIsImF1ZCI6ImFuZHJvaWQtYXBwcy00NjJlMCIsImF1dGhfdGltZSI6MTU5NzUyNjQ4OCwidXNlcl9pZCI6ImQxb1laU3BqWm9US1VpenpEMGpVYU1zQVBhdzEiLCJzdWIiOiJkMW9ZWlNwalpvVEtVaXp6RDBqVWFNc0FQYXcxIiwiaWF0IjoxNTk3NTU1ODc1LCJleHAiOjE1OTc1NTk0NzUsImVtYWlsIjoicmtzYW5kZTFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMDM1NzQ2ODg4MjQ5MjczOTY4NDAiXSwiZW1haWwiOlsicmtzYW5kZTFAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.I74f6m9isomeyY8eakOQXAj4UQ04DsMML9OhxT0S8uTyTkzjOJ3mE0qSvOPgc_fcJB2Xj2yUB1M-3O2P25Qb9Zes2xNfLMoELMpuTBDUNZdsxk2K3rvAindP2yHPyv0jB2skFR2vsTxpuRWTkmqgtGqOMCl3t8nHt8Q5RcqKIbD-CKSXY1-Eu_R-P7jg7j5SNslNIQCNupOFVjgIIQBbkCfS0YRTyVHj80lveeRPmc2v6991b9V5AOdo0JvkGXGYSVUaIpymcukLExik9W0MMZPHOWmzQptegaHylYQdyPB5hbmjXAEsTB2eOwDr6wSfi5o8KiDHvIyT36199tZweA";
      LoginService.Login(idToken);
      // LoginService.isLoggedIn = true;
      setSignedIn(true);
    } else {
      setShowSigninFlow(true);
    }
  };
  return (
    <div className={classes.root}>
      {isSignedIn ? (
        props.redirectToOnSignIn ? (
          <Redirect to={props.redirectToOnSignIn} />
        ) : (
          <div>
            <p>
              {greeting} {firebase.auth().currentUser?.displayName},{" "}
              {signoutQuestion}!
            </p>
            <Button
              color="inherit"
              onClick={() => {
                LoginService.Logout();
                firebase.auth().signOut();
                setSignedIn(false);
              }}
            >
              {signoutButtonContent}
            </Button>
          </div>
        )
      ) : showSigninFlow ? (
        <div>
          <p>
            <FormattedMessage id="app.signin" defaultMessage="Please Sign-in" />
          </p>
          <StyledFirebaseAuth
            uiConfig={firebaseUiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      ) : (
        <Button color="inherit" onClick={() => handleSignIn()}>
          {signInButtonContent}
        </Button>
      )}
    </div>
  );
}
