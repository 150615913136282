//import grecaptcha from "grecaptcha";
// https://www.google.com/recaptcha/admin/site/440076231/setup
// under my account
import React, { Component, createRef } from "react";
import PropTypes from "prop-types";

// below code is from https://github.com/codeep/react-recaptcha-google/blob/master/src/ReCaptcha.js

// The recaptcha key for bintech.in
const TEST_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // see https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
const BINTECH_RECAPTCHA_PRODUCTION_SITE_KEY = "6LfHBzsaAAAAAFPySTNI_tzKItzXoO50uS5_sOvb";
const BINTECH_RECAPTCHA_SITE_KEY = TEST_KEY;

// ES-Lint directive to ignore undef error on grecaptcha
/*global grecaptcha*/

const noop = () => undefined;

class ReCaptcha extends Component {
  static defaultProps = {
    badge: "bottomleft",
    hl: "en",
    inherit: true,
    isolated: false,
    onError: () => {console.error("error in recaptcha"); },
    onExpired: noop,
    onLoad: noop,
    onSuccess: noop,
    sitekey: BINTECH_RECAPTCHA_SITE_KEY,
    size: "normal",
    tabIndex: 0,
    theme: "light",
    type: "image"
  };
  static propTypes = {
    //  'inline' lets you position it with CSS.
    badge: PropTypes.oneOf(["bottomright", "bottomleft", "inline"]),
    inherit: PropTypes.bool,
    isolated: PropTypes.bool,
    onError: PropTypes.func,
    onExpired: PropTypes.func,
    onLoad: PropTypes.func,
    onSuccess: PropTypes.func,
    sitekey: PropTypes.string,
    size: PropTypes.oneOf(["compact", "normal", "invisible"]),
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    theme: PropTypes.oneOf(["dark", "light"])
  };
  isGrecaptchaReady = () =>
    typeof window !== "undefined" && typeof window.grecaptcha !== "undefined" && typeof window.grecaptcha.render === 'function';
  // check for isGrecaptchaReady() until it becomes true, every 1 second
  readyIntervalId = 0;
  recaptcha = createRef();

  state = {
    ready: false
  };
  componentDidMount() {
    this.readyIntervalId = setInterval(() => this._updateReadyState(), 1000)
  }
  componentWillUnmount() {
    clearInterval(this.readyIntervalId);
  }
  componentDidUpdate(prevProps, prevState) {
    // call grecaptach to render this component once isGrecaptchaReady() changes to true.
    if (!prevState.ready && this.state.ready) {
      // https://developers.google.com/recaptcha/docs/display#js_api
      console.log("rendering recaptcha")
      this.widgetId = grecaptcha.render(
        this.recaptcha.current, // container, this component
        {
          "error-callback": this.props.onError,  //  executed when reCAPTCHA encounters an error (usually network connectivity)
          "expired-callback": this.props.onExpired,  //  executed when the reCAPTCHA response expires and the user needs to re-verify
          badge: this.props.badge,
          callback: this.props.onSuccess, //executed when the user submits a successful response.
          isolated: this.props.isolated, //  If true, this reCAPTCHA instance will be part of a separate ID space.
          sitekey: this.props.sitekey,
          size: this.props.size,
          tabindex: this.props.tabIndex,
          theme: this.props.theme
        },
        this.props.inherit
      );
    }
  }

  // For use by component user to reset recaptcha
  reset = () => {
    grecaptcha.reset(this.widgetId);
  };

  // For use by component user to invoke recaptcha challenge
  execute = () => {
    grecaptcha.execute(this.widgetId);
  };

  _updateReadyState = () => {
    if (this.isGrecaptchaReady()) {
      this.setState(() => ({
        ready: true
      }));
      clearInterval(this.readyIntervalId);
      this.props.onLoad();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !this.state.ready && nextState.ready;
  }

  render() {
    const {
      onError,
      onExpired,
      onLoad,
      onSuccess,
      inherit,
      isolated,
      sitekey,
      theme,
      type,
      size,
      badge,
      tabIndex,
      ...rest
    } = this.props;

    return (
      <div
        ref={this.recaptcha}
        data-sitekey={BINTECH_RECAPTCHA_SITE_KEY}
        data-theme={theme}
        data-type={type}
        data-size={size}
        data-badge={badge}
        data-tabindex={tabIndex}
        {...rest}
      />
    );
  }
}

export default ReCaptcha;