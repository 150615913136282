import { IntlShape } from "react-intl";
import { RecordMetadata } from "src/services/datatypes/metadata";
import { TableColumns, FormRecordMetaData } from "./record_list";

export function getTableRowsFromRecordArray(
  recordsArray: RecordMetadata[] | undefined,
  lowerLimitSubmissionTime: number,
  upperLimitSubmissionTime: number | undefined,
  intl: IntlShape
): TableColumns[] {
  return recordsArray === undefined
    ? []
    : Object.values(
        recordsArray
          .filter(
            (elem: RecordMetadata) =>
              elem.recordDate > lowerLimitSubmissionTime &&
              (upperLimitSubmissionTime === undefined ||
                elem.recordDate <= upperLimitSubmissionTime)
          )
          .reduce(
            (
              displayRecords: { [key: string]: FormRecordMetaData },
              elem: RecordMetadata
            ) => {
              const key = JSON.stringify([elem.formGuid, elem.formVersion]);
              displayRecords[key] = {
                formGuid: elem.formGuid,
                // TODO: return form name in the record metadata
                // we should be storing the form name in record metadata
                // it doesn't seem a good idea to make multiple request to get the form-name
                formName: elem.formName,
                version: elem.formVersion,
                numRecords: displayRecords[key]
                  ? displayRecords[key].numRecords + 1
                  : 1,
                lastCreated: displayRecords[key]
                  ? Math.max(elem.recordDate, displayRecords[key].lastCreated)
                  : elem.recordDate
              };
              return displayRecords;
            },
            {}
          )
      ).map((elem: FormRecordMetaData) => {
        return {
          formGuid: elem.formGuid,
          formName: elem.formName,
          version: elem.version,
          numRecords: elem.numRecords,
          lastCreated: intl.formatDate(new Date(elem.lastCreated * 1000))
        };
      });
}
