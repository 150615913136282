import React from "react";
import { useIntl, defineMessages } from "react-intl";
import Navigation from "src/navigation/navigation";
import FillFormComp from "src/components/form/fill_form";
import { useParams } from "react-router-dom";

export function FillForm() {
  const intl = useIntl();
  const messages = defineMessages({
    formTitle: {
      id: "app.fill_form",
      defaultMessage: "Fill Form",
      description: "Fill Form",
    },
  });
  const formTitle = intl.formatMessage(messages.formTitle);

  const { formLinkId } = useParams<{formLinkId:string}>();

  return (
    <div className="App">
      <Navigation title={formTitle}>
        <FillFormComp formGuid={formLinkId} />
      </Navigation>
    </div>
  );
}
