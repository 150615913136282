import React from "react";
import { useIntl, defineMessages } from "react-intl";
import Navigation from "src/navigation/navigation";
import { useParams } from "react-router-dom";
import ViewRecord from "src/components/form/view_record";
import ViewRecordRaw from "src/components/form/view_record_raw";
import { messages } from "src/translations/intl_messages";

type RecordPropsType = {};
export function Record(props: RecordPropsType) {
  const intl = useIntl();
  const recordTitle = intl.formatMessage(messages.recordViewTitle);

  const { recordGuid } = useParams<{ recordGuid: string }>();

  return (
    <div className="App">
      <Navigation title={recordTitle}>
        <ul>
          <li>Display of record data.</li>
          <li>
            Information about record: time of creation, person who submitted
            record, linked form, ...
          </li>
          <li>
            Actions that can be taken on record: add state, attach/remove
            message
          </li>
          <li>History of record data/actions</li>
        </ul>
        <ViewRecordRaw recordGuid={recordGuid} />

        <ViewRecord recordGuid={recordGuid} />
      </Navigation>
    </div>
  );
}
