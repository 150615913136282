import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IFormModel } from "src/services/models/interface/form_model";
import { INodeModel } from "src/services/models/interface/node_model";

type IndexedText = {
  id: number;
  text: string;
  groupName: string;
};
type TextChooserPropsType = {
  textArray: IndexedText[];
  onChange: (index: IndexedText) => void;
  useGroup?: boolean;
  value?: IndexedText;
};
/**
 * This is the component to choose one from list of options of texts
 * @param props : array of text along with index which points to some data
 */
export function TextChooser(props: TextChooserPropsType) {
  return (
    <Autocomplete
      id="combo-box-demo"
      fullWidth
      options={props.textArray}
      getOptionLabel={(indexedText: IndexedText) => indexedText.text}
      groupBy={props.useGroup ? (option) => option.groupName : undefined}
      value={props.value}
      onChange={(e, val) => {
        // REVIEW: is e.target.value value field of <MenuItem> or is it string ?
        // setNodeId(parseInt(e.target.value as string));
        if (val) props.onChange(val);
      }}
      renderInput={(params: any) => <TextField {...params} label="Question" />}
    />
  );
}
/**
 * Creates an array of IndexedText from questions in the form
 * @param formModel
 */
export function GetQuestionsText(formModel: IFormModel): IndexedText[] {
  // TODO: get number of sections
  // if number of sections more than 1, group the questions by section names
  const hasSections = formModel.hasSections();
  return formModel.getQuestionNodes().map((qNode) => {
    return {
      id: qNode.getId(),
      text: qNode.getShortName(),
      groupName: hasSections
        ? qNode.getParent()
          ? (qNode.getParent() as INodeModel).getShortName()
          : ""
        : ""
    };
  });
}

/**
 * Shows the UI to select question.
 * onChange is called with node id of question if a question is selected.
 * @param props
 */
export function QuestionChooser(props: {
  formModel: IFormModel;
  nodeId?: number; // question that is choosen as of now
  onChange: (id: number) => void;
}) {
  const hasSections = props.formModel.hasSections();
  const questionArray = GetQuestionsText(props.formModel);
  return (
    <TextChooser
      textArray={questionArray}
      value={
        props.nodeId
          ? questionArray.find((val) => val.id === props.nodeId)
          : undefined
      }
      onChange={(val: IndexedText) => props.onChange(val.id)}
      useGroup={hasSections}
    />
  );
}
