import { Form } from "src/proto/FormServerMessages";
import AppConfiguration from "src/configurations/constants";
import axios, { AxiosResponse } from "axios";
import { AccountTokenPair } from "src/services/datatypes/metadata";
import FormData from "form-data";
import util from "util";
import * as firebase from "firebase/app";
import { detectMobile } from "../services/detect_mobile";

// Configure Firebase.
const config = {
  apiKey: "AIzaSyBpCqKXAClqxE3g3O6BuQ7F3xCoXzn1cMo",
  //authDomain: "android-apps-462e0.firebaseapp.com",
  authDomain: "app.bintech.in",
  databaseURL: "https://android-apps-462e0.firebaseio.com",
  projectId: "android-apps-462e0",
  storageBucket: "android-apps-462e0.appspot.com",
  messagingSenderId: "348048083410",
  appId: "1:348048083410:web:9af4c0736d16a599e3200b",
  measurementId: "G-JWT06WTG18",
};

// Initialize Firebase
firebase.initializeApp(config);

const signOnSuccessCallback = function (
  authResult: firebase.auth.UserCredential,
  redirectUrl?: string
): boolean {
  // setSignedIn(true);
  authResult.user?.getIdToken().then((idToken: string) => {
    console.log("idtoken is:" + idToken);
    if (idToken !== undefined) {
      // TODO: store login information in redux
      LoginService.Login(idToken);
    }
  });
  return false;
};

// Configure FirebaseUI.
export const firebaseUiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: detectMobile() ? "redirect" : "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: "/signedIn",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: signOnSuccessCallback,
  },
};
export default class LoginService {
  public static isLoggedIn(): boolean {
    if (firebase.auth().currentUser) {
      if (firebase.auth().currentUser?.isAnonymous) {
        return false;
      } else {
        return true;
      }
    } else {
      this.signInAnonymously();
      return false;
    }
  }
  public static signInAnonymously() {
    firebase
      .auth()
      .signInAnonymously()
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log("anonymous signin error: ", errorCode, errorMessage);
      });
  }
  /**
   * Send the fireauth idToken to server so that it can set the session cookie.
   *
   * @return Promise object
   */
  public static Login(idToken: string): Promise<AxiosResponse<any>> {
    // LoginService.isLoggedIn = true;
    const url = AppConfiguration.formServerUrl + "/login";
    const data = {
      idToken: idToken,
    };
    return axios.post(url, data, { withCredentials: true });
  }
  /**
   * Send request to server so that it can clear the session cookie
   */
  public static Logout() {
    if (!this.isLoggedIn()) return;
    // LoginService.isLoggedIn = false;
    const url = AppConfiguration.formServerUrl + "/logout";
    return axios.post(url, "", {
      withCredentials: true,
    });
  }
  public static getUserName() {
    return firebase.auth().currentUser?.displayName;
  }
  public static getUserImage() {
    return firebase.auth().currentUser?.photoURL;
  }
}
