import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link/Link";
import React, { useState } from "react";
import { Question } from "src/proto/FormServerMessages";
import { FormModel } from "src/services/models/form_model";
import { IFormModel } from "src/services/models/interface/form_model";
import { NodeModel } from "src/services/models/node_model";
import { FormDisplayMode } from "../enums";
import QuestionView from "../question";

/**
 * Clickable question shows the short name of the question and clicking
 * it opens the dialog view which shows the complete question.
 *
 * This view is helpful to present the conditions in a concise way.
 *    answer to [question] equals/not equals/... [value]
 * where question is the text shown using this view.
 */
export function ClickableQuestion(props: {
  formModel: IFormModel;
  nodeId: number;
}) {
  const [open, setOpen] = useState(false);
  const question = props.formModel.getNode(props.nodeId) as NodeModel;
  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography display="block" variant="body2">
          {question.getShortName()}
        </Typography>
      </Link>

      <Dialog
        fullWidth
        onClose={(e) => setOpen(false)}
        aria-labelledby={"question"}
        open={open}
      >
        <DialogContent>
          <QuestionView
            formModel={props.formModel}
            displayMode={FormDisplayMode.EditMode}
            question={question}
            onUpdate={() => {}}
            isShortNameOK={(name) => true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpen(false)}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
