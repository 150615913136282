import React from "react";
import FormListComponent from "src/components/form_list/form_list";
import Navigation from "src/navigation/navigation";
import { useIntl, defineMessages } from "react-intl";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import { Link } from "react-router-dom";
import { messages } from "src/translations/intl_messages";

type FormListPropsType = {};
export function FormList(props: FormListPropsType) {
  const intl = useIntl();
  const createForm = intl.formatMessage(messages.createForm);
  const formListTitle = intl.formatMessage(messages.formList);

  const createFormButton = (
    <Button variant="contained" startIcon={<CreateIcon />}>
      {createForm}
    </Button>
  );

  return (
    <div className="App">
      <Navigation title={formListTitle}>
        <div>
          List of forms, pagination, action to take on selected form
          <FormListComponent />
        </div>
        <Container>
          <Link to="/form/create">{createFormButton}</Link>
        </Container>
      </Navigation>
    </div>
  );
}
