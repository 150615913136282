import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, FormikBag } from "formik";
import * as Yup from "yup";
import { boolean } from "yup";
// import { DisplayFormikState } from './formikHelper';

const styles = {};

const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT || "";

// https://medium.com/codefully-io/react-forms-validation-with-formik-and-material-ui-1adf0c1cae5c
function Contact(props: { classes: any }) {
  const { classes } = props;
  const [open, setDialogOpen] = useState(false);
  const [isSubmissionCompleted, setSubmissionCompleted] = useState(false);

  function handleClose() {
    setDialogOpen(false);
  }

  function handleClickOpen() {
    setSubmissionCompleted(false);
    setDialogOpen(true);
  }

  const formikSubmitHandler = (values:any, formikBag: any) => {
    formikBag.setSubmitting(true);
    axios
      .post(contactFormEndpoint, values, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((_resp) => {
        setSubmissionCompleted(true);
      });
  }

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Contact us!
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmissionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Contact</DialogTitle>
            <DialogContent>
              <DialogContentText>Send us a comment!</DialogContentText>
              <Formik
                initialValues={{ email: "", name: "", comment: "" }}
                onSubmit={formikSubmitHandler}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required("Required"),
                  name: Yup.string().required("Required"),
                  comment: Yup.string().required("Required"),
                })}
              >
                {(formikProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = formikProps;
                  return (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        label="name"
                        name="name"
                        className={classes.textField}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && errors.name}
                        margin="normal"
                      />

                      <TextField
                        error={Boolean(errors.email && touched.email)}
                        label="email"
                        name="email"
                        className={classes.textField}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        margin="normal"
                      />

                      <TextField
                        label="comment"
                        name="comment"
                        className={classes.textField}
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.comment && touched.comment && errors.comment
                        }
                        margin="normal"
                      />
                      <DialogActions>
                        <Button
                          type="button"
                          className="outline"
                          onClick={handleReset}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                        {/* <DisplayFormikState {...props} /> */}
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmissionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" className="outline" onClick={handleClose}>
                  Back to app
                </Button>
                {/* <DisplayFormikState {...props} /> */}
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styles)(Contact);
