import React from "react";
import { useIntl } from "react-intl";
import { GroupedCondition } from "src/services/models/decision_model";
import { IFormModel } from "src/services/models/interface/form_model";
import { ClickableQuestion } from "./clickable_question";
import Grid from "@material-ui/core/Grid";
import { ConditionTypeToLabelMap } from "src/components/enum_to_labels";
import protobufTypes from "src/proto/FormServerMessages";

/**
 * [Form Name]
 *
 * Condition name     Condition Text                          Condition Impact
 *
 * [condition name]     answer to [question short name]       [section/question] [short name] is made [hidden/optional]
 *                      [is/is not/is one of] [values],                           and
 *                                   and
 *
 *    car insurance     answer to "insurace wanted"           section "life insurance" is made "hidden"
 *                      is "car insurance"
 *
 *
 *  actions : on condtion name -> edit/delete/create new based on this
 *            on question short name -> click opens up the pop up showing the question with button to edit it
 */

// for reason of this kind of declaration of function
// see https://stackoverflow.com/questions/58311442/type-element-is-missing-the-following-properties-from-type-element-type
const ConditionClause: Function = (props: {
  groupCondition: GroupedCondition;
  formModel: IFormModel;
}): JSX.Element[] => {
  // iterate over all conditions of the group
  // produce them as text
  const cTypeToLabel = ConditionTypeToLabelMap(useIntl());
  return props.groupCondition.nodeConditions.map((nodeCond) => {
    return (
      <React.Fragment key={nodeCond.getNodeId()}>
        <Grid container spacing={2}>
          <Grid item>
            <ClickableQuestion
              formModel={props.formModel}
              nodeId={nodeCond.getNodeId()}
            />
          </Grid>
          <Grid item>
            {cTypeToLabel.get(
              protobufTypes.ConditionType[nodeCond.getConditionType()]
            )}
          </Grid>
          <Grid item>Option {nodeCond.getRefValue().integerValue}</Grid>
        </Grid>
      </React.Fragment>
    );
  });
};

export default ConditionClause;
