import { TableHeaderCellPropType } from "./form_records";

/**
 * TODO: get attributes from the records, and create the column to show them in the table
 * allow the sorting by those attribute values.
 */
export function getFormRecordTableHeaderCellProps(
  attrNames: string[]
): TableHeaderCellPropType[] {
  // TODO: react-int localization for the labels
  const tableHeaderCellProps: TableHeaderCellPropType[] = [
    {
      id: "creationTime",
      rightAlign: false,
      disablePadding: true,
      label: "Date of Record Submission",
      sortable: true
    },
    {
      id: "version",
      rightAlign: true,
      disablePadding: false,
      label: "Form Version"
    }
  ];
  // below is not taking account the type of attribute values
  // TODO: take into account type of attribute values,
  //  think about what will be different types of attributes, and
  // how we want to handle those different types of attributes.
  // E.g. an attibute can be date, so it should be displayed in date specific manner
  //   for example, using relative time, 2 days ago, 5 hours ago.
  // Attributes will be a big item to think about, as it might involve tags, and so on.

  // add attribute headCells to fixed head cells.
  return tableHeaderCellProps.concat(
    attrNames
      .map((attr) => {
        return {
          id: attr,
          rightAlign: true,
          disablePadding: true,
          label: attr,
          sortable: true
        };
      })
      .flat()
  );
}
