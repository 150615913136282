import React, { useState, useReducer, useEffect } from "react";
import { Form, Node, FilledForm } from "src/proto/FormServerMessages";
import SectionView from "./section";
import { FormModel } from "src/services/models/form_model";
import Snackbar from "@material-ui/core/Snackbar";

import { useIntl } from "react-intl";

import Button from "@material-ui/core/Button";

import "./form.css";
import { RowsOfSectionSteps } from "src/components/form/sectionSteps";
import { IconButton } from "@material-ui/core";
import FormName from "./formname/formname";

import CloseIcon from "@material-ui/icons/Close";
import { FilledFormModel } from "src/services/models/filled_form_model";
import {
  DecisionType,
  FormDisplayMode,
} from "src/components/formelements/enums";
import FormFooter from "../form/form_footer";
import {
  MenuItemType,
  GenericActionMenuCreator,
} from "../common/generic_action_menu";

import EditIcon from "@material-ui/icons/Edit";
import FormService from "src/services/form_service";
import { FormMetadata } from "src/services/datatypes/metadata";

import MD5 from "crypto-js/md5";
import RecordService from "src/services/record_service";
import LoginService from "src/services/login_service";
import FormDescr from "./form_descr/form_descr";
import { IFormModel } from "src/services/models/interface/form_model";
import { ConditionTable } from "./conditions/condition_table";
import { messages } from "src/translations/intl_messages";
import { INodeModel } from "src/services/models/interface/node_model";

// Form has 2-3 screens
//  one screen displays questions/sections
//  other screen displays conditions in the form
// How will user move between different screens ?
//   add button in case of other view to leave the secondary view (e.g. conditions view)
//   may be, simple GO BACK button
export enum FormScreenType {
  QuestionsScreen,
  ConditionsScreen,
}
type FormViewProps =
  | {
      form?: IFormModel;
      displayMode: FormDisplayMode.EditMode;
    }
  | {
      filledForm: FilledFormModel;
      // the only difference between fillmode and view mode is that
      // in fill mode, filled form is submitted (submit button exists)
      // in view mode, submit button doesn't exist
      // however, user is allowed to see how his entering of form changes
      // the display of the form; so that he can take the corrective actions
      // before publishing the form.
      displayMode:
        | FormDisplayMode.FillMode
        | FormDisplayMode.RecordMode
        | FormDisplayMode.ViewMode;
    };

export default function FormView(props: FormViewProps) {
  const intl = useIntl();

  let inputForm: IFormModel | undefined =
    props.displayMode !== FormDisplayMode.EditMode
      ? props.filledForm?.form
      : props.form; /*=== undefined
      ? FormModel.newForm("form title")
      : props.form*/

  // use below for edit
  // in case of React, we need state if we want this and child elements to change
  // rendering. In react, state shared by components is lifted to the common ancestor
  // of those components. Here, we need to declare the functions that will manipulate
  // the form object, and will pass those in the props to children to use.
  const [form, setForm] = useState<IFormModel | undefined>(inputForm);

  const NO_ACTIVE_SECTION = form ? form.getRoot().getId() : -1;
  const [activeSectionIndex, setActiveSectionIndex] = useState(
    NO_ACTIVE_SECTION
  );

  const [formScreenType, setFormScreenType] = useState(
    FormScreenType.QuestionsScreen
  );

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(
    "Saved the form"
  );
  // TODO: add the condition checkers to make the form submittable
  // conditions: 1) form name should be more than a character if editiing form
  //   2) there should be at least one question in form ??
  //   3) if it is form to fill, then all required answers have been filled if user is not allowed to create draft of answers.
  const [submittable, setSubmittable] = useState(
    form !== undefined && form.getTitle() !== undefined
  );

  const [] = useState(
    (props.displayMode === FormDisplayMode.EditMode ||
      props.displayMode === FormDisplayMode.ViewMode) &&
      form &&
      form.getQuestionNodes().length > 0
  );

  const handleClickSection = (sectionId: number) => {
    // toggle active section
    if (activeSectionIndex === sectionId) {
      setActiveSectionIndex(NO_ACTIVE_SECTION);
    } else {
      setActiveSectionIndex(sectionId);
    }
  };

  const handleAddSection = (sectionTitle: string, sectionDetail?: string) => {
    if (form === undefined) return;
    const section = FormModel.newSection(
      activeSectionIndex !== NO_ACTIVE_SECTION
        ? (form.getNode(activeSectionIndex) as INodeModel)
        : form.getRoot(),
      sectionTitle,
      sectionDetail
    );
    forceUpdate();
  };

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  // creating updateDisplay to deal with issue mentioned in http://dev.bintech.in:9002/T42
  // (props.filledForm.form is updated on condition change, but form state variable is not updated)
  const updateDisplayOnFilledFormChange = () => {
    // we set the form, as the answer change results in condition change, which results in
    // change in visibility of form nodes, registered in props.filledForm.form
    if (props.displayMode !== FormDisplayMode.EditMode) {
      // to deal with state form being different from props.filledForm.form
      // Q: why props.filledForm.form and state form have different contained nodeModels ?
      // Answer: because the JSX component wrapping FormView is going through state changes
      setForm(props.filledForm.form);
      // to refresh UI, because above will not refresh if props.filledForm.form and state form are reference to same object.
      forceUpdate();
    }
  };

  let activeSectionNodeModel = form?.getNode(activeSectionIndex);

  // close the snack bar shown for notification
  const handleCloseSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const [formMetadata, setFormMetadata] = useState<FormMetadata | undefined>(
    undefined
  );
  useEffect(() => {
    let formGuidInput = undefined;
    if (props.displayMode === FormDisplayMode.EditMode) {
      if (props.form !== undefined) {
        formGuidInput = props.form.getForm()?.guid;
      }
    } else if (props.filledForm !== undefined) {
      formGuidInput = props.filledForm.form.getForm()?.guid;
    }
    if (formGuidInput !== undefined) {
      FormService.getFormMetadata(formGuidInput)
        .then((response) => setFormMetadata(response.data as FormMetadata))
        .catch((e) => {
          // TODO: show the error to user
          console.error(`Problem in getting the form metadata: ${e}`);
        });
    }
  }, []);
  const [, setFormChanged] = useState(false);
  const saveChangedForm = () => {
    console.log("save changed form is called");
    const changedForm = form?.getForm();
    if (/*formChanged &&*/ changedForm !== undefined) {
      let formMetaDataValue: FormMetadata;
      if (formMetadata !== undefined) {
        formMetaDataValue = formMetadata;
        formMetaDataValue.formName = (changedForm.node as Node).shortName;
        formMetaDataValue.version++;
        formMetaDataValue.lastUpdateTime = Date.now();
        formMetaDataValue.hash = MD5(
          Buffer.from(Form.encode(changedForm).finish()).toString()
        ).toString(); //"0xsdf",
      } else {
        formMetaDataValue = {
          guid: changedForm.guid,
          accountId: "",
          version: 1,
          synched: false,
          formName: (changedForm.node as Node).shortName, //changedForm.node?.title,
          hash: MD5(
            Buffer.from(Form.encode(changedForm).finish()).toString()
          ).toString(), //"0xsdf",
          creationTime: Date.now(),
          lastUpdateTime: Date.now(),
        };
      }
      console.log("calling postForm");
      FormService.postForm(changedForm, formMetaDataValue)
        .then(
          () => {
            setFormChanged(false);
            setSnackbarMessage("Successfully saved");
            setOpenSnackBar(true);
          },
          (reason: any) => {
            console.error(`some error in postForm: ${reason}`);
          }
        )
        .catch((e) => {
          // TODO: display  the error to user
          console.error(`failed to save form changes: ${JSON.stringify(e)}`);
          setSnackbarMessage(`Failed to save: ${JSON.stringify(e)}`);
          setOpenSnackBar(true);
        });
    }
  };
  const submitFilledForm = onSubmitFilledForm(
    props,
    formMetadata,
    setSnackbarMessage,
    setOpenSnackBar
  );

  // REDUX: Redux is not an option: we can NOT store IFormModel object as state in Redux
  // because Redux requires that state be immutable, and any change in state
  // be done on copy  of the old state. We don't know how to create copy in easy way (:=> use Immer);
  // thinking about the actions which creates new IFormModel object is going to be perhaps
  // duplicate what we will be doing below.

  // IMMER: Immer is perhaps option, in combination with Redux, or to deal with difficulties in below approach.
  const changeFormName = (name: string) => {
    setForm((oldForm) => {
      let newForm = oldForm;
      // if there was no form; i.e. creating the form first time
      if (newForm === undefined) {
        newForm = FormModel.newForm(name);
        setActiveSectionIndex(newForm.getRoot().getId());
      }
      setSubmittable(true);
      newForm?.setTitle(name);
      setFormChanged(true);
      return newForm;
    });
    // WORK-AROUND: React hook uses Object.is for comparing the previous and new state, and won't trigger a re-render
    // if previous object and new object use the same address (reference). Therefore, below work-around is used.
    // If it is not wanted, convert the react function to react component.
    // See https://blog.logrocket.com/a-guide-to-usestate-in-react-ecb9952e406c/ and https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    forceUpdate();
  };

  const changeFormText = (text: string | undefined) => {
    setForm((oldForm) => {
      let newForm = oldForm;
      newForm?.setText(text === undefined ? "" : text);
      return newForm;
    });
    setFormChanged(true);

    // WORK-AROUND: React hook uses Object.is for comparing the previous and new state, and will trigger a re-render
    forceUpdate();
  };
  // corresponds to NodeModel's constructor
  // the child react components can use to attach new node to the form
  /*
  const createNodeModel = (
    containerNode: NodeModel | undefined,
    node: Node
  ) => {
    const newNode = new NodeModel(form, containerNode, node);
    // WORK-AROUND: React hook uses Object.is for comparing the previous and new state, and won't trigger a re-render
    forceUpdate();
    return newNode;
  };
  */

  // language specific texts
  const formNameLabel = intl.formatMessage(messages.formName);
  const formDescrLabel = intl.formatMessage(messages.formDescr);
  const [, setOpenFormTextEdit] = useState(false);
  const menuItems: MenuItemType[] = [
    {
      name: "Edit this",
      action: () => {
        setOpenFormTextEdit(true);
      },
    },
  ];
  const FormTextEditMenu = GenericActionMenuCreator(menuItems);
  // language specific texts
  const isShortNameOK = (nodeId: number | undefined, name: string) =>
    form ? form.isShortNameConflicting(nodeId, name) : true;

  // TODO: provide the form/filled form submit button in case of display mode other than view.
  return (
    <div>
      <FormName
        name={form?.getRoot().getShortName()}
        label={formNameLabel}
        variant="h4"
        onFormNameChange={changeFormName}
        displayMode={props.displayMode}
      ></FormName>
      <FormDescr
        descr={form?.getRoot().getTextToDisplay()}
        label={formDescrLabel}
        variant="h6"
        onFormDescrChange={changeFormText}
        displayMode={props.displayMode}
      ></FormDescr>
      {formScreenType === FormScreenType.QuestionsScreen ? (
        <>
          <RowsOfSectionSteps
            leafSectionNode={form?.getNode(activeSectionIndex)}
            handleSection={handleClickSection}
          />
          {/** TODO: pass the FilledFormModel to QuestionView and SectionView
                    so that answers of users can be captured.
                    nodeModel is coming from IFormModel which only describes the structure of form
                    and is useful for editing; but we additionally need to capture the inputs of the user.
                   */}
          <div className="formView">
            <form>
              {activeSectionNodeModel ? (
                props.displayMode === FormDisplayMode.EditMode ? (
                  <SectionView
                    section={activeSectionNodeModel}
                    formModel={form as IFormModel}
                    displayMode={props.displayMode}
                    onUpdate={forceUpdate}
                    isShortNameOK={isShortNameOK}
                  />
                ) : (
                  <SectionView
                    section={activeSectionNodeModel}
                    filledForm={props.filledForm}
                    displayMode={props.displayMode}
                    onUpdate={updateDisplayOnFilledFormChange}
                  />
                )
              ) : null}
              <input type="submit" value="Submit" className="hidden" />
            </form>
          </div>
        </>
      ) : (
        <ConditionTable
          formModel={form as FormModel}
          displayMode={props.displayMode}
        />
      )}
      <FormFooter
        formModel={form as FormModel}
        displayMode={props.displayMode}
        submit={submittable}
        prev={form?.getNode(activeSectionIndex)?.getPrevSection() !== undefined}
        next={form?.getNode(activeSectionIndex)?.getNextSection() !== undefined}
        onPrevButtonClick={() => {
          setActiveSectionIndex(
            form
              ?.getNode(activeSectionIndex)
              ?.getPrevSection()
              ?.getId() as number
          );
        }}
        onNextButtonClick={() => {
          setActiveSectionIndex(
            form
              ?.getNode(activeSectionIndex)
              ?.getNextSection()
              ?.getId() as number
          );
        }}
        onSubmit={() =>
          props.displayMode === FormDisplayMode.EditMode
            ? saveChangedForm()
            : submitFilledForm()
        }
        onAddSection={handleAddSection}
        onAddQuestion={(qLabel, qText, question, isOptional, condition) => {
          if (form === undefined) return;
          const questionNode = FormModel.newQuestion(
            activeSectionIndex >= 0
              ? (form.getNode(activeSectionIndex) as INodeModel)
              : form.getRoot(),
            qLabel,
            isOptional,
            question,
            qText
          );
          if (condition) {
            condition.addAffectedNode(questionNode.getId(), DecisionType.HIDE);
          }
          forceUpdate();
        }}
        currentFormScreenType={formScreenType}
        onSetFormScreenType={setFormScreenType}
        formId={form?.getForm()?.guid}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={handleCloseSnackBar}
            >
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
function onSubmitFilledForm(
  props: FormViewProps,
  formMetadata: FormMetadata | undefined,
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>,
  setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
) {
  return props.displayMode !== FormDisplayMode.FillMode
    ? () => {
        /* nothing to do if not fill mode */
      }
    : () => {
        // check number of not answered but required questions
        let notAnswered = (props.filledForm as FilledFormModel).getNotAnsweredRequiredQuestions();
        console.log(
          `submit filled form is called, number of not answered questions are ${notAnswered.length}`
        );
        // either the form is filled completely so the record can be submitted
        // or user is logged-in, so the partially filled form can be saved as draft
        if (notAnswered.length === 0 || LoginService.isLoggedIn) {
          // submit the filled form to server
          // TODO: work on the user-friendly message and language localization
          RecordService.postFilledForm(
            (props.filledForm as FilledFormModel).getFilledForm() as FilledForm,
            formMetadata as FormMetadata,
            notAnswered.length !== 0,
            (props.filledForm as FilledFormModel).getAttributes()
          )
            .then(
              () => {
                // TODO: message will be "Successfully saved" if notAnswered.length !== 0
                setSnackbarMessage("Successfully saved/submitted");
                setOpenSnackBar(true);
                // TODO: go to successful submission screen if notAnswered.length === 0
                // display the record id in that screen, so that user can mention it to form owner if needed.
                // ask user to sign-in in the system if he wants to keep track of records that he created.
              },
              (reason: any) => {
                console.error(`some error in submitting record: ${reason}`);
                setSnackbarMessage(
                  `Error in saving/submitting record: ${reason}`
                );
                setOpenSnackBar(true);
                // TODO: display submission failure below 'submit' button
              }
            )
            .catch((e) => {
              // TODO: display  the error to user
              console.error(`failed to submit record: ${JSON.stringify(e)}`);
              setSnackbarMessage(
                `Failed to save/submit record: ${JSON.stringify(e)}`
              );
              setOpenSnackBar(true);
              // TODO: show submission failure below 'submit' button
            });
        }
      };
}
