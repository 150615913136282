import React from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import LanguageSwitcher from "../components/language/language-switcher";
import "firebase/auth";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

// export default function App() {

//   return (
//     <IntlProvider locale={usersLocale} key={usersLocale}>
//       <div className="App">
//       <CssBaseline />
//         <Navigation />
//       </div>
//     </IntlProvider>
//   );
// }

type MarketNavPropsType = {};
export default function MarketNav() {
  // See https://stackoverflow.com/questions/54843302/reactjs-bootstrap-navbar-and-routing-not-working-together
  // on reason why we use `Nav.Link as={Link} to=` instead of `Nav.Link href=`
  return (
    <div>
      <Navbar bg="primary" variant="dark" fixed="top">
        <Navbar.Brand href="#home">Bintech</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="#home">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="#features">
            Features
          </Nav.Link>
          <Nav.Link as={Link} to="#pricing">
            Pricing
          </Nav.Link>
          <Nav.Link as={Link} to="/forms">
            <FormattedMessage id="app.forms" defaultMessage="Forms" />
          </Nav.Link>
          <LanguageSwitcher />
        </Nav>
      </Navbar>
      <Navbar bg="primary" variant="dark">
        <Navbar.Brand href="#home">Bintech</Navbar.Brand>
      </Navbar>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <FormattedMessage id="app.home" defaultMessage="Home" />
            </Link>
          </li>
          <li>
            <Link to="/about">
              <FormattedMessage id="app.about" defaultMessage="About" />
            </Link>
          </li>
          <li>
            <Link to="/settings">
              <FormattedMessage id="app.settings" defaultMessage="Settings" />
            </Link>
          </li>
          <li>
            <Link to="/login">
              <FormattedMessage id="app.login" defaultMessage="Login" />
            </Link>
          </li>
          <li>
            <Link to="/forms">
              <FormattedMessage id="app.forms" defaultMessage="Forms" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
