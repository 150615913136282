import React from "react";

type SettingsPropsType = {};
export function Settings(props: SettingsPropsType) {
  return (
    <div>
      Settings by the account holder, or stored in browser local storage
      language, currency, country, ...
    </div>
  );
}
